import { useEffect, useRef, useState } from "react";

import { UserAccount, UserOnchainStats, CreatorClub, CreatorClubOnchainStats } from "../../utils/types";

import { DataCacheContext } from "./useDataCache";
import api from "../../utils/api/api";
import firebaseHelpers from "../../utils/firebaseHelpers";

export function DataCacheProvider(
    { children }: any
) {
    const fetchedUsers = useRef<{ [userId: string]: { userAccount: UserAccount, onchainStats: UserOnchainStats | null } }>({});

    async function fetchUser(userId: string, includeOnchainStats = false): Promise<{ userAccount: UserAccount, onchainStats: UserOnchainStats | null } | null> {
        if (fetchedUsers.current[userId] !== undefined) {
            if (!includeOnchainStats) return fetchedUsers.current[userId];
        } else {
            const userAccount = ((await firebaseHelpers.users.searchUserInfoByIds([userId])) ?? [])[0];
            if (userAccount === undefined) return null;
            fetchedUsers.current[userId] = { userAccount, onchainStats: null };
            if (!includeOnchainStats) return fetchedUsers.current[userId];
        }

        const onchainStats = await api.onchain.fetchUserOnchainStats(userId);
        fetchedUsers.current[userId] = { ...fetchedUsers.current[userId], onchainStats };
        return fetchedUsers.current[userId];
    }
    function cacheUser(userAccount: UserAccount) {
        fetchedUsers.current[userAccount.userId] = { userAccount, onchainStats: null };
    }

    const fetchedCreatorClubs = useRef<{ [creatorClubId: string]: { creatorClub: CreatorClub, creatorClubOnchainStats: CreatorClubOnchainStats | null } }>({});

    async function fetchCreatorClub(creatorClubId: string, includeOnchainStats = false): Promise<{ creatorClub: CreatorClub, creatorClubOnchainStats: CreatorClubOnchainStats | null } | null> {
        if (fetchedCreatorClubs.current[creatorClubId] !== undefined) {
            if (!includeOnchainStats) return fetchedCreatorClubs.current[creatorClubId];
        } else {
            const creatorClub = await firebaseHelpers.creatorClub.getCreatorClubInfo(creatorClubId);
            if (!creatorClub) return null;
            fetchedCreatorClubs.current[creatorClubId] = { creatorClub, creatorClubOnchainStats: null };
            if (!includeOnchainStats) return fetchedCreatorClubs.current[creatorClubId];
        }

        const creatorClubOnchainStats = await api.onchain.fetchClubOnchainStats(creatorClubId);
        fetchedCreatorClubs.current[creatorClubId] = { ...fetchedCreatorClubs.current[creatorClubId], creatorClubOnchainStats };
        return fetchedCreatorClubs.current[creatorClubId];
    }
    function cacheCreatorClub(creatorClub: CreatorClub) {
        fetchedCreatorClubs.current[creatorClub.id] = { creatorClub, creatorClubOnchainStats: null };
    }

    return (
        <DataCacheContext.Provider
            value={{
                fetchUser,
                cacheUser,
                fetchCreatorClub,
                cacheCreatorClub,
            }}
        >
            {children}
        </DataCacheContext.Provider>
    );
}