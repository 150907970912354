import "./AlphaPostWriterModal.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { gsap } from "gsap";

import {
    Dialog,
    DialogHeader,
    DialogBody,
    MenuItem,
    Menu,
    MenuHandler,
    Button,
    MenuList,
} from "@material-tailwind/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMinus, faCalendarDays, faPlus, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

import PopoverContainer, { PopoverTriggerType } from '@idui/react-popover'

import DatePicker from "react-datepicker";

// import { AdvancedChart } from "react-tradingview-embed";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

import TokenSelectorDropdown from "../../../Components/TokenSelectorDropdown";

import { ReactComponent as CaretUp } from "../../../media/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../../../media/icons/CaretDown.svg";
import { ReactComponent as ChevronDown } from "../../../media/icons/ChevronDown.svg";
import { ReactComponent as ConfirmTick } from "../../../media/icons/confirmTick.svg";
import loadingIconAnim from "../../../media/icons/loadingIcon.gif"
import tradingViewIcon from "../../../media/icons/tradingView.webp";
import { ReactComponent as EthIcon } from "../../../media/icons/ethereumIcon.svg";

import { useAlphaPostWriter } from "../../../Components/Providers/useAlphaPostWriter";

import { PostTokenSecurityMetricsFetch, PostTokenPoolStatsFetch, PostsListFetch } from "../types"
import { TokenInfo, PostCreationError, AssetClass, AlphaPredictionTimeframeRange, FtCreator, FtShareInfo, DEFAULT_NEW_ALPHA_POST_FIELDS, AlphaPostAssetInfo } from "../../../utils/types";
import { checkIfBothDatesSameDay, formatNumberForPrice, getAssetId, getScrollBarWidth } from "../../../utils/utils";
import { ALPHA_POST_CREATION_CONFIG, CHAIN_SLUG_TO_GECKO_ID, CHAIN_SLUGS, ALPHA_TIME_RANGE_ICONS } from "../../../utils/constants";
import api from "../../../utils/api/api";
import TokenSecurityMetricsDrawer from "./TokenSecurityMetricsDrawer";

const alphaPostCreatorConfig = {
    modules: {
        // toolbar: [
        //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        //     [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        //     ['link', "code", "code-block"],
        //     ['clean']
        // ],
        toolbar: "#post-writer-modal-research-toolbar .ql-toolbar"
    },

    formats: [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet',
        'link', "code", "code-block" //'image'
    ]
}

const AlphaPostWriterModal: FC<any> = (props) => {
    const { assetClass, verifyIfPostMeetsFilters, postsListFetch, setPostsListFetch }: {
        isWritingPost: boolean, setIsWritingPost: React.Dispatch<React.SetStateAction<boolean>>, assetClass: AssetClass, verifyIfPostMeetsFilters: Function, postsListFetch: PostsListFetch, setPostsListFetch: (value: React.SetStateAction<PostsListFetch>) => void
    } = props;

    const history = useHistory();

    const { isWritingPost, setIsWritingPost, isWriterDialogOpen, setIsWriterDialogOpen, newPostInfo, setNewPostInfo, getDefaultNewPostInfo, createPost } = useAlphaPostWriter();

    const [postTokenSecurityMetricsFetch, setPostTokenSecurityMetricsFetch] = useState<PostTokenSecurityMetricsFetch>({ tokenSecurityMetrics: null, fetching: false, drawerOpen: false, drawerVisible: false })
    const [postTokenPoolStatsFetch, setPostTokenPoolStatsFetch] = useState<PostTokenPoolStatsFetch>({ tokenPoolStats: null, fetching: false })
    const [fetchingFtShareInfoMetrics, setFetchingFtShareInfoMetrics] = useState<boolean>(false);

    const toolbarImageInputRef = useRef<HTMLInputElement | null>(null);
    const tradingViewChartRef = useRef<any>();
    const timeframeStartDatePickerRef = useRef<any>();
    const timeframeEndDatePickerRef = useRef<any>();

    useEffect(() => {
        setNewPostInfo({ ...newPostInfo, errors: [] })
    }, [newPostInfo.newPostContent])

    useEffect(() => {
        setNewPostInfo(getDefaultNewPostInfo())
    }, [assetClass])

    useEffect(() => {
        if (!newPostInfo.newPostContent.prediction?.asset) return;
        if (newPostInfo.newPostContent.prediction.asset.type === "token") {
            fetchPostTokenPoolStats()
            fetchPostTokenSecurityMetrics()
            const fetchTokenPoolStatsInterval = setInterval(() => fetchPostTokenPoolStats(false), 30000);
            return () => clearInterval(fetchTokenPoolStatsInterval);
        } else if (newPostInfo.newPostContent.prediction.asset.type === "ftShare") {
            fetchPostTokenFtShareInfo()
        }
    }, [newPostInfo.newPostContent.prediction?.asset])

    async function fetchPostTokenPoolStats(indicateFetch: boolean = true) {
        if (newPostInfo.newPostContent.prediction?.asset?.type !== "token") return;
        console.log("fetching token pool stats")
        setPostTokenPoolStatsFetch((prevPostTokenPoolStatsFetch: any) => ({ ...prevPostTokenPoolStatsFetch, fetching: indicateFetch }));
        const response = await api.defi.getTokenPoolStats(newPostInfo.newPostContent.prediction.asset.info as TokenInfo);
        setPostTokenPoolStatsFetch({ tokenPoolStats: response, fetching: false });
    }

    async function fetchPostTokenSecurityMetrics(indicateFetch: boolean = true) {
        if (newPostInfo.newPostContent.prediction?.asset?.type !== "token") return;
        console.log("fetching token security metrics")
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, fetching: indicateFetch });
        const response = await api.defi.getTokenSecurityMetrics(newPostInfo.newPostContent.prediction.asset.info as TokenInfo);
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, tokenSecurityMetrics: response, fetching: false });
    }

    async function fetchPostTokenFtShareInfo() {
        if (newPostInfo.newPostContent.prediction?.asset?.type !== "ftShare" || (newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics !== undefined) return;
        console.log("fetching ft share info")
        setFetchingFtShareInfoMetrics(true);
        const response = await api.onchain.getFtShareInfo((newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).address);
        setFetchingFtShareInfoMetrics(false);
        if (response !== undefined) {
            const changedNewPostInfo = { ...newPostInfo };
            changedNewPostInfo.newPostContent.prediction!.asset!.info = response;
            setNewPostInfo(changedNewPostInfo);
        }
    }

    function handleImageInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (file.size > ALPHA_POST_CREATION_CONFIG.MAXIMUM_MEDIA_SIZE) return;
            const filePreview = URL.createObjectURL(file);
            const modifiedNewPostInfo = { ...newPostInfo };
            modifiedNewPostInfo.newPostContent.research.media.push({ file, filePreview });
            setNewPostInfo({ ...modifiedNewPostInfo });
        }
    }

    useEffect(() => {
        const postWriterModalParentElement = document.body.querySelector("#post-writer-modal")?.parentElement;
        if (!postWriterModalParentElement) return;
        if (postTokenSecurityMetricsFetch.drawerOpen) {
            postWriterModalParentElement.scrollTop = 0;
            postWriterModalParentElement.style.setProperty("overflow-y", "hidden");
            postWriterModalParentElement.style.setProperty("padding-right", `${getScrollBarWidth()}px`);
        } else {
            postWriterModalParentElement.style.setProperty("overflow-y", "scroll");
            postWriterModalParentElement.style.removeProperty("padding-right");
        }
    }, [postTokenSecurityMetricsFetch.drawerOpen])

    useEffect(() => {
        if (isWritingPost) openWriterDialog();
        else if (isWriterDialogOpen) closeWriterDialog();
    }, [isWritingPost])

    function openWriterDialog() {
        setIsWriterDialogOpen(true);
        setTimeout(() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerVisible: true }), 500);
    }
    function closeWriterDialog() {
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: false, drawerVisible: false })
        setTimeout(() => { setIsWriterDialogOpen(false); setIsWritingPost(false) }, 100);
    }


    function getEntryDateMinTime(timeframe: { start?: number, end?: number }) {
        const minimumTimestamp = timeframe.end ? Math.min(Date.now(), timeframe.end) : Date.now();
        if (timeframe.start === undefined || checkIfBothDatesSameDay(new Date(minimumTimestamp), new Date(timeframe.start))) return new Date(minimumTimestamp);
        return new Date(new Date().setHours(0, 0, 0, 0));
    }

    function getEntryDateMaxTime(timeframe: { start?: number, end?: number }) {
        const startTimestamp = timeframe.start ? new Date(timeframe.start) : new Date();
        if (timeframe.end !== undefined && checkIfBothDatesSameDay(startTimestamp, new Date(timeframe.end))) return new Date(timeframe.end);
        return new Date(new Date().setHours(23, 59, 59, 0));
    }

    function getExitDateMinTime(timeframe: { start?: number, end?: number }) {
        const startTimestamp = timeframe.start ? new Date(timeframe.start) : new Date();
        if (timeframe.end === undefined || checkIfBothDatesSameDay(startTimestamp, new Date(timeframe.end))) return startTimestamp;
        return new Date(new Date().setHours(0, 0, 0, 0));
    }

    function getExitDateMaxTime() {
        return new Date(new Date().setHours(23, 59, 59, 0));
    }

    const tradingViewChart = React.useMemo(() =>
        <div ref={tradingViewChartRef} className="mt-4" >
            {newPostInfo.newPostContent.prediction?.asset && <iframe height="500px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/${CHAIN_SLUG_TO_GECKO_ID[CHAIN_SLUGS[(newPostInfo.newPostContent.prediction.asset.info as TokenInfo).networkId]]}/pools/${(newPostInfo.newPostContent.prediction.asset.info as TokenInfo).topPoolId}?embed=1&info=0&swaps=0`} frameBorder="0" allow="clipboard-write" allowFullScreen></iframe>}

            {/* {newPostInfo.newPostContent.token !== null && <AdvancedChart widgetProps={{ "theme": "dark", width: "100%", height: "500px", style: "1", symbol: `${newPostInfo.newPostContent.token.symbol}USD`, allow_symbol_change: false }} />} */}
            {/* {newPostInfo.newPostContent.token !== null && <AdvancedRealTimeChart theme="dark" width="100%" height="500px" symbol={`${newPostInfo.newPostContent.token.symbol}USD`} allow_symbol_change={false} />} */}
        </div >, [newPostInfo.newPostContent.prediction?.asset]);

    // const postWriterTypeSelectionContainer = <div id="post-writer-modal-type-selection-container" className="flex flex-col text-header w-full">
    //     {/* <h2 className="text-center border-main pb-1 mb-4">Select type of post</h2> */}
    //     <div id="post-writer-modal-type-selection" className="flex flex-row flex-wrap justify-center">
    //         <button id="post-writer-modal-type-selection-alpha" className="post-writer-modal-type-selection-btn flex flex-col w-[120px] h-[120px] justify-center items-center text-white border border-neutral-800 hover:bg-neutral-950 rounded-lg transition-all duration-300 m-2" onClick={() => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.postType = "alpha";
    //             history.push(window.location.pathname.replace("friendTech", "crypto"));
    //             setNewPostInfo(changedNewPostInfo);
    //         }}>
    //             <img src={alphaPostIcon} className="w-auto h-[60px]" />
    //             <h5 className="mt-2">ALPHA</h5>
    //         </button>
    //         <button id="post-writer-modal-type-selection-technicalAnalysis" className="post-writer-modal-type-selection-btn flex flex-col w-[120px] h-[120px] justify-center items-center text-white border border-neutral-800 hover:bg-neutral-950 rounded-lg transition-all duration-300 m-2" onClick={() => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.postType = "technicalAnalysis";
    //             history.push(window.location.pathname.replace("friendTech", "crypto"));
    //             setNewPostInfo(changedNewPostInfo);
    //         }}>
    //             <img src={technicalAnalysisPostIcon} className="w-auto h-[60px]" />
    //             <h5 className="mt-2">T.A</h5>
    //         </button>
    //         <button id="post-writer-modal-type-selection-friendTech" className="post-writer-modal-type-selection-btn flex flex-col w-[120px] h-[120px] justify-center items-center text-white border border-neutral-800 hover:bg-neutral-950 rounded-lg transition-all duration-300 m-2" onClick={() => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.postType = "friendTech";
    //             history.push(window.location.pathname.replace("crypto", "friendTech"));
    //             setNewPostInfo(changedNewPostInfo);
    //         }}>
    //             <img src={friendTechIcon} className="w-auto h-[60px]" />
    //             <h5 className="mt-2">FT</h5>
    //         </button>
    //     </div>
    // </div>

    // const alphaPostWriterModalBodyContent = <div id="post-writer-modal-body-content" className="flex flex-col w-full">
    //     <div id="post-writer-modal-title" className="flex flex-col mb-2">
    //         <input type="text" placeholder={newPostInfo.newPostContent.asset !== null ? `${newPostInfo.newPostContent.asset.info.symbol} ${newPostInfo.newPostContent.position}` : "Title"} id="post-writer-modal-title-input" value={newPostInfo.newPostContent.title} onChange={(e) => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.title = e.target.value;
    //             changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Title);
    //             setNewPostInfo(changedNewPostInfo)
    //         }} className={`post-writer-input text-2xl ps-3 ${newPostInfo.errors.includes(PostCreationError.Title) ? "post-writer-input-error" : ""}`} />
    //         <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Title) ? PostCreationError.Title.valueOf() : ""}</p>
    //     </div>
    // <div id="post-writer-modal-trade-details" className="flex flex-row flex-wrap">
    //     <div id="post-writer-modal-trade-details-token" className="flex flex-col mt-1 me-2">
    //         <TokenSelectorDropdown className="h-full" buttonClassName={`h-full ${newPostInfo.errors.includes(PostCreationError.Asset) ? "post-writer-btn-error" : ""}`} assetClass={assetClass} selectedToken={newPostInfo.newPostContent.asset?.info} setSelectedToken={(token: any) => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.asset = token !== null ? { info: { ...token }, assetClass, assetId: getAssetId(token), priceAtCreation: 0, type: "token" } : null;
    //             changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Asset)
    //             setNewPostInfo(changedNewPostInfo)
    //         }} />
    //         <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Asset) ? PostCreationError.Asset.valueOf() : ""}</p>
    //     </div>
    //     <div id="post-writer-modal-trade-details-position" className="flex flex-col mt-1">
    //         <div className="flex h-full">
    //             <button onClick={() => {
    //                 const changedNewPostInfo = { ...newPostInfo };
    //                 changedNewPostInfo.newPostContent.position = "long";
    //                 setNewPostInfo(changedNewPostInfo)
    //             }} className={`post-writer-btn long-btn mx-1 px-4 py-1 rounded-lg hover:bg-selection ${newPostInfo.newPostContent.position === "long" ? "!bg-selection" : ""}`}>LONG</button>
    //             <button onClick={() => {
    //                 const changedNewPostInfo = { ...newPostInfo };
    //                 changedNewPostInfo.newPostContent.position = "short";
    //                 setNewPostInfo(changedNewPostInfo)
    //             }} className={`post-writer-btn short-btn mx-1 px-4 py-1 rounded-lg hover:bg-error ${newPostInfo.newPostContent.position === "short" ? "!bg-error" : ""}`}>SHORT</button>
    //         </div>
    //         <p className="post-writer-input-error-text mb-0"></p>
    //     </div>

    //     <div id="post-writer-modal-trade-details-timeframe" className="flex items-center mx-2 mt-1">
    //         <FontAwesomeIcon icon={faCalendarDays} className="text-xl me-3 mb-[20px]" />
    //         {/* <div className="flex flex-col sm:flex-row flex-wrap justify-center items-center">
    //             <div className="flex flex-col">
    //                 <DatePicker
    //                     ref={timeframeStartDatePickerRef}
    //                     placeholderText="Estimated Entry Date"
    //                     // timeInputLabel="Time:"
    //                     dateFormat="MM/dd/yyyy h:mm aa"
    //                     showTimeSelect
    //                     className={`post-writer-input date-time-input ${newPostInfo.errors.includes(PostCreationError.TimeframeStart) ? "post-writer-input-error" : ""}`}
    //                     showPopperArrow={false}
    //                     closeOnScroll={true}
    //                     selected={newPostInfo.newPostContent.prediction.alpha.timeframe.start !== undefined ? new Date(newPostInfo.newPostContent.prediction.alpha.timeframe.start) : undefined}
    //                     minDate={new Date()}
    //                     maxDate={newPostInfo.newPostContent.prediction.alpha.timeframe.end !== undefined ? new Date(newPostInfo.newPostContent.prediction.alpha.timeframe.end) : undefined}
    //                     minTime={getEntryDateMinTime(newPostInfo.newPostContent.prediction.alpha.timeframe)}
    //                     maxTime={getEntryDateMaxTime(newPostInfo.newPostContent.prediction.alpha.timeframe)}
    //                     onChange={(date: Date) => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         const timestamp = date ? Math.max(date.getTime(), Date.now()) : undefined;
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframe.start = timestamp && newPostInfo.newPostContent.prediction.alpha.timeframe.end ? Math.min(timestamp, newPostInfo.newPostContent.prediction.alpha.timeframe.end) : timestamp;
    //                         changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.TimeframeStart)
    //                         setNewPostInfo(changedNewPostInfo);
    //                     }} />
    //                 <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.TimeframeStart) ? PostCreationError.TimeframeStart.valueOf() : ""}</p>
    //             </div>
    //             <FontAwesomeIcon icon={faMinus} className="mx-2 mb-[20px] hidden sm:inline-block" />
    //             <div className="flex flex-col">
    //                 <DatePicker
    //                     ref={timeframeEndDatePickerRef}
    //                     placeholderText="Estimated Exit Date"
    //                     // timeInputLabel="Time:"
    //                     dateFormat="MM/dd/yyyy h:mm aa"
    //                     showTimeSelect
    //                     className={`post-writer-input date-time-input ${newPostInfo.errors.includes(PostCreationError.TimeframeEnd) ? "post-writer-input-error" : ""}`}
    //                     showPopperArrow={false}
    //                     closeOnScroll={true}
    //                     selected={newPostInfo.newPostContent.prediction.alpha.timeframe.end !== undefined ? new Date(newPostInfo.newPostContent.prediction.alpha.timeframe.end) : undefined}
    //                     minDate={newPostInfo.newPostContent.prediction.alpha.timeframe.start !== undefined ? new Date(newPostInfo.newPostContent.prediction.alpha.timeframe.start) : new Date()}
    //                     minTime={getExitDateMinTime(newPostInfo.newPostContent.prediction.alpha.timeframe)}
    //                     maxTime={getExitDateMaxTime()}
    //                     onChange={(date: Date) => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         const timestamp = date ? Math.max(date.getTime(), Date.now()) : undefined;
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframe.end = timestamp && newPostInfo.newPostContent.prediction.alpha.timeframe.start ? Math.max(timestamp, newPostInfo.newPostContent.prediction.alpha.timeframe.start) : timestamp;
    //                         changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.TimeframeEnd)
    //                         setNewPostInfo(changedNewPostInfo);
    //                     }} />
    //                 <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.TimeframeEnd) ? PostCreationError.TimeframeEnd.valueOf() : ""}</p>
    //             </div>
    //         </div> */}
    //         <div className="flex flex-col justify-center items-center">
    //             <Menu
    //                 placement="bottom-start"
    //                 dismiss={{
    //                     itemPress: true,
    //                 }}
    //             >
    //                 <MenuHandler>
    //                     <Button className="flex items-center bg-neutral-750"><img src={ALPHA_TIME_RANGE_ICONS[newPostInfo.newPostContent.prediction.alpha.timeframeRange]} className="h-[19.5px] w-auto me-2" /> {newPostInfo.newPostContent.prediction.alpha.timeframeRange.valueOf()}</Button>
    //                 </MenuHandler>
    //                 <MenuList className="token-selector-dropdown-content bg-neutral-800 border-0 px-2 py-1 z-[9999]">
    //                     <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.alpha.timeframeRange === AlphaPredictionTimeframeRange.Scalp ? "bg-neutral-900" : "")} onClick={() => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframeRange = AlphaPredictionTimeframeRange.Scalp
    //                         setNewPostInfo(changedNewPostInfo)
    //                     }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.Scalp]} className="h-[20px] w-auto me-2" /> Scalp</MenuItem>
    //                     <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.alpha.timeframeRange === AlphaPredictionTimeframeRange.SwingTrade ? "bg-neutral-900" : "")} onClick={() => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframeRange = AlphaPredictionTimeframeRange.SwingTrade
    //                         setNewPostInfo(changedNewPostInfo)
    //                     }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.SwingTrade]} className="h-[20px] w-auto me-2" /> Swing Trade</MenuItem>
    //                     <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.alpha.timeframeRange === AlphaPredictionTimeframeRange.ShortTerm ? "bg-neutral-900" : "")} onClick={() => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframeRange = AlphaPredictionTimeframeRange.ShortTerm
    //                         setNewPostInfo(changedNewPostInfo)
    //                     }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.ShortTerm]} className="h-[20px] w-auto me-2" /> Short Term</MenuItem>
    //                     <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.alpha.timeframeRange === AlphaPredictionTimeframeRange.MidTerm ? "bg-neutral-900" : "")} onClick={() => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframeRange = AlphaPredictionTimeframeRange.MidTerm
    //                         setNewPostInfo(changedNewPostInfo)
    //                     }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.MidTerm]} className="h-[20px] w-auto me-2" /> Mid Term</MenuItem>
    //                     <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.alpha.timeframeRange === AlphaPredictionTimeframeRange.LongTerm ? "bg-neutral-900" : "")} onClick={() => {
    //                         const changedNewPostInfo = { ...newPostInfo };
    //                         changedNewPostInfo.newPostContent.prediction.alpha.timeframeRange = AlphaPredictionTimeframeRange.LongTerm
    //                         setNewPostInfo(changedNewPostInfo)
    //                     }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.LongTerm]} className="h-[20px] w-auto me-2" /> Long Term</MenuItem>
    //                 </MenuList>
    //             </Menu>
    //             <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.TimeframeEnd) ? PostCreationError.TimeframeEnd.valueOf() : ""}</p>
    //         </div>
    //     </div>
    // </div>
    // {
    //     newPostInfo.newPostContent.asset?.type === "token" ? <>
    //         <div id="alpha-post-token-pool-stats" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
    //             <div className="flex flex-col mb-2 lg:mb-0">
    //                 {/* <h5 className="pool-stat-header mb-0">Price</h5> */}
    //                 <div className="flex items-center">
    //                     {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
    //                         <h2 className="mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.price ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.price)}` : "-"}</h2>
    //                         <h6 className={`ms-2 mb-0 ${(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? "text-selection" : "text-error"}`}>{(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? <CaretUp /> : <CaretDown />} {postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h.toFixed(2) ?? "-"}%</h6>
    //                     </>}
    //                 </div>
    //             </div>
    //             <div className="flex lg:ms-auto">
    //                 <div className="flex flex-col items-center mx-3">
    //                     <h6 className="pool-stat-header mb-1">Volume</h6>
    //                     {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.volume24h ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.volume24h, true)}` : "-"}</h5>}
    //                 </div>
    //                 <div className="flex flex-col items-center mx-3">
    //                     <h6 className="pool-stat-header mb-1">Liquidity</h6>
    //                     {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.liquidity ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.liquidity, true)}` : "-"}</h5>}
    //                 </div>
    //                 <div className="flex flex-col items-center mx-3">
    //                     <h6 className="pool-stat-header mb-1">FDV</h6>
    //                     {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.fdv ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.fdv, true)}` : "-"}</h5>}
    //                 </div>
    //             </div>
    //         </div>
    //         <button id="alpha-post-token-security-metrics-btn" className="btn-primary py-2 rounded-lg" onClick={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: true })}>Audit Scan</button>
    //     </> : ""
    // }
    // <div id="post-writer-modal-trade-research" className="flex flex-col mt-3">
    //     <ReactQuill theme="snow" id="post-writer-modal-research" placeholder="" value={newPostInfo.newPostContent.research.content} onChange={(newValue) => {
    //         const changedNewPostInfo = { ...newPostInfo };
    //         changedNewPostInfo.newPostContent.research.content = newValue === "<p><br></p>" ? "" : newValue;
    //         changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.Research)
    //         setNewPostInfo(changedNewPostInfo);
    //     }} modules={alphaPostCreatorConfig.modules} formats={alphaPostCreatorConfig.formats} />
    //     <div id="post-writer-modal-research-media-container" className="flex border-x border-neutral-700 w-full px-3 pb-5">
    //         {/* <FileDropzone text="Drag and drop images or" labelClassName="py-5" className="w-full" /> */}
    //         <div className={`grid grid-cols-${newPostInfo.newPostContent.research.media.length >= 2 ? "2" : "1"} grid-rows-${newPostInfo.newPostContent.research.media.length >= 3 ? "2" : "1"} w-full`}>
    //             {
    //                 newPostInfo.newPostContent.research.media.map((media, index) => {
    //                     return <div key={index} className="relative w-full">
    //                         <button type="button" className="btn-simple absolute mix-blend-difference text-2xl top-1 end-2" onClick={() => {
    //                             URL.revokeObjectURL(media.filePreview);
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.research.media = changedNewPostInfo.newPostContent.research.media.filter((_, i) => i !== index);
    //                             setNewPostInfo(changedNewPostInfo);
    //                         }}><FontAwesomeIcon icon={faXmark} /></button>
    //                         <img src={media.filePreview} className="w-full h-auto object-cover rounded-md" />
    //                     </div>
    //                 })
    //             }
    //         </div>
    //     </div>
    //     <div id="post-writer-modal-research-toolbar" className="flex flex-wrap items-center">
    //         <div className="ql-toolbar ql-snow flex flex-col !border-none">
    //             <div className="flex flex-wrap mb-2">
    //                 <span className="ql-formats">
    //                     <button type="button" className="ql-bold"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
    //                     <button type="button" className="ql-italic"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
    //                     <button type="button" className="ql-underline"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
    //                     <button type="button" className="ql-strike"><svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg></button>
    //                     <button type="button" className="ql-blockquote"><svg viewBox="0 0 18 18"> <rect className="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect> <rect className="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect> <path className="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path> <path className="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path> </svg></button>
    //                 </span>
    //                 <span className="ql-formats">
    //                     <button type="button" className="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button>
    //                     <button type="button" className="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
    //                     {/* <button type="button" className="ql-indent" value="-1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-stroke" points="5 7 5 11 3 9 5 7"></polyline> </svg></button>
    //                     <button type="button" className="ql-indent" value="+1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-fill ql-stroke" points="3 7 3 11 5 9 3 7"></polyline> </svg></button> */}
    //                 </span>
    //                 <span className="ql-formats">
    //                     <button type="button" className="ql-link"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg></button>
    //                     <button type="button" className="ql-code"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button><button type="button" className="ql-code-block"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button>
    //                 </span>
    //                 <span className="ql-formats">
    //                     <button type="button" className="ql-clean"><svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line> <line className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line> <line className="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line> <line className="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect> </svg></button>
    //                 </span>
    //                 <span className="ql-formats">
    //                     <button onClick={() => toolbarImageInputRef.current && toolbarImageInputRef.current.click()} type="button" className="ql-custom-image"><svg fill="currentColor" height="18px" width="18px" viewBox="0 0 500 500"><g><g><path d="M0,437.8c0,28.5,23.2,51.6,51.6,51.6h386.2c28.5,0,51.6-23.2,51.6-51.6V51.6c0-28.5-23.2-51.6-51.6-51.6H51.6    C23.1,0,0,23.2,0,51.6C0,51.6,0,437.8,0,437.8z M437.8,464.9H51.6c-14.9,0-27.1-12.2-27.1-27.1v-64.5l92.8-92.8l79.3,79.3    c4.8,4.8,12.5,4.8,17.3,0l143.2-143.2l107.8,107.8v113.4C464.9,452.7,452.7,464.9,437.8,464.9z M51.6,24.5h386.2    c14.9,0,27.1,12.2,27.1,27.1v238.1l-99.2-99.1c-4.8-4.8-12.5-4.8-17.3,0L205.2,333.8l-79.3-79.3c-4.8-4.8-12.5-4.8-17.3,0    l-84.1,84.1v-287C24.5,36.7,36.7,24.5,51.6,24.5z" /><path d="M151.7,196.1c34.4,0,62.3-28,62.3-62.3s-28-62.3-62.3-62.3s-62.3,28-62.3,62.3S117.3,196.1,151.7,196.1z M151.7,96    c20.9,0,37.8,17,37.8,37.8s-17,37.8-37.8,37.8s-37.8-17-37.8-37.8S130.8,96,151.7,96z" /></g></g></svg></button>
    //                     <input ref={toolbarImageInputRef} type="file" accept="image/*" name="images" className="hidden" onChange={handleImageInputChange}></input>
    //                 </span>
    //             </div>
    //         </div>
    //         {/* <span className="ms-auto" style={{ color: `rgb(${200 - getHtmlTextLength(newPostInfo.newPostContent.research)}, ${getHtmlTextLength(newPostInfo.newPostContent.research)}, 0)` }}>Minimum: {getHtmlTextLength(newPostInfo.newPostContent.research)}/{MINIMUM_ALPHA_RESEARCH_LENGTH}</span> */}
    //         <div className="flex items-center ms-auto">
    //             <PopoverContainer className="text-sm !px-3 !py-2 !z-[10000] !bg-neutral-750 text-white rounded-lg" content={`Rating: ${newPostInfo.isRatingOn ? "On" : "Off"}`} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
    //                 <button className="relative flex w-[80px] h-[20px] bg-neutral-800 rounded-full overflow-hidden mx-2" onClick={() => setNewPostInfo({ ...newPostInfo, isRatingOn: !newPostInfo.isRatingOn })}>
    //                     <div className={"flex absolute w-1/2 h-full top-0 bottom-0 transition-all duration-150 " + (newPostInfo.isRatingOn ? "bg-selection left-1/2" : "bg-error left-0")}></div>
    //                     <div className="flex justify-center items-center absolute top-0 bottom-0 left-0 w-1/2 text-xs text-neutral-800"><FontAwesomeIcon icon={faXmark} className="h-[15px] w-auto me-1" /> No</div>
    //                     <div className="flex justify-center items-center absolute top-0 bottom-0 left-1/2 w-1/2 text-xs text-neutral-800"><ConfirmTick className="h-[15px] w-auto me-1" /> Yes</div>
    //                 </button>
    //             </PopoverContainer>
    //             <button id="post-writer-modal-post-btn" className="flex items-center btn-default-reversed disabled:opacity-80 mx-2 !px-5 my-2 leading-[20px]" disabled={newPostInfo.creating} onClick={() => createPost()} style={{ width: "fit-content", borderRadius: "3rem" }}>{newPostInfo.creating ? <img src={loadingIconAnim} className="px-3 h-[20px] w-auto" /> : "Post"}</button>
    //         </div>
    //     </div>
    // </div>
    // </div>

    // const technicalAnalysisPostWriterModalBodyContent = <div id="post-writer-modal-body-content" className="flex flex-col w-full">
    //     <div id="post-writer-modal-title" className="flex flex-col mb-2">
    //         <input type="text" placeholder="Title" id="post-writer-modal-title-input" value={newPostInfo.newPostContent.title} onChange={(e) => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.title = e.target.value;
    //             changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Title);
    //             setNewPostInfo(changedNewPostInfo)
    //         }} className={`post-writer-input text-2xl ps-3 ${newPostInfo.errors.includes(PostCreationError.Title) ? "post-writer-input-error" : ""}`} />
    //         <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Title) ? PostCreationError.Title.valueOf() : ""}</p>
    //     </div>
    //     <div id="post-writer-modal-trade-details" className="flex flex-row flex-wrap">
    //         <div id="post-writer-modal-trade-details-token" className="flex flex-col mt-1 me-2">
    //             <TokenSelectorDropdown buttonClassName={newPostInfo.errors.includes(PostCreationError.Asset) ? "post-writer-btn-error" : ""} assetClass={assetClass} selectedToken={newPostInfo.newPostContent.asset?.info} setSelectedToken={(token: any) => {
    //                 const changedNewPostInfo = { ...newPostInfo };
    //                 changedNewPostInfo.newPostContent.asset = token !== null ? { info: { ...token }, assetClass, assetId: getAssetId(token), priceAtCreation: 0, type: "token" } : null;
    //                 changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Asset);
    //                 // if (token === null) changedNewPostInfo.includeChart = false;
    //                 setNewPostInfo(changedNewPostInfo)
    //             }} />
    //             <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Asset) ? PostCreationError.Asset.valueOf() : ""}</p>
    //         </div>
    //         <div id="post-writer-modal-trade-details-position" className="flex flex-col mt-1">
    //             <div className="flex h-full">
    //                 <button onClick={() => {
    //                     const changedNewPostInfo = { ...newPostInfo };
    //                     changedNewPostInfo.newPostContent.position = "long";
    //                     setNewPostInfo(changedNewPostInfo)
    //                 }} className={`post-writer-btn long-btn mx-1 px-4 py-1 ${newPostInfo.newPostContent.position === "long" ? "!bg-selection" : ""}`}>LONG</button>
    //                 <button onClick={() => {
    //                     const changedNewPostInfo = { ...newPostInfo };
    //                     changedNewPostInfo.newPostContent.position = "short";
    //                     setNewPostInfo(changedNewPostInfo)
    //                 }} className={`post-writer-btn short-btn mx-1 px-4 py-1 ${newPostInfo.newPostContent.position === "short" ? "!bg-error" : ""}`}>SHORT</button>
    //             </div>
    //             <p className="post-writer-input-error-text mb-0"></p>
    //         </div>
    // <div id="post-writer-modal-trade-details-entry" className="flex flex-col justify-center mx-2 mt-1">
    //     <input type="number" placeholder="Entry" min={0} value={newPostInfo.newPostContent.prediction.technicalAnalysis.entryTarget} onChange={(e) => {
    //         const changedNewPostInfo = { ...newPostInfo };
    //         changedNewPostInfo.newPostContent.prediction.technicalAnalysis.entryTarget = Number(e.target.value);
    //         changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.EntryTarget);
    //         setNewPostInfo(changedNewPostInfo)
    //     }} className={`post-writer-input ps-2 ${newPostInfo.errors.includes(PostCreationError.EntryTarget) ? "post-writer-input-error" : ""}`} />
    //     <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.EntryTarget) ? PostCreationError.EntryTarget.valueOf() : ""}</p>
    // </div>
    // <div id="post-writer-modal-trade-details-exit" className="flex flex-col justify-center mx-2 mt-1">
    //     <input type="number" placeholder="Exit" min={0} value={newPostInfo.newPostContent.prediction.technicalAnalysis.exitTargets[0]} onChange={(e) => {
    //         const changedNewPostInfo = { ...newPostInfo };
    //         changedNewPostInfo.newPostContent.prediction.technicalAnalysis.exitTargets = [Number(e.target.value)];
    //         changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.ExitTarget);
    //         setNewPostInfo(changedNewPostInfo)
    //     }} className={`post-writer-input ps-2 ${newPostInfo.errors.includes(PostCreationError.ExitTarget) ? "post-writer-input-error" : ""}`} />
    //     <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.ExitTarget) ? PostCreationError.ExitTarget.valueOf() : ""}</p>
    // </div>
    // <div id="post-writer-modal-trade-details-invalidation" className="flex flex-col justify-center mx-2 mt-1">
    //     <input type="number" placeholder="Invalidation" min={0} value={newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.invalidationPoint} onChange={(e) => {
    //         const changedNewPostInfo = { ...newPostInfo };
    //         changedNewPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.invalidationPoint = Number(e.target.value);
    //         changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.MissingPostEndPoint);
    //         setNewPostInfo(changedNewPostInfo)
    //     }} className={`post-writer-input ps-2 ${newPostInfo.errors.includes(PostCreationError.LowInvalidationOnShort) || newPostInfo.errors.includes(PostCreationError.HighInvalidationOnLong) || newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? "post-writer-input-error" : ""}`} />
    //     <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? PostCreationError.MissingPostEndPoint.valueOf() : newPostInfo.errors.includes(PostCreationError.LowInvalidationOnShort) ? PostCreationError.LowInvalidationOnShort.valueOf() : newPostInfo.errors.includes(PostCreationError.HighInvalidationOnLong) ? PostCreationError.HighInvalidationOnLong.valueOf() : ""}</p>
    // </div>

    //         <div id="post-writer-modal-trade-details-timeframe" className="flex flex-col justify-center mx-2 mt-1">
    //             <div className="flex justify-center items-center">
    //                 <FontAwesomeIcon icon={faCalendarDays} className="text-xl me-3" />
    //                 <div className="flex flex-col sm:flex-row justify-center items-center">
    //                     <DatePicker
    //                         ref={timeframeStartDatePickerRef}
    //                         placeholderText="Estimated Entry Date"
    //                         // timeInputLabel="Time:"
    //                         dateFormat="MM/dd/yyyy h:mm aa"
    //                         showTimeSelect
    //                         className={`post-writer-input date-time-input my-2 sm:my-0 ${newPostInfo.errors.includes(PostCreationError.TimeframeStart) || newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? "post-writer-input-error" : ""}`}
    //                         showPopperArrow={false}
    //                         closeOnScroll={true}
    //                         selected={newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start !== undefined ? new Date(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start) : undefined}
    //                         minDate={new Date()}
    //                         maxDate={newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end !== undefined ? new Date(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end) : undefined}
    //                         minTime={getEntryDateMinTime(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe)}
    //                         maxTime={getEntryDateMaxTime(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe)}
    //                         onChange={(date: Date) => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             const timestamp = date ? Math.max(date.getTime(), Date.now()) : undefined;
    //                             changedNewPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start = timestamp && newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end ? Math.min(timestamp, newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end) : timestamp;;
    //                             changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.TimeframeStart)
    //                             setNewPostInfo(changedNewPostInfo);
    //                         }} />
    //                     <FontAwesomeIcon icon={faMinus} className="mx-2 hidden sm:inline-block" />
    //                     <DatePicker
    //                         ref={timeframeEndDatePickerRef}
    //                         placeholderText="Estimated Exit Date"
    //                         // timeInputLabel="Time:"
    //                         dateFormat="MM/dd/yyyy h:mm aa"
    //                         showTimeSelect
    //                         className={`post-writer-input date-time-input my-2 sm:my-0 ${newPostInfo.errors.includes(PostCreationError.TimeframeEnd) || newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? "post-writer-input-error" : ""}`}
    //                         showPopperArrow={false}
    //                         closeOnScroll={true}
    //                         selected={newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end !== undefined ? new Date(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end) : undefined}
    //                         minDate={newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start !== undefined ? new Date(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start) : new Date()}
    //                         minTime={getExitDateMinTime(newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe)}
    //                         maxTime={getExitDateMaxTime()}
    //                         onChange={(date: Date) => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             const timestamp = date ? Math.max(date.getTime(), Date.now()) : undefined;
    //                             changedNewPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.end = timestamp && newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start ? Math.max(timestamp, newPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.timeframe.start) : timestamp;;
    //                             changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.TimeframeEnd)
    //                             setNewPostInfo(changedNewPostInfo);
    //                         }} />
    //                 </div>
    //             </div>
    //             <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? PostCreationError.MissingPostEndPoint.valueOf() : ""}</p>
    //         </div>
    //     </div>
    //     {
    //         newPostInfo.newPostContent.asset?.type === "token" ? <>
    //             <div id="alpha-post-token-pool-stats" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
    //                 <div className="flex flex-col mb-2 lg:mb-0">
    //                     {/* <h5 className="pool-stat-header mb-0">Price</h5> */}
    //                     <div className="flex items-center">
    //                         {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
    //                             <h2 className="mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.price ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.price)}` : "-"}</h2>
    //                             <h6 className={`ms-2 mb-0 ${(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? "text-selection" : "text-error"}`}>{(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? <CaretUp /> : <CaretDown />} {postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h.toFixed(2) ?? "-"}%</h6>
    //                         </>}
    //                     </div>
    //                 </div>
    //                 <div className="flex lg:ms-auto">
    //                     <div className="flex flex-col items-center mx-3">
    //                         <h6 className="pool-stat-header mb-1">Volume</h6>
    //                         {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.volume24h ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.volume24h, true)}` : "-"}</h5>}
    //                     </div>
    //                     <div className="flex flex-col items-center mx-3">
    //                         <h6 className="pool-stat-header mb-1">Liquidity</h6>
    //                         {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.liquidity ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.liquidity, true)}` : "-"}</h5>}
    //                     </div>
    //                     <div className="flex flex-col items-center mx-3">
    //                         <h6 className="pool-stat-header mb-1">FDV</h6>
    //                         {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.fdv ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.fdv, true)}` : "-"}</h5>}
    //                     </div>
    //                 </div>
    //             </div>
    //             <button id="alpha-post-token-security-metrics-btn" className="btn-primary py-2 rounded-lg" onClick={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: true })}>Audit Scan</button>
    //         </> : ""
    //     }
    //     <div id="post-writer-modal-trade-research" className="flex flex-col mt-3">
    //         <ReactQuill theme="snow" id="post-writer-modal-research" placeholder="" value={newPostInfo.newPostContent.research.content} onChange={(newValue) => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.research.content = newValue === "<p><br></p>" ? "" : newValue;
    //             changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.Research)
    //             setNewPostInfo(changedNewPostInfo);
    //         }} modules={alphaPostCreatorConfig.modules} formats={alphaPostCreatorConfig.formats} />
    //         <div id="post-writer-modal-research-media-container" className="flex border-x border-neutral-700 w-full px-3 pb-5">
    //             {/* <FileDropzone text="Drag and drop images or" labelClassName="py-5" className="w-full" /> */}
    //             <div className={`grid grid-cols-${newPostInfo.newPostContent.research.media.length >= 2 ? "2" : "1"} grid-rows-${newPostInfo.newPostContent.research.media.length >= 3 ? "2" : "1"} w-full`}>
    //                 {
    //                     newPostInfo.newPostContent.research.media.map((media, index) => {
    //                         return <div key={index} className="relative w-full">
    //                             <button type="button" className="btn-simple absolute mix-blend-difference text-2xl top-1 end-2" onClick={() => {
    //                                 URL.revokeObjectURL(media.filePreview);
    //                                 const changedNewPostInfo = { ...newPostInfo };
    //                                 changedNewPostInfo.newPostContent.research.media = changedNewPostInfo.newPostContent.research.media.filter((_, i) => i !== index);
    //                                 setNewPostInfo(changedNewPostInfo);
    //                             }}><FontAwesomeIcon icon={faXmark} /></button>
    //                             <img src={media.filePreview} className="w-full h-auto object-cover rounded-md" />
    //                         </div>
    //                     })
    //                 }
    //             </div>
    //         </div>

    //         <div id="chart-container" className="flex flex-col overflow-hidden border-x border-neutral-800">
    //             <div className={`${newPostInfo.includeChart ? "expanded mt-0" : "collapsed -mt-[586px]"} active-chart transition-all duration-1000`}>
    //                 {tradingViewChart}
    //                 <div className="flex flex-col chart-link-container my-2 ms-2">
    //                     {/* <span>Chart image link:</span> */}
    //                     <div className="flex items-center w-full">
    //                         <input type="text" value={newPostInfo.newPostContent!.prediction.technicalAnalysis.advancedOptions.taChartUrl === null ? "" : newPostInfo.newPostContent!.prediction.technicalAnalysis.advancedOptions.taChartUrl} onChange={(e) => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.prediction.technicalAnalysis.advancedOptions.taChartUrl = e.target.value;
    //                             changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.TaChartUrl)
    //                             setNewPostInfo(changedNewPostInfo);
    //                         }
    //                         } className={`post-writer-input me-2 ${newPostInfo.errors.includes(PostCreationError.TaChartUrl) ? "post-writer-input-error" : ""}`} style={{ maxWidth: "400px", width: "90%" }} placeholder={`Chart link (${TA_TRADING_VIEW_CHART_IMAGE_BASE_URL}):`}></input>
    //                         <PopoverContainer className="chart-image-input-question-popover" content={"To include your TA, click on the camera icon (top-right of chart) and on \"Copy Link to the chart image\", then paste the tradingview.com link here. The same process applies if you've done your TA on TradingView's website."} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
    //                             <FontAwesomeIcon icon={faCircleQuestion} />
    //                         </PopoverContainer>
    //                     </div>
    //                     {newPostInfo.errors.includes(PostCreationError.TaChartUrl) && <span className="text-error">Invalid Trading View chart image url</span>}
    //                 </div>
    //             </div>
    //         </div>
    //         <div id="post-writer-modal-research-toolbar" className="flex flex-wrap items-center">
    //             <div className="ql-toolbar ql-snow flex flex-col !border-none">
    //                 <div className="flex flex-wrap mb-2">
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-bold"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
    //                         <button type="button" className="ql-italic"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
    //                         <button type="button" className="ql-underline"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
    //                         <button type="button" className="ql-strike"><svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg></button>
    //                         <button type="button" className="ql-blockquote"><svg viewBox="0 0 18 18"> <rect className="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect> <rect className="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect> <path className="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path> <path className="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button>
    //                         <button type="button" className="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
    //                         {/* <button type="button" className="ql-indent" value="-1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-stroke" points="5 7 5 11 3 9 5 7"></polyline> </svg></button>
    //                         <button type="button" className="ql-indent" value="+1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-fill ql-stroke" points="3 7 3 11 5 9 3 7"></polyline> </svg></button> */}
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-link"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg></button>
    //                         <button type="button" className="ql-code"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button><button type="button" className="ql-code-block"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-clean"><svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line> <line className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line> <line className="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line> <line className="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button onClick={() => toolbarImageInputRef.current && toolbarImageInputRef.current.click()} type="button" className="ql-custom-image"><svg fill="currentColor" height="18px" width="18px" viewBox="0 0 500 500"><g><g><path d="M0,437.8c0,28.5,23.2,51.6,51.6,51.6h386.2c28.5,0,51.6-23.2,51.6-51.6V51.6c0-28.5-23.2-51.6-51.6-51.6H51.6    C23.1,0,0,23.2,0,51.6C0,51.6,0,437.8,0,437.8z M437.8,464.9H51.6c-14.9,0-27.1-12.2-27.1-27.1v-64.5l92.8-92.8l79.3,79.3    c4.8,4.8,12.5,4.8,17.3,0l143.2-143.2l107.8,107.8v113.4C464.9,452.7,452.7,464.9,437.8,464.9z M51.6,24.5h386.2    c14.9,0,27.1,12.2,27.1,27.1v238.1l-99.2-99.1c-4.8-4.8-12.5-4.8-17.3,0L205.2,333.8l-79.3-79.3c-4.8-4.8-12.5-4.8-17.3,0    l-84.1,84.1v-287C24.5,36.7,36.7,24.5,51.6,24.5z" /><path d="M151.7,196.1c34.4,0,62.3-28,62.3-62.3s-28-62.3-62.3-62.3s-62.3,28-62.3,62.3S117.3,196.1,151.7,196.1z M151.7,96    c20.9,0,37.8,17,37.8,37.8s-17,37.8-37.8,37.8s-37.8-17-37.8-37.8S130.8,96,151.7,96z" /></g></g></svg></button>
    //                         <input ref={toolbarImageInputRef} type="file" accept="image/*" name="images" className="hidden" onChange={handleImageInputChange}></input>
    //                     </span>
    //                 </div>

    //             </div>
    //             <div className="ql-formats flex ms-auto items-center justify-center me-3" style={{ fontSize: ".85rem" }}>
    //                 <button className="include-trading-view-btn btn-default-reversed flex items-center disabled:opacity-30 me-2 my-2 px-3" style={{ border: "0", borderRadius: ".7rem" }} disabled={newPostInfo.newPostContent.asset === null} onClick={() => setNewPostInfo({ ...newPostInfo, includeChart: !newPostInfo.includeChart })}>
    //                     {newPostInfo.includeChart ? <><FontAwesomeIcon icon={faMinus} /> <span className="ms-3 me-2">Remove</span></> : <><FontAwesomeIcon icon={faPlus} /> <span className="ms-3 me-2">Include</span></>} <img src={tradingViewIcon} width="20px" />
    //                 </button>
    //                 {/* <button className="rating-toggle-btn btn-default-reversed flex items-center w-fit me-2 my-2 pt-1" style={{ border: "0", borderRadius: ".7rem" }} onClick={() => setNewPostInfo({ ...newPostInfo, includeChart: !newPostInfo.includeChart })}>Rating: ON</button> */}
    //                 <button id="post-writer-modal-post-btn" className="flex items-center btn-default-reversed disabled:opacity-80 !px-5 my-2 leading-[20px]" disabled={newPostInfo.creating} onClick={() => createPost()} style={{ width: "fit-content", borderRadius: "3rem" }}>{newPostInfo.creating ? <img src={loadingIconAnim} className="px-3 h-[20px] w-auto" /> : "Post"}</button>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    // const friendTechPostWriterModalBodyContent = <div id="post-writer-modal-body-content" className="flex flex-col w-full">
    //     <div id="post-writer-modal-title" className="flex flex-col mb-2">
    //         <input type="text" placeholder={newPostInfo.newPostContent.asset !== null ? `${newPostInfo.newPostContent.asset.info.symbol} ${newPostInfo.newPostContent.position}` : "Title"} id="post-writer-modal-title-input" value={newPostInfo.newPostContent.title} onChange={(e) => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.title = e.target.value;
    //             changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Title);
    //             setNewPostInfo(changedNewPostInfo)
    //         }} className={`post-writer-input text-2xl ps-3 ${newPostInfo.errors.includes(PostCreationError.Title) ? "post-writer-input-error" : ""}`} />
    //         <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Title) ? PostCreationError.Title.valueOf() : ""}</p>
    //     </div>
    //     <div id="post-writer-modal-trade-details" className="flex flex-row flex-wrap">
    //         <div id="post-writer-modal-trade-details-token" className="flex flex-col mt-1 me-2">
    //             <FtCreatorSelectorDropdown selectedFtCreatorShareInfo={newPostInfo.newPostContent.asset?.info} setSelectedFtCreator={(ftCreator: FtCreator | null) => {
    //                 const changedNewPostInfo = { ...newPostInfo };
    // const ftShareInfo: FtShareInfo | null = ftCreator !== null ? {
    //     address: ftCreator.address,
    //     networkId: "FT",
    //     symbol: ftCreator.twitterUsername,
    //     twitterName: ftCreator.twitterName,
    //     iconUrl: ftCreator.twitterPfpUrl,
    //     twitterUserId: ftCreator.twitterUserId,
    // } : null;
    // changedNewPostInfo.newPostContent.asset = ftShareInfo !== null ? { info: { ...ftShareInfo }, assetClass, assetId: getAssetId(ftShareInfo), priceAtCreation: 0, type: "ftShare" } : null;
    //                 changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Asset)
    //                 setNewPostInfo(changedNewPostInfo)
    //             }} className="h-full" buttonClassName={`h-full ${newPostInfo.errors.includes(PostCreationError.Asset) ? "post-writer-btn-error" : ""}`} />
    //             <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Asset) ? PostCreationError.Asset.valueOf() : ""}</p>
    //         </div>
    //         <div id="post-writer-modal-trade-details-position" className="flex flex-col mt-1">
    //             <div className="flex h-full">
    //                 <button onClick={() => {
    //                     const changedNewPostInfo = { ...newPostInfo };
    //                     changedNewPostInfo.newPostContent.position = "long";
    //                     setNewPostInfo(changedNewPostInfo)
    //                 }} className={`post-writer-btn long-btn mx-1 px-4 py-1 rounded-lg hover:bg-selection ${newPostInfo.newPostContent.position === "long" ? "!bg-selection" : ""}`}>LONG</button>
    //                 <button onClick={() => {
    //                     const changedNewPostInfo = { ...newPostInfo };
    //                     changedNewPostInfo.newPostContent.position = "short";
    //                     setNewPostInfo(changedNewPostInfo)
    //                 }} className={`post-writer-btn short-btn mx-1 px-4 py-1 rounded-lg hover:bg-error ${newPostInfo.newPostContent.position === "short" ? "!bg-error" : ""}`}>SHORT</button>
    //             </div>
    //             <p className="post-writer-input-error-text mb-0"></p>
    //         </div>

    //         <div id="post-writer-modal-trade-details-timeframe" className="flex items-center mx-2 mt-1">
    //             <FontAwesomeIcon icon={faCalendarDays} className="text-xl me-3 mb-[20px]" />
    //             <div className="flex flex-col justify-center items-center">
    //                 <Menu
    //                     placement="bottom-start"
    //                     dismiss={{
    //                         itemPress: true,
    //                     }}
    //                 >
    //                     <MenuHandler>
    //                         <Button className="flex items-center bg-neutral-750"><img src={ALPHA_TIME_RANGE_ICONS[newPostInfo.newPostContent.prediction.friendTech.timeframeRange]} className="h-[19.5px] w-auto me-2" /> {newPostInfo.newPostContent.prediction.friendTech.timeframeRange.valueOf()}</Button>
    //                     </MenuHandler>
    //                     <MenuList className="token-selector-dropdown-content bg-neutral-800 border-0 px-2 py-1 z-[9999]">
    //                         <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.friendTech.timeframeRange === AlphaPredictionTimeframeRange.Scalp ? "bg-neutral-900" : "")} onClick={() => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.prediction.friendTech.timeframeRange = AlphaPredictionTimeframeRange.Scalp
    //                             setNewPostInfo(changedNewPostInfo)
    //                         }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.Scalp]} className="h-[20px] w-auto me-2" /> Scalp</MenuItem>
    //                         <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.friendTech.timeframeRange === AlphaPredictionTimeframeRange.SwingTrade ? "bg-neutral-900" : "")} onClick={() => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.prediction.friendTech.timeframeRange = AlphaPredictionTimeframeRange.SwingTrade
    //                             setNewPostInfo(changedNewPostInfo)
    //                         }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.SwingTrade]} className="h-[20px] w-auto me-2" /> Swing Trade</MenuItem>
    //                         <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.friendTech.timeframeRange === AlphaPredictionTimeframeRange.ShortTerm ? "bg-neutral-900" : "")} onClick={() => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.prediction.friendTech.timeframeRange = AlphaPredictionTimeframeRange.ShortTerm
    //                             setNewPostInfo(changedNewPostInfo)
    //                         }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.ShortTerm]} className="h-[20px] w-auto me-2" /> Short Term</MenuItem>
    //                         <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.friendTech.timeframeRange === AlphaPredictionTimeframeRange.MidTerm ? "bg-neutral-900" : "")} onClick={() => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.prediction.friendTech.timeframeRange = AlphaPredictionTimeframeRange.MidTerm
    //                             setNewPostInfo(changedNewPostInfo)
    //                         }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.MidTerm]} className="h-[20px] w-auto me-2" /> Mid Term</MenuItem>
    //                         <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction.friendTech.timeframeRange === AlphaPredictionTimeframeRange.LongTerm ? "bg-neutral-900" : "")} onClick={() => {
    //                             const changedNewPostInfo = { ...newPostInfo };
    //                             changedNewPostInfo.newPostContent.prediction.friendTech.timeframeRange = AlphaPredictionTimeframeRange.LongTerm
    //                             setNewPostInfo(changedNewPostInfo)
    //                         }}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.LongTerm]} className="h-[20px] w-auto me-2" /> Long Term</MenuItem>
    //                     </MenuList>
    //                 </Menu>
    //                 <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.TimeframeEnd) ? PostCreationError.TimeframeEnd.valueOf() : ""}</p>
    //             </div>
    //         </div>
    //     </div>
    // {
    //     newPostInfo.newPostContent.asset?.type === "ftShare" ?
    //         <div id="alpha-post-alpha-post-ft-share-metrics" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
    //             <div className="flex flex-col mb-2 lg:mb-0">
    //                 <div className="flex items-center">
    //                     {fetchingFtShareInfoMetrics ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
    //                         <h2 className="mb-0">{(newPostInfo.newPostContent.asset.info as FtShareInfo).metrics !== undefined ? (newPostInfo.newPostContent.asset.info as FtShareInfo).metrics?.price : "-"}</h2>
    //                         <EthIcon className="w-[15px] h-auto ms-2" />
    //                     </>}
    //                 </div>
    //             </div>
    //             <div className="flex lg:ms-auto">
    //                 <div className="flex flex-col items-center mx-3">
    //                     <h6 className="pool-stat-header mb-1">Shares</h6>
    //                     {fetchingFtShareInfoMetrics ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{(newPostInfo.newPostContent.asset.info as FtShareInfo).metrics !== undefined ? (newPostInfo.newPostContent.asset.info as FtShareInfo).metrics!.shareSupply : "-"}</h5>}
    //                 </div>
    //                 <div className="flex flex-col items-center mx-3">
    //                     <h6 className="pool-stat-header mb-1">Holders</h6>
    //                     {fetchingFtShareInfoMetrics ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{(newPostInfo.newPostContent.asset.info as FtShareInfo).metrics !== undefined ? (newPostInfo.newPostContent.asset.info as FtShareInfo).metrics!.holderCount : "-"}</h5>}
    //                 </div>
    //             </div>
    //         </div> : ""
    // }
    //     <div id="post-writer-modal-trade-research" className="flex flex-col mt-3">
    //         <ReactQuill theme="snow" id="post-writer-modal-research" placeholder="" value={newPostInfo.newPostContent.research.content} onChange={(newValue) => {
    //             const changedNewPostInfo = { ...newPostInfo };
    //             changedNewPostInfo.newPostContent.research.content = newValue === "<p><br></p>" ? "" : newValue;
    //             changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.Research)
    //             setNewPostInfo(changedNewPostInfo);
    //         }} modules={alphaPostCreatorConfig.modules} formats={alphaPostCreatorConfig.formats} />
    //         <div id="post-writer-modal-research-media-container" className="flex border-x border-neutral-700 w-full px-3 pb-5">
    //             {/* <FileDropzone text="Drag and drop images or" labelClassName="py-5" className="w-full" /> */}
    //             <div className={`grid grid-cols-${newPostInfo.newPostContent.research.media.length >= 2 ? "2" : "1"} grid-rows-${newPostInfo.newPostContent.research.media.length >= 3 ? "2" : "1"} w-full`}>
    //                 {
    //                     newPostInfo.newPostContent.research.media.map((media, index) => {
    //                         return <div key={index} className="relative w-full">
    //                             <button type="button" className="btn-simple absolute mix-blend-difference text-2xl top-1 end-2" onClick={() => {
    //                                 URL.revokeObjectURL(media.filePreview);
    //                                 const changedNewPostInfo = { ...newPostInfo };
    //                                 changedNewPostInfo.newPostContent.research.media = changedNewPostInfo.newPostContent.research.media.filter((_, i) => i !== index);
    //                                 setNewPostInfo(changedNewPostInfo);
    //                             }}><FontAwesomeIcon icon={faXmark} /></button>
    //                             <img src={media.filePreview} className="w-full h-auto object-cover rounded-md" />
    //                         </div>
    //                     })
    //                 }
    //             </div>
    //         </div>
    //         <div id="post-writer-modal-research-toolbar" className="flex flex-wrap items-center">
    //             <div className="ql-toolbar ql-snow flex flex-col !border-none">
    //                 <div className="flex flex-wrap mb-2">
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-bold"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
    //                         <button type="button" className="ql-italic"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
    //                         <button type="button" className="ql-underline"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
    //                         <button type="button" className="ql-strike"><svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg></button>
    //                         <button type="button" className="ql-blockquote"><svg viewBox="0 0 18 18"> <rect className="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect> <rect className="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect> <path className="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path> <path className="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button>
    //                         <button type="button" className="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-link"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg></button>
    //                         <button type="button" className="ql-code"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button><button type="button" className="ql-code-block"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button type="button" className="ql-clean"><svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line> <line className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line> <line className="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line> <line className="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect> </svg></button>
    //                     </span>
    //                     <span className="ql-formats">
    //                         <button onClick={() => toolbarImageInputRef.current && toolbarImageInputRef.current.click()} type="button" className="ql-custom-image"><svg fill="currentColor" height="18px" width="18px" viewBox="0 0 500 500"><g><g><path d="M0,437.8c0,28.5,23.2,51.6,51.6,51.6h386.2c28.5,0,51.6-23.2,51.6-51.6V51.6c0-28.5-23.2-51.6-51.6-51.6H51.6    C23.1,0,0,23.2,0,51.6C0,51.6,0,437.8,0,437.8z M437.8,464.9H51.6c-14.9,0-27.1-12.2-27.1-27.1v-64.5l92.8-92.8l79.3,79.3    c4.8,4.8,12.5,4.8,17.3,0l143.2-143.2l107.8,107.8v113.4C464.9,452.7,452.7,464.9,437.8,464.9z M51.6,24.5h386.2    c14.9,0,27.1,12.2,27.1,27.1v238.1l-99.2-99.1c-4.8-4.8-12.5-4.8-17.3,0L205.2,333.8l-79.3-79.3c-4.8-4.8-12.5-4.8-17.3,0    l-84.1,84.1v-287C24.5,36.7,36.7,24.5,51.6,24.5z" /><path d="M151.7,196.1c34.4,0,62.3-28,62.3-62.3s-28-62.3-62.3-62.3s-62.3,28-62.3,62.3S117.3,196.1,151.7,196.1z M151.7,96    c20.9,0,37.8,17,37.8,37.8s-17,37.8-37.8,37.8s-37.8-17-37.8-37.8S130.8,96,151.7,96z" /></g></g></svg></button>
    //                         <input ref={toolbarImageInputRef} type="file" accept="image/*" name="images" className="hidden" onChange={handleImageInputChange}></input>
    //                     </span>
    //                 </div>
    //             </div>
    //             {/* <span className="ms-auto" style={{ color: `rgb(${200 - getHtmlTextLength(newPostInfo.newPostContent.research)}, ${getHtmlTextLength(newPostInfo.newPostContent.research)}, 0)` }}>Minimum: {getHtmlTextLength(newPostInfo.newPostContent.research)}/{MINIMUM_ALPHA_RESEARCH_LENGTH}</span> */}
    //             <div className="flex items-center ms-auto">
    //                 {/* <PopoverContainer className="text-sm !px-3 !py-2 !z-[10000] !bg-neutral-750 text-white rounded-lg" content={`Rating: ${newPostInfo.isRatingOn ? "On" : "Off"}`} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
    //                     <button className="relative flex w-[80px] h-[20px] bg-neutral-800 rounded-full overflow-hidden mx-2" onClick={() => setNewPostInfo({ ...newPostInfo, isRatingOn: !newPostInfo.isRatingOn })}>
    //                         <div className={"flex absolute w-1/2 h-full top-0 bottom-0 transition-all duration-150 " + (newPostInfo.isRatingOn ? "bg-selection left-1/2" : "bg-error left-0")}></div>
    //                         <div className="flex justify-center items-center absolute top-0 bottom-0 left-0 w-1/2 text-xs text-neutral-800"><FontAwesomeIcon icon={faXmark} className="h-[15px] w-auto me-1" /> No</div>
    //                         <div className="flex justify-center items-center absolute top-0 bottom-0 left-1/2 w-1/2 text-xs text-neutral-800"><ConfirmTick className="h-[15px] w-auto me-1" /> Yes</div>
    //                     </button>
    //                 </PopoverContainer> */}
    //                 <button id="post-writer-modal-post-btn" className="flex items-center btn-default-reversed disabled:opacity-80 mx-2 !px-5 my-2 leading-[20px]" disabled={newPostInfo.creating} onClick={() => createPost()} style={{ width: "fit-content", borderRadius: "3rem" }}>{newPostInfo.creating ? <img src={loadingIconAnim} className="px-3 h-[20px] w-auto" /> : "Post"}</button>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    const postContentPredictionContainerRef = useRef(null);
    useEffect(() => {
        if (!postContentPredictionContainerRef.current) return;
        toggleAdditionalInfo(false);
        toggleTechnicalAnalysisOptions(false);
    }, [postContentPredictionContainerRef.current])

    function toggleAdditionalInfo(toggleOn: boolean) {
        const changedNewPostInfo = { ...newPostInfo };
        changedNewPostInfo.newPostContent.prediction = toggleOn ? DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION : null;
        // changedNewPostInfo.newPostContent.postType = toggleOn ? "alpha" : "global";
        setNewPostInfo(changedNewPostInfo);
        console.log(changedNewPostInfo)

        gsap.to("#post-content-prediction-toggle svg", { rotateZ: !toggleOn ? 0 : -180, duration: 0.3 });
        const postContentPredictionInfoContainerElement = document.body.querySelector("#post-content-prediction-info-container");
        const postContentPredictionInfoContainerElementHeight = postContentPredictionInfoContainerElement?.clientHeight ?? 200;
        gsap.to("#post-content-prediction-info-container", { marginTop: !toggleOn ? `-${postContentPredictionInfoContainerElementHeight}px` : 0, duration: 0.4 });
    }

    function toggleTechnicalAnalysisOptions(toggleOn: boolean) {
        const changedNewPostInfo = { ...newPostInfo };
        // if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = { ...DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION, advancedOptions: DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION_ADVANCED_OPTIONS };
        if (changedNewPostInfo.newPostContent.prediction) {
            changedNewPostInfo.newPostContent.prediction.advancedOptions = toggleOn ? DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION_ADVANCED_OPTIONS : null;
            // changedNewPostInfo.newPostContent.postType = toggleOn ? "technicalAnalysis" : "alpha";
            setNewPostInfo(changedNewPostInfo);
        }

        gsap.to("#post-content-prediction-ta-options-toggle svg", { rotateZ: !toggleOn ? 0 : -180, duration: 0.3 });
        const postContentPredictionTaOptionsInfoContainerElement = document.body.querySelector("#post-content-prediction-ta-options-info-container");
        const postContentPredictionTaOptionsInfoContainerHeight = postContentPredictionTaOptionsInfoContainerElement?.clientHeight ?? 200;
        gsap.to("#post-content-prediction-ta-options-info-container", { marginTop: !toggleOn ? `-${postContentPredictionTaOptionsInfoContainerHeight}px` : 0, duration: 0.4 });
    }

    function selectTimeframeRange(timeframeRange: AlphaPredictionTimeframeRange) {
        const changedNewPostInfo = { ...newPostInfo };
        if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION;
        changedNewPostInfo.newPostContent.prediction.timeframeRange = timeframeRange
        setNewPostInfo(changedNewPostInfo)
    }

    const postContentPredictionContainer = <div ref={postContentPredictionContainerRef} id="post-content-prediction-container" className="flex flex-col mt-2">
        <button id="post-content-prediction-toggle" type="button" className="flex items-center btn-simple text-base text-neutral-300 w-fit" onClick={(e) => toggleAdditionalInfo(newPostInfo.newPostContent.prediction === null)}>Additional Info <ChevronDown className="w-[15px] h-auto ms-2" /></button>
        <div id="post-content-prediction" className="flex flex-col mt-2 overflow-y-hidden">
            <div id="post-content-prediction-info-container" className="flex flex-col">
                <div id="post-content-prediction-info" className="flex flex-row flex-wrap">
                    <div id="post-writer-modal-trade-details-token" className="flex flex-col mt-1 me-2">
                        <TokenSelectorDropdown selectedAsset={newPostInfo.newPostContent.prediction?.asset} setSelectedAsset={(asset: AlphaPostAssetInfo | null) => {
                            const changedNewPostInfo = { ...newPostInfo };
                            if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION;
                            changedNewPostInfo.newPostContent.prediction.asset = asset;
                            changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Asset)
                            setNewPostInfo(changedNewPostInfo)
                        }} />
                        <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Asset) ? PostCreationError.Asset.valueOf() : ""}</p>
                    </div>
                    <div id="post-writer-modal-trade-details-position" className="flex flex-col mt-1">
                        <div className="flex h-full">
                            <button onClick={() => {
                                const changedNewPostInfo = { ...newPostInfo };
                                if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION;
                                changedNewPostInfo.newPostContent.prediction.position = "long";
                                setNewPostInfo(changedNewPostInfo)
                            }} className={`post-writer-btn long-btn mx-1 px-4 py-1 rounded-lg hover:bg-selection ${newPostInfo.newPostContent.prediction?.position === "long" ? "!bg-selection" : ""}`}>LONG</button>
                            <button onClick={() => {
                                const changedNewPostInfo = { ...newPostInfo };
                                if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION;
                                changedNewPostInfo.newPostContent.prediction.position = "short";
                                setNewPostInfo(changedNewPostInfo)
                            }} className={`post-writer-btn short-btn mx-1 px-4 py-1 rounded-lg hover:bg-error ${newPostInfo.newPostContent.prediction?.position === "short" ? "!bg-error" : ""}`}>SHORT</button>
                        </div>
                        <p className="post-writer-input-error-text mb-0"></p>
                    </div>

                    <div id="post-writer-modal-trade-details-timeframe" className="flex items-center mx-2 mt-1">
                        <FontAwesomeIcon icon={faCalendarDays} className="text-xl me-3 mb-[20px]" />
                        <div className="flex flex-col justify-center items-center">
                            <Menu
                                placement="bottom-start"
                                dismiss={{
                                    itemPress: true,
                                }}
                            >
                                <MenuHandler>
                                    <Button className="flex items-center bg-neutral-750"><img src={newPostInfo.newPostContent.prediction ? ALPHA_TIME_RANGE_ICONS[newPostInfo.newPostContent.prediction.timeframeRange] : undefined} className="h-[19.5px] w-auto me-2" /> {newPostInfo.newPostContent.prediction?.timeframeRange.valueOf()}</Button>
                                </MenuHandler>
                                <MenuList className="token-selector-dropdown-content bg-neutral-800 border-0 px-2 py-1 z-[9999]">
                                    <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction?.timeframeRange === AlphaPredictionTimeframeRange.Scalp ? "bg-neutral-900" : "")} onClick={() => selectTimeframeRange(AlphaPredictionTimeframeRange.Scalp)}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.Scalp]} className="h-[20px] w-auto me-2" /> Scalp</MenuItem>
                                    <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction?.timeframeRange === AlphaPredictionTimeframeRange.SwingTrade ? "bg-neutral-900" : "")} onClick={() => selectTimeframeRange(AlphaPredictionTimeframeRange.SwingTrade)}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.SwingTrade]} className="h-[20px] w-auto me-2" /> Swing Trade</MenuItem>
                                    <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction?.timeframeRange === AlphaPredictionTimeframeRange.ShortTerm ? "bg-neutral-900" : "")} onClick={() => selectTimeframeRange(AlphaPredictionTimeframeRange.ShortTerm)}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.ShortTerm]} className="h-[20px] w-auto me-2" /> Short Term</MenuItem>
                                    <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction?.timeframeRange === AlphaPredictionTimeframeRange.MidTerm ? "bg-neutral-900" : "")} onClick={() => selectTimeframeRange(AlphaPredictionTimeframeRange.MidTerm)}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.MidTerm]} className="h-[20px] w-auto me-2" /> Mid Term</MenuItem>
                                    <MenuItem className={"flex items-center text-white my-1 hover:!bg-neutral-900 hover:!text-white " + (newPostInfo.newPostContent.prediction?.timeframeRange === AlphaPredictionTimeframeRange.LongTerm ? "bg-neutral-900" : "")} onClick={() => selectTimeframeRange(AlphaPredictionTimeframeRange.LongTerm)}><img src={ALPHA_TIME_RANGE_ICONS[AlphaPredictionTimeframeRange.LongTerm]} className="h-[20px] w-auto me-2" /> Long Term</MenuItem>
                                </MenuList>
                            </Menu>
                            <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.TimeframeEnd) ? PostCreationError.TimeframeEnd.valueOf() : ""}</p>
                        </div>
                    </div>
                </div>
                <button id="post-content-prediction-ta-options-toggle" type="button" className="flex items-center btn-simple text-base text-neutral-300 w-fit mt-2 ms-3" onClick={(e) => toggleTechnicalAnalysisOptions(newPostInfo.newPostContent.prediction?.advancedOptions === null)}>Trading Options <ChevronDown className="w-[15px] h-auto ms-2" /></button>
                <div id="post-content-prediction-ta-options" className="flex flex-col mt-2 overflow-y-hidden">
                    <div id="post-content-prediction-ta-options-info-container" className="flex flex-col">
                        <div id="post-content-prediction-ta-options-info" className="flex flex-row flex-wrap">
                            <div id="post-writer-modal-trade-details-entry" className="flex flex-col justify-center mx-2 mt-1">
                                <input type="number" placeholder="Entry" min={0} value={newPostInfo.newPostContent.prediction?.advancedOptions?.entryTarget} onChange={(e) => {
                                    const changedNewPostInfo = { ...newPostInfo };
                                    if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = { ...DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION, advancedOptions: DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION_ADVANCED_OPTIONS };
                                    changedNewPostInfo.newPostContent.prediction.advancedOptions!.entryTarget = Number(e.target.value);
                                    changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.EntryTarget);
                                    setNewPostInfo(changedNewPostInfo)
                                }} className={`post-writer-input ps-2 ${newPostInfo.errors.includes(PostCreationError.EntryTarget) ? "post-writer-input-error" : ""}`} />
                                <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.EntryTarget) ? PostCreationError.EntryTarget.valueOf() : ""}</p>
                            </div>
                            <div id="post-writer-modal-trade-details-exit" className="flex flex-col justify-center mx-2 mt-1">
                                <input type="number" placeholder="Exit" min={0} value={newPostInfo.newPostContent.prediction?.advancedOptions?.exitTargets[0]} onChange={(e) => {
                                    const changedNewPostInfo = { ...newPostInfo };
                                    if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = { ...DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION, advancedOptions: DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION_ADVANCED_OPTIONS };
                                    changedNewPostInfo.newPostContent.prediction.advancedOptions!.exitTargets = [Number(e.target.value)];
                                    changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.ExitTarget);
                                    setNewPostInfo(changedNewPostInfo)
                                }} className={`post-writer-input ps-2 ${newPostInfo.errors.includes(PostCreationError.ExitTarget) ? "post-writer-input-error" : ""}`} />
                                <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.ExitTarget) ? PostCreationError.ExitTarget.valueOf() : ""}</p>
                            </div>
                            <div id="post-writer-modal-trade-details-invalidation" className="flex flex-col justify-center mx-2 mt-1">
                                <input type="number" placeholder="Invalidation" min={0} value={newPostInfo.newPostContent.prediction?.advancedOptions?.invalidationPoint} onChange={(e) => {
                                    const changedNewPostInfo = { ...newPostInfo };
                                    if (!changedNewPostInfo.newPostContent.prediction) changedNewPostInfo.newPostContent.prediction = { ...DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION, advancedOptions: DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT_PREDICTION_ADVANCED_OPTIONS };
                                    changedNewPostInfo.newPostContent.prediction.advancedOptions!.invalidationPoint = Number(e.target.value);
                                    changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.MissingPostEndPoint);
                                    setNewPostInfo(changedNewPostInfo)
                                }} className={`post-writer-input ps-2 ${newPostInfo.errors.includes(PostCreationError.LowInvalidationOnShort) || newPostInfo.errors.includes(PostCreationError.HighInvalidationOnLong) || newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? "post-writer-input-error" : ""}`} />
                                <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.MissingPostEndPoint) ? PostCreationError.MissingPostEndPoint.valueOf() : newPostInfo.errors.includes(PostCreationError.LowInvalidationOnShort) ? PostCreationError.LowInvalidationOnShort.valueOf() : newPostInfo.errors.includes(PostCreationError.HighInvalidationOnLong) ? PostCreationError.HighInvalidationOnLong.valueOf() : ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    // newPostInfo.newPostContent.prediction?.asset?.type === "token" ? <>
                    newPostInfo.newPostContent.prediction?.asset?.type === "token" && (postTokenPoolStatsFetch.fetching || postTokenPoolStatsFetch.tokenPoolStats) ? <>
                        <div id="alpha-post-token-pool-stats" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
                            <div className="flex flex-col mb-2 lg:mb-0">
                                {/* <h5 className="pool-stat-header mb-0">Price</h5> */}
                                <div className="flex items-center">
                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
                                        <h2 className="mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.price ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.price)}` : "-"}</h2>
                                        <h6 className={`ms-2 mb-0 ${(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? "text-selection" : "text-error"}`}>{(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? <CaretUp /> : <CaretDown />} {postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h.toFixed(2) ?? "-"}%</h6>
                                    </>}
                                </div>
                            </div>
                            <div className="flex lg:ms-auto">
                                <div className="flex flex-col items-center mx-3">
                                    <h6 className="pool-stat-header mb-1">Volume</h6>
                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.volume24h ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.volume24h, true)}` : "-"}</h5>}
                                </div>
                                <div className="flex flex-col items-center mx-3">
                                    <h6 className="pool-stat-header mb-1">Liquidity</h6>
                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.liquidity ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.liquidity, true)}` : "-"}</h5>}
                                </div>
                                <div className="flex flex-col items-center mx-3">
                                    <h6 className="pool-stat-header mb-1">FDV</h6>
                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.fdv ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.fdv, true)}` : "-"}</h5>}
                                </div>
                            </div>
                        </div>
                        <button id="alpha-post-token-security-metrics-btn" className="btn-primary py-2 rounded-lg" onClick={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: true })}>Audit Scan</button>
                    </> : ""
                }
                {
                    newPostInfo.newPostContent.prediction?.asset?.type === "ftShare" ?
                        <div id="alpha-post-alpha-post-ft-share-metrics" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
                            <div className="flex flex-col mb-2 lg:mb-0">
                                <div className="flex items-center">
                                    {fetchingFtShareInfoMetrics ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
                                        <h2 className="mb-0">{(newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics !== undefined ? (newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics?.price : "-"}</h2>
                                        <EthIcon className="w-[15px] h-auto ms-2" />
                                    </>}
                                </div>
                            </div>
                            <div className="flex lg:ms-auto">
                                <div className="flex flex-col items-center mx-3">
                                    <h6 className="pool-stat-header mb-1">Shares</h6>
                                    {fetchingFtShareInfoMetrics ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{(newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics !== undefined ? (newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics!.shareSupply : "-"}</h5>}
                                </div>
                                <div className="flex flex-col items-center mx-3">
                                    <h6 className="pool-stat-header mb-1">Holders</h6>
                                    {fetchingFtShareInfoMetrics ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{(newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics !== undefined ? (newPostInfo.newPostContent.prediction.asset.info as FtShareInfo).metrics!.holderCount : "-"}</h5>}
                                </div>
                            </div>
                        </div> : ""
                }
            </div>
        </div>
    </div>

    const postContentContainer = <div id="post-content-container" className="flex flex-col w-full">
        <div id="post-writer-modal-title" className="flex flex-col">
            <input type="text" placeholder={newPostInfo.newPostContent.prediction?.asset ? `${newPostInfo.newPostContent.prediction?.asset.info.symbol} ${newPostInfo.newPostContent.prediction?.position}` : "Title"} id="post-writer-modal-title-input" value={newPostInfo.newPostContent.title} onChange={(e) => {
                const changedNewPostInfo = { ...newPostInfo };
                changedNewPostInfo.newPostContent.title = e.target.value;
                changedNewPostInfo.errors = changedNewPostInfo.errors.filter((error: PostCreationError) => error !== PostCreationError.Title);
                setNewPostInfo(changedNewPostInfo)
            }} className={`post-writer-input text-2xl ps-3 ${newPostInfo.errors.includes(PostCreationError.Title) ? "post-writer-input-error" : ""}`} />
            {/* <p className="post-writer-input-error-text mb-0">{newPostInfo.errors.includes(PostCreationError.Title) ? PostCreationError.Title.valueOf() : ""}</p> */}
        </div>

        {postContentPredictionContainer}

        <div id="post-writer-modal-trade-research" className="flex flex-col mt-3">
            <ReactQuill theme="snow" id="post-writer-modal-research" placeholder="" value={newPostInfo.newPostContent.research.content} onChange={(newValue) => {
                const changedNewPostInfo = { ...newPostInfo };
                changedNewPostInfo.newPostContent.research.content = newValue === "<p><br></p>" ? "" : newValue;
                changedNewPostInfo.errors = changedNewPostInfo.errors.filter(error => error !== PostCreationError.Research)
                setNewPostInfo(changedNewPostInfo);
            }} modules={alphaPostCreatorConfig.modules} formats={alphaPostCreatorConfig.formats} />
            <div id="post-writer-modal-research-media-container" className="flex border-x border-neutral-700 w-full px-3 pb-5">
                {/* <FileDropzone text="Drag and drop images or" labelClassName="py-5" className="w-full" /> */}
                <div className={`grid grid-cols-${newPostInfo.newPostContent.research.media.length >= 2 ? "2" : "1"} grid-rows-${newPostInfo.newPostContent.research.media.length >= 3 ? "2" : "1"} w-full`}>
                    {
                        newPostInfo.newPostContent.research.media.map((media, index) => {
                            return <div key={index} className="relative w-full">
                                <button type="button" className="btn-simple absolute mix-blend-difference text-2xl top-1 end-2" onClick={() => {
                                    URL.revokeObjectURL(media.filePreview);
                                    const changedNewPostInfo = { ...newPostInfo };
                                    changedNewPostInfo.newPostContent.research.media = changedNewPostInfo.newPostContent.research.media.filter((_, i) => i !== index);
                                    setNewPostInfo(changedNewPostInfo);
                                }}><FontAwesomeIcon icon={faXmark} /></button>
                                <img src={media.filePreview} className="w-full h-auto object-cover rounded-md" />
                            </div>
                        })
                    }
                </div>
            </div>
            <div id="post-writer-modal-research-toolbar" className="flex flex-wrap items-center">
                <div className="ql-toolbar ql-snow flex flex-col !border-none">
                    <div className="flex flex-wrap mb-2">
                        <span className="ql-formats">
                            <button type="button" className="ql-bold"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
                            <button type="button" className="ql-italic"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
                            <button type="button" className="ql-underline"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
                            <button type="button" className="ql-strike"><svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg></button>
                            <button type="button" className="ql-blockquote"><svg viewBox="0 0 18 18"> <rect className="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect> <rect className="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect> <path className="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path> <path className="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path> </svg></button>
                        </span>
                        <span className="ql-formats">
                            <button type="button" className="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button>
                            <button type="button" className="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
                            {/* <button type="button" className="ql-indent" value="-1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-stroke" points="5 7 5 11 3 9 5 7"></polyline> </svg></button>
                            <button type="button" className="ql-indent" value="+1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-fill ql-stroke" points="3 7 3 11 5 9 3 7"></polyline> </svg></button> */}
                        </span>
                        <span className="ql-formats">
                            <button type="button" className="ql-link"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg></button>
                            <button type="button" className="ql-code"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button><button type="button" className="ql-code-block"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button>
                        </span>
                        <span className="ql-formats">
                            <button type="button" className="ql-clean"><svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line> <line className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line> <line className="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line> <line className="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect> </svg></button>
                        </span>
                        <span className="ql-formats">
                            <button onClick={() => toolbarImageInputRef.current && toolbarImageInputRef.current.click()} type="button" className="ql-custom-image"><svg fill="currentColor" height="18px" width="18px" viewBox="0 0 500 500"><g><g><path d="M0,437.8c0,28.5,23.2,51.6,51.6,51.6h386.2c28.5,0,51.6-23.2,51.6-51.6V51.6c0-28.5-23.2-51.6-51.6-51.6H51.6    C23.1,0,0,23.2,0,51.6C0,51.6,0,437.8,0,437.8z M437.8,464.9H51.6c-14.9,0-27.1-12.2-27.1-27.1v-64.5l92.8-92.8l79.3,79.3    c4.8,4.8,12.5,4.8,17.3,0l143.2-143.2l107.8,107.8v113.4C464.9,452.7,452.7,464.9,437.8,464.9z M51.6,24.5h386.2    c14.9,0,27.1,12.2,27.1,27.1v238.1l-99.2-99.1c-4.8-4.8-12.5-4.8-17.3,0L205.2,333.8l-79.3-79.3c-4.8-4.8-12.5-4.8-17.3,0    l-84.1,84.1v-287C24.5,36.7,36.7,24.5,51.6,24.5z" /><path d="M151.7,196.1c34.4,0,62.3-28,62.3-62.3s-28-62.3-62.3-62.3s-62.3,28-62.3,62.3S117.3,196.1,151.7,196.1z M151.7,96    c20.9,0,37.8,17,37.8,37.8s-17,37.8-37.8,37.8s-37.8-17-37.8-37.8S130.8,96,151.7,96z" /></g></g></svg></button>
                            <input ref={toolbarImageInputRef} type="file" accept="image/*" name="images" className="hidden" onChange={handleImageInputChange}></input>
                        </span>
                    </div>
                </div>
                {/* <span className="ms-auto" style={{ color: `rgb(${200 - getHtmlTextLength(newPostInfo.newPostContent.research)}, ${getHtmlTextLength(newPostInfo.newPostContent.research)}, 0)` }}>Minimum: {getHtmlTextLength(newPostInfo.newPostContent.research)}/{MINIMUM_ALPHA_RESEARCH_LENGTH}</span> */}
                <div className="flex items-center ms-auto">
                    <PopoverContainer className="text-sm !px-3 !py-2 !z-[10000] !bg-neutral-750 text-white rounded-lg" content={`Rating: ${newPostInfo.isRatingOn ? "On" : "Off"}`} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
                        <button className="relative flex w-[80px] h-[20px] bg-neutral-800 rounded-full overflow-hidden mx-2" onClick={() => setNewPostInfo({ ...newPostInfo, isRatingOn: !newPostInfo.isRatingOn })}>
                            <div className={"flex absolute w-1/2 h-full top-0 bottom-0 transition-all duration-150 " + (newPostInfo.isRatingOn ? "bg-selection left-1/2" : "bg-error left-0")}></div>
                            <div className="flex justify-center items-center absolute top-0 bottom-0 left-0 w-1/2 text-xs text-neutral-800"><FontAwesomeIcon icon={faXmark} className="h-[15px] w-auto me-1" /> No</div>
                            <div className="flex justify-center items-center absolute top-0 bottom-0 left-1/2 w-1/2 text-xs text-neutral-800"><ConfirmTick className="h-[15px] w-auto me-1" /> Yes</div>
                        </button>
                    </PopoverContainer>
                    <button id="post-writer-modal-post-btn" className="flex items-center btn-default-reversed disabled:opacity-80 mx-2 !px-5 my-2 leading-[20px]" disabled={newPostInfo.creating} onClick={() => createPost()} style={{ width: "fit-content", borderRadius: "3rem" }}>{newPostInfo.creating ? <img src={loadingIconAnim} className="px-3 h-[20px] w-auto" /> : "Post"}</button>
                </div>
            </div>
        </div>
    </div>

    return (
        <Dialog
            id="post-writer-modal"
            size="lg"
            className="bg-neutral-900 text-white border border-neutral-800 rounded-3xl"
            open={isWriterDialogOpen}
            handler={closeWriterDialog}
        >
            <DialogHeader id="post-writer-modal-header">
                <h3 className="flex justify-between items-center text-header w-full text-white">
                    <div className="flex items-end">
                        <span>NEW POST</span>
                        {/* {newPostInfo.newPostContent.postType !== null ? <span className="text-xs text-neutral-400 hover:text-white underline mb-1 ms-1 transition-all duration-200">({newPostInfo.newPostContent.postType === "alpha" ? "Alpha" : newPostInfo.newPostContent.postType === "technicalAnalysis" ? "T.A" : "FT"})</span> : ""} */}
                    </div>
                    <button className="btn-simple" onClick={closeWriterDialog}><FontAwesomeIcon icon={faXmark} /></button>
                </h3>
            </DialogHeader>
            <DialogBody divider id="post-writer-modal-body" className="flex text-normal font-normal border-b-0">
                {/* {newPostInfo.newPostContent.postType === null ? postWriterTypeSelectionContainer : newPostInfo.newPostContent.postType === "alpha" ? alphaPostWriterModalBodyContent : newPostInfo.newPostContent.postType === "technicalAnalysis" ? technicalAnalysisPostWriterModalBodyContent : friendTechPostWriterModalBodyContent} */}
                {postContentContainer}
            </DialogBody>
            {newPostInfo.newPostContent.prediction?.asset?.type === "token" && postTokenSecurityMetricsFetch.drawerVisible && <TokenSecurityMetricsDrawer tokenInfo={newPostInfo.newPostContent.prediction.asset.info} postTokenSecurityMetricsFetch={postTokenSecurityMetricsFetch} close={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: false })} />}
        </Dialog>
    )
};

export default AlphaPostWriterModal;