import { gsap } from "gsap";

import { AlphaPostInfo, CoinInfo, FtShareInfo, NFTInfo, TokenInfo } from "./types"
import api from "./api/api";
import { CHAIN_SLUGS } from "./constants";

import GradientImg0 from "../media/images/gradient_0.png";
import GradientImg1 from "../media/images/gradient_1.png";
import GradientImg2 from "../media/images/gradient_2.png";
import GradientImg3 from "../media/images/gradient_3.png";
import GradientImg4 from "../media/images/gradient_4.png";
import GradientImg5 from "../media/images/gradient_5.png";
import GradientImg6 from "../media/images/gradient_6.png";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export function shortenAddress(address: string, borderLengths: number = 5): string {
    return address.slice(0, borderLengths) + "..." + address.slice(-borderLengths, address.length)
}

export function timestampToSmallDate(timestamp: number) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    const time = `${month} ${date}`
    return time;
}
export function timestampToLongDate(timestamp: number) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    // const time = `${month} ${date}`
    return time;
}

export function timestampToRelative(msTimestamp: number, replaceSmallGap = false): string {
    const currentTimestamp = Date.now();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = currentTimestamp - msTimestamp;
    const prefix = elapsed > 0 ? "" : "in ";
    const suffix = elapsed > 0 ? " ago" : "";
    elapsed = Math.abs(elapsed)

    if (replaceSmallGap && elapsed < msPerMinute) return "now";
    let finalResult = "";
    if (elapsed < msPerMinute) {
        const result = Math.floor(elapsed / 1000);
        finalResult = result + ` second${result > 1 ? "s" : ""}`;
    }

    else if (elapsed < msPerHour) {
        const result = Math.floor(elapsed / msPerMinute);
        finalResult = result + ` minute${result > 1 ? "s" : ""}`;
    }

    else if (elapsed < msPerDay) {
        const result = Math.floor(elapsed / msPerHour);
        finalResult = result + ` hour${result > 1 ? "s" : ""}`;
    }

    else if (elapsed < msPerMonth) {
        const result = Math.floor(elapsed / msPerDay);
        finalResult = result + ` day${result > 1 ? "s" : ""}`;
    }

    else if (elapsed < msPerYear) {
        const result = Math.floor(elapsed / msPerMonth);
        finalResult = result + ` month${result > 1 ? "s" : ""}`;
    }

    else {
        const result = Math.floor(elapsed / msPerYear);
        finalResult = result + ` year${result > 1 ? "s" : ""}`;
    }

    finalResult = prefix + finalResult + suffix;
    return finalResult;
}

export function timestampToClockTime(timestampInSeconds: number): string {
    if (timestampInSeconds <= 0) return "00:00:00";

    const timestampInMinutes = Math.floor(timestampInSeconds / 60);
    const timestampInHours = Math.floor(timestampInSeconds / 3600);

    const seconds = Math.floor(timestampInSeconds) % 60;
    const minutes = timestampInMinutes % 60;
    const hours = timestampInHours % 24;
    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
}

export function checkIfBothDatesSameDay(firstDate: Date, secondDate: Date) {
    return firstDate.getDate() === secondDate.getDate() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getFullYear() === secondDate.getFullYear();
}

export function getFormattedUnixDate(date: Date) {
    const formattedDate = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC' }).format(date);
    const formattedHour = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC', hour12: false, hour: 'numeric' }).format(date);

    return { formattedDate, formattedHour };
}


export function getFullBaseUrl(window: Window, encode = false) {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? ":" + window.location.port : ""}`
    return encode ? encodeURIComponent(baseUrl) : baseUrl;
}

export function numberWithCommas(num: number | string) {
    if (typeof num !== "number" && typeof num !== "string") return num;
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function getRoundedPrice(price: number): string {
    if (typeof price !== "number" && typeof price !== "string") return price;

    let stringPrice = price.toString();
    if (stringPrice.includes("e-")) {
        const power = stringPrice.substring(stringPrice.indexOf("e"))
        return Number(stringPrice.substring(0, stringPrice.indexOf(power))).toFixed(2) + power;
    }
    let decimalPart = stringPrice.split('.')[1]?.substring(0, 9);
    if (decimalPart === undefined) return stringPrice;

    const nonZeroDecimalPart = Number(decimalPart).toString();
    return (price + Number.EPSILON).toFixed(price > 0.1 ? 2 : (3 + decimalPart.length - nonZeroDecimalPart.length));
    // return Math.round((price + Number.EPSILON) * 100) / 100;
}

export function formatNumberForPrice(num: number, useWithSymbol = false) {
    if (num > 1) {
        if (useWithSymbol && num >= 10000) {
            const units = ["", "K", "M", "B", "T"];
            const index = Math.floor(Math.log10(num) / 3);
            const shortenedNum = num / Math.pow(1000, index);
            return shortenedNum.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + units[index];
        } else {
            return num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    } else {
        const significantDigits = num.toPrecision(3);
        const parsedNumber = parseFloat(significantDigits);
        if (parsedNumber >= 0.000001) {
            const decimalPlaces = significantDigits.split('.')[1]?.length || 0;
            return parsedNumber.toLocaleString('en-US', { minimumFractionDigits: decimalPlaces });
        } else {
            // const [, exponent] = significantDigits.split("e");
            // const decimalPlaces = Math.abs(Number(exponent)) + 2;
            // return parsedNumber.toFixed(decimalPlaces);
            return parsedNumber.toString();
        }
    }
}

// export function arrangePostComments(alphaPostInfo: AlphaPostInfo): AlphaPostInfo {
//     const creatorComments = [];
//     const otherComments = [];
//     for (const comment of alphaPostInfo.interactions.comments) {
//         if (comment.creation.user.userId !== alphaPostInfo.creation.user.userId) otherComments.push(comment);
//         else creatorComments.push(comment);
//     }
//     creatorComments.reverse();
//     otherComments.reverse();
//     alphaPostInfo.interactions.comments = [...creatorComments, ...otherComments];
//     return alphaPostInfo;
// }

export function getHtmlTextLength(html: string) {
    return new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent?.length ?? 0;
}

export function getScrollBarWidth() {
    let el = document.createElement("div");
    el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
    document.body.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    el.remove();
    return width;
}

export const DEFINED_TOKEN_ICON_URL = (token: { networkId: number, address: string }) => `https://d3kdei7cs9pk0w.cloudfront.net/${token.networkId}_${token.address}_large.png`

export function getAssetId(asset: CoinInfo | TokenInfo | NFTInfo | FtShareInfo) {
    return (asset as CoinInfo).id ?? `${(asset as TokenInfo | NFTInfo | FtShareInfo).address}:${(asset as TokenInfo | NFTInfo | FtShareInfo).networkId ?? ""}`;
}

export async function searchForTokens(searchQuery: string, limit: number) {
    if (!searchQuery.length) return [];
    const searchTokensResult = await api.defi.searchToken(searchQuery, limit);
    const searchedTokens: TokenInfo[] = []
    for (const tokenResult of searchTokensResult) {
        searchedTokens.push({
            name: tokenResult.name,
            symbol: tokenResult.symbol,
            address: tokenResult.address,
            networkId: tokenResult.networkId,
            topPoolId: tokenResult.topPairId.split(":")[0],
            iconUrl: DEFINED_TOKEN_ICON_URL(tokenResult),

            metrics: {
                liquidity: tokenResult.liquidity,
                price: tokenResult.price,
                priceChange: tokenResult.priceChange,
                volume: tokenResult.volume,
            },

            chain: { slug: CHAIN_SLUGS[tokenResult.networkId], icon: `https://icons.llamao.fi/icons/chains/rsz_${CHAIN_SLUGS[tokenResult.networkId]}.jpg` }
        })
    }
    return searchedTokens;
}

export const randomGradientImg = () => {
    const gradientImages = [
        GradientImg0,
        GradientImg1,
        GradientImg2,
        GradientImg3,
        GradientImg4,
        GradientImg5,
        GradientImg6,
    ];
    return gradientImages[Math.floor(Math.random() * gradientImages.length)];
}


export function extractTextFromHTML(html: string) {
    // Remove HTML tags and their contents
    const textWithoutTags = html.replace(/<[^>]*>/g, '');

    // Remove extra white spaces and trim
    const cleanedText = textWithoutTags.replace(/\s+/g, ' ').trim();

    return cleanedText;
}

export function formatTradingViewChartUrl(inputURL: string): string {
    const lowercasedFirstLetter = inputURL.match(/\/x\/([a-zA-Z0-9]+)/)?.[1]?.[0]?.toLowerCase();

    if (lowercasedFirstLetter) return inputURL.replace(/https:\/\/www\.tradingview\.com\/x\/([a-zA-Z0-9]+)\/?$/, `https://s3.tradingview.com/snapshots/${lowercasedFirstLetter}/$1.png`);
    else return inputURL;
}

export function getSharePostOnTwitterLink(alphaPost: AlphaPostInfo) {
    return `https://twitter.com/intent/tweet?text=${encodeURI(extractHTMLContent(alphaPost.content.research.content))}%0A%0A&url=${getFullBaseUrl(window, true)}%2Fbig-board%2F${alphaPost.id}`;
}

export function extractHTMLContent(s: string) {
    var span = document.createElement('span');
    span.innerHTML = s.replaceAll("<br>", "\n");
    return span.textContent || span.innerText;
};
