import alphaPost from "./routes/alphaPost"
import marketNews from "./routes/marketNews"
import defi from "./routes/defi";
import onchain from "./routes/onchain";
import users from "./routes/users";

import auth from "./auth/auth"

// export const API_URL = "http://localhost:3001/v1";
export const API_URL = "https://us-central1-primordium-alpha-club.cloudfunctions.net/api/v1";

export default {
    alphaPost,
    marketNews,
    defi,
    onchain,
    users,

    auth
}