import './App.css';
import './ToastifyOverride.css';
import "./styles/app.css"
import "./styles/gradient.css"
import "./styles/periphery.css"

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import MainHandler from './MainHandler'

import { UserProvider } from './Components/Providers/UserProvider';
import { AlphaPostWriterProvider } from './Components/Providers/AlphaPostWriterProvider';
import { DataCacheProvider } from './Components/Providers/DataCacheProvider';


gsap.registerPlugin(ScrollTrigger)

function App() {

    return (
        <DataCacheProvider>
            <UserProvider>
                <AlphaPostWriterProvider>
                    <div className="App">
                        <MainHandler />
                    </div>
                    <div className="text-white mb-2 ms-2" style={{ position: "fixed", bottom: "0", left: "0" }}>
                        v0.5
                    </div>
                </AlphaPostWriterProvider>
            </UserProvider>
        </DataCacheProvider>
    );
}

export default App;
