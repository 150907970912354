import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBCs4VMFFijWswBgqykaCNuQr2cWVhew8g",
    authDomain: "primordium-alpha-club.firebaseapp.com",
    projectId: "primordium-alpha-club",
    storageBucket: "primordium-alpha-club.appspot.com",
    messagingSenderId: "269265019894",
    appId: "1:269265019894:web:bb2d7acd23657ad38a9dd7",
    measurementId: "G-Z9EH4Y6Z89"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const firestoreDB = getFirestore();
export const firebaseAuth = getAuth();
export const firebaseStorage = getStorage();