import "./BigBoard.css";
import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";
import queryString from "query-string";

import { useHistory } from "react-router-dom";

import mixpanel from "mixpanel-browser";

import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
} from "@material-tailwind/react";

import alphaClubIcon from "../../media/icons/alphaClubIcon.png";
import friendTechIcon from "../../media/icons/friendTechIcon.png";
import cryptoIcon from "../../media/icons/ethereumToken.png";
import { ReactComponent as ConfirmTick } from "../../media/icons/confirmTick.svg";

import { IAlphaPostFilters, PostOrderBy, PostsListFetch } from "./types";
import { AlphaPostInfo, AssetClass } from "../../utils/types"

import NewsFeedWidget from "./Widgets/NewsFeedWidget"
import AlphaPostsWidget from "./Widgets/AlphaPostsWidget"

import AlphaPostWriterModal from "./Components/AlphaPostWriterModal"

import { getAssetId, searchForTokens } from "../../utils/utils";

import { useAlphaPostWriter } from "../../Components/Providers/useAlphaPostWriter";
import firebaseHelpers from "../../utils/firebaseHelpers";
import { SearchBox } from "./Components/SearchBox";
import ConnectionButton from "../../Components/Connection/ConnectionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faFire } from "@fortawesome/free-solid-svg-icons";

const NUM_POSTS_RESULTS_ON_FETCH = 10;
const DEFAULT_FILTERS: IAlphaPostFilters = {
    assets: [], authors: null, assetClass: AssetClass.Crypto, search: null, orderBy: PostOrderBy.Recent, loadingFilters: false
}

const BigBoard: FC<any> = (props) => {
    const { searchedAssetClass, searchedAlphaPostId, createNewPostIntent } = props;

    const history = useHistory();

    // const [alphaPostSorting, setAplhaPostSorting] = useState<AlphaPostSorting>({ field: PostsQueryField.CreationTime, direction: PostsQueryOrder.DESCENDING });
    const [alphaPostFilters, setAlphaPostFilters] = useState<IAlphaPostFilters>({ ...DEFAULT_FILTERS, assetClass: searchedAssetClass === undefined ? DEFAULT_FILTERS.assetClass : searchedAssetClass })
    const [postsListFetch, setPostsListFetch] = useState<PostsListFetch>({ fetching: false, alphaPosts: [], fetchError: false, allFetched: false, fetch: fetchAlphaPosts });
    const { isWritingPost, setIsWritingPost } = useAlphaPostWriter();

    const scrollContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setAlphaPostFilters({ ...alphaPostFilters, assetClass: searchedAssetClass === undefined ? DEFAULT_FILTERS.assetClass : searchedAssetClass })
    }, [searchedAssetClass])

    // useEffect(() => {
    //     checkForUrlFilters();
    // }, [])
    // async function checkForUrlFilters() {
    //     const { filterToken, filterAuthor } = queryString.parse(window.location.search);
    //     if (filterToken) {
    //         setAlphaPostFilters({ ...alphaPostFilters, loadingFilters: true });
    //         const filterTokenSearched = await searchForTokens(typeof filterToken === "string" ? filterToken : filterToken[0], 1);
    //         console.log(filterTokenSearched)
    //         setAlphaPostFilters({ ...DEFAULT_FILTERS, authors: filterAuthor ? typeof filterAuthor === "string" ? [filterAuthor] : [...filterAuthor] : null, assets: filterTokenSearched, loadingFilters: false });
    //     }
    //     else if (filterAuthor) setAlphaPostFilters({ ...DEFAULT_FILTERS, authors: filterAuthor ? typeof filterAuthor === "string" ? [filterAuthor] : [...filterAuthor] : null });
    // }

    useEffect(() => {
        if (createNewPostIntent) {
            setIsWritingPost(true);
        }
    }, [createNewPostIntent])

    const didMount = useRef(false);
    useEffect(() => {
        // const { filterToken, filterAuthor } = queryString.parse(window.location.search);
        // if (!filterToken && !filterAuthor && postsListFetch.alphaPosts.length === 0 && searchedAlphaPostId === undefined) fetchAlphaPosts(undefined, true);
        setTimeout(() => didMount.current = true, 1);
    }, []);

    useEffect(() => {
        const { filterToken, filterAuthor } = queryString.parse(window.location.search);
        if (didMount.current && !filterToken && !filterAuthor && postsListFetch.alphaPosts.length === 0 && searchedAlphaPostId === undefined) fetchAlphaPosts(undefined, true);
    }, [searchedAlphaPostId])
    useEffect(() => {
        const controller = new AbortController();
        let fetchTimeout: NodeJS.Timeout;
        if (searchedAlphaPostId === undefined) fetchTimeout = setTimeout(() => { console.log("from filters"); fetchAlphaPosts(controller.signal, true) }, 500);
        return () => {
            controller.abort();
            clearTimeout(fetchTimeout);
        };
    }, [alphaPostFilters]);

    async function fetchAlphaPosts(signal?: AbortSignal, clearPreviousFetches = false) {
        if (alphaPostFilters === undefined || alphaPostFilters.loadingFilters || (!clearPreviousFetches && postsListFetch.allFetched)) return;
        setPostsListFetch({ ...postsListFetch, fetching: true, alphaPosts: clearPreviousFetches ? [] : postsListFetch.alphaPosts, fetchError: false });
        const createdBefore = (clearPreviousFetches || postsListFetch.alphaPosts.length <= 0) ? alphaPostFilters.orderBy === PostOrderBy.Recent ? Date.now() : 100_000_000_000 :
            alphaPostFilters.orderBy === PostOrderBy.Recent ? postsListFetch.alphaPosts[postsListFetch.alphaPosts.length - 1].creation.timestamp :
                postsListFetch.alphaPosts[postsListFetch.alphaPosts.length - 1].interactions.totalVoteScore; // With top 1h or top 24h, the next fetch (when scrolling down) will skip the posts with the same vote score as the last post. This is due to how we're getting around firestore's query limitations. Nothing to do.
        const { success, alphaPosts } = await firebaseHelpers.alphaPosts.getRecentPosts(alphaPostFilters.orderBy, NUM_POSTS_RESULTS_ON_FETCH, createdBefore, -100000000000, alphaPostFilters.assets?.map((asset: any) => getAssetId(asset)) ?? undefined, searchedAssetClass === undefined ? DEFAULT_FILTERS.assetClass : searchedAssetClass, alphaPostFilters.authors ?? undefined);
        if (signal?.aborted) return;
        // const newAlphaPosts = alphaPosts.map((post: AlphaPostInfo) => arrangePostComments(post))
        setPostsListFetch({ ...postsListFetch, fetching: false, alphaPosts: clearPreviousFetches ? alphaPosts : [...postsListFetch.alphaPosts, ...alphaPosts], fetchError: !success, allFetched: success && alphaPosts.length < NUM_POSTS_RESULTS_ON_FETCH });
    }

    const handleScroll = () => {
        if (!scrollContainer.current) return;
        const bottom = Math.ceil(scrollContainer.current.clientHeight + scrollContainer.current.scrollTop) >= scrollContainer.current.scrollHeight

        if (bottom) {
            console.log("BOTTOMED")
            if (!isWritingPost) fetchAlphaPosts();
        }
    };
    useEffect(() => {
        if (!scrollContainer.current) return;
        scrollContainer.current.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            scrollContainer.current?.removeEventListener('scroll', handleScroll);
        };
    }, [scrollContainer.current, isWritingPost, postsListFetch, alphaPostFilters]);



    function verifyIfPostMeetsFilters(alphaPost: AlphaPostInfo): boolean {
        if (alphaPostFilters === undefined) return true;
        if (alphaPostFilters.assets !== null && alphaPost.content.prediction?.asset && !alphaPostFilters.assets.map(asset => asset.symbol).includes(alphaPost.content.prediction.asset.info.symbol)) return false;
        if (alphaPostFilters.authors !== null && !alphaPostFilters.authors.includes(alphaPost.creation.user.userId)) return false;
        if (alphaPostFilters.assetClass !== null && alphaPost.content.prediction?.asset && alphaPostFilters.assetClass !== alphaPost.content.prediction.asset.assetClass) return false;
        return true;
    }


    return (
        <div id="big-board" className="relative col-span-12 max-h-inherit flex flex-1 flex-col xl:col-span-10">
            <header className="bg-transparent px-6 pt-6 pb-3 xl:px-10 flex gap-4 items-start w-full" style={{ height: "92px" }}>
                <div className="flex w-full flex-1 justify-center h-[56px]">
                    <SearchBox alphaPostFilters={alphaPostFilters} setAlphaPostFilters={setAlphaPostFilters} />
                </div>
                <ConnectionButton className="hidden xl:flex" />
            </header>
            <div id="big-board-container" className="w-full flex flex-1 overflow-y-auto" style={{ maxHeight: "calc(100% - 92px)" }}>
                <div className="relative flex h-full w-full gap-x-8 px-3 md:px-6 pb-0" style={{ height: "inherit" }}>
                    <div id="big-board-alpha-posts-container" className="flex flex-col rounded-xl px-1 md:px-4 w-full max-w-[1100px]">
                        {/* <FiltersWidget className="flex mb-5 xl:hidden" alphaPostFilters={alphaPostFilters} setAlphaPostFilters={setAlphaPostFilters} /> */}
                        <div className="flex items-center justify-between">
                            <h1 className="text-5xl text-header font-bold header-gradient sticky top-0 pb-3">Home</h1>
                            <div className="flex items-center">
                                <Popover placement="bottom">
                                    <PopoverHandler>
                                        <Button className="bg-neutral-800 min-w-[150px] leading-[14px] flex items-center justify-center px-3 me-4">
                                            <FontAwesomeIcon icon={alphaPostFilters.orderBy === PostOrderBy.Recent ? faClock : faFire} className={`${alphaPostFilters.orderBy === PostOrderBy.Top24h ? "text-primary-blue" : "text-white"} token-icon me-2`} />
                                            {alphaPostFilters.orderBy === PostOrderBy.Recent ? "Recent" : alphaPostFilters.orderBy === PostOrderBy.Top1h ? "Top 1h" : "Top 24h"}
                                        </Button>
                                    </PopoverHandler>
                                    <PopoverContent className="flex flex-col bg-neutral-900 outline-none !border !border-neutral-800 shadow-black p-2" style={{ border: "inherit" }}>
                                        <div className="flex w-full">
                                            <Button className={"bg-transparent flex items-center justify-between normal-case font-thin text-white w-full px-2 " + (alphaPostFilters.orderBy === PostOrderBy.Recent ? "bg-neutral-950" : "")} onClick={() => {
                                                setAlphaPostFilters({ ...alphaPostFilters, orderBy: PostOrderBy.Recent })
                                            }}>
                                                <div className="flex items-center">
                                                    <FontAwesomeIcon icon={faClock} className="text-white me-2" />
                                                    <p className="text-sm">Recent</p>
                                                </div>
                                                {alphaPostFilters.orderBy === PostOrderBy.Recent ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}
                                            </Button>
                                        </div>
                                        <div className="flex w-full">
                                            <Button className={"bg-transparent flex items-center justify-between normal-case font-thin text-white w-full px-2 " + (alphaPostFilters.orderBy === PostOrderBy.Top1h ? "bg-neutral-950" : "")} onClick={() => {
                                                setAlphaPostFilters({ ...alphaPostFilters, orderBy: PostOrderBy.Top1h })
                                            }}>
                                                <div className="flex items-center">
                                                    <FontAwesomeIcon icon={faFire} className="text-white me-2" />
                                                    <p className="text-sm">Top 1h</p>
                                                </div>
                                                {alphaPostFilters.orderBy === PostOrderBy.Top1h ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}
                                            </Button>
                                        </div>
                                        <div className="flex w-full">
                                            <Button className={"bg-transparent flex items-center justify-between normal-case font-thin text-white w-full px-2 " + (alphaPostFilters.orderBy === PostOrderBy.Top24h ? "bg-neutral-950" : "")} onClick={() => {
                                                setAlphaPostFilters({ ...alphaPostFilters, orderBy: PostOrderBy.Top24h })
                                            }}>
                                                <div className="flex items-center">
                                                    <FontAwesomeIcon icon={faFire} className="text-primary-blue me-2" />
                                                    <p className="text-sm">Top 24h</p>
                                                </div>
                                                {alphaPostFilters.orderBy === PostOrderBy.Top24h ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}
                                            </Button>
                                        </div>
                                    </PopoverContent>
                                </Popover>
                                <Popover placement="bottom">
                                    <PopoverHandler>
                                        <Button className="bg-neutral-800 min-w-[150px] leading-[14px] flex items-center justify-center px-3">
                                            <img src={!searchedAssetClass ? alphaClubIcon : searchedAssetClass === AssetClass.Crypto ? cryptoIcon : friendTechIcon} className="token-icon me-2" />
                                            {searchedAssetClass ? searchedAssetClass.valueOf() : "General"}
                                        </Button>
                                    </PopoverHandler>
                                    <PopoverContent className="flex flex-col bg-neutral-900 outline-none !border !border-neutral-800 shadow-black p-2" style={{ border: "inherit" }}>
                                        <div className="flex w-full">
                                            <Button className={"bg-transparent flex items-center justify-between normal-case font-thin text-white w-full px-2 " + (!searchedAssetClass ? "bg-neutral-950" : "")} onClick={() => {
                                                history.push(`/big-board/general`)
                                            }}>
                                                <div className="flex items-center">
                                                    <img src={alphaClubIcon} className="token-icon me-2" />
                                                    <p className="text-sm">General</p>
                                                </div>
                                                {!searchedAssetClass ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}
                                            </Button>
                                        </div>
                                        <div className="flex w-full">
                                            <Button className={"bg-transparent flex items-center justify-between normal-case font-thin text-white w-full px-2 " + (searchedAssetClass === AssetClass.Crypto ? "bg-neutral-950" : "")} onClick={() => {
                                                history.push(`/big-board/${AssetClass.Crypto.valueOf()}`)
                                            }}>
                                                <div className="flex items-center">
                                                    <img src={cryptoIcon} className="token-icon me-2" />
                                                    <p className="text-sm">Crypto</p>
                                                </div>
                                                {searchedAssetClass === AssetClass.Crypto ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}
                                            </Button>
                                        </div>
                                        <div className="flex w-full">
                                            <Button className={"bg-transparent flex items-center justify-between normal-case font-thin text-white w-full px-2 " + (searchedAssetClass === AssetClass.FriendTech ? "bg-neutral-950" : "")} onClick={() => {
                                                history.push(`/big-board/${AssetClass.FriendTech.valueOf()}`)
                                            }}>
                                                <div className="flex items-center">
                                                    <img src={friendTechIcon} className="token-icon me-2" />
                                                    <p className="text-sm">Friend Tech</p>
                                                </div>
                                                {searchedAssetClass === AssetClass.FriendTech ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}
                                            </Button>
                                        </div>
                                    </PopoverContent>
                                </Popover>
                            </div>

                        </div>
                        <div ref={scrollContainer} className="flex flex-col no-scrollbar overflow-y-scroll gap-y-4 w-full">
                            <AlphaPostsWidget className="z-20" postsListFetch={postsListFetch} setPostsListFetch={setPostsListFetch} fetchAlphaPosts={fetchAlphaPosts} assetClass={alphaPostFilters.assetClass} searchedAlphaPostId={searchedAlphaPostId} />
                        </div>
                    </div>
                    <div id="big-board-news-feed-container" className="sticky top-0 hidden 2xl:flex flex-1 h-full flex-col gap-y-2 rounded-xl border-l px-4 border-l-[#29292C]">
                        <NewsFeedWidget className="" />
                        <div className="no-scrollbar flex-center flex h-1/3 max-w-sm flex-col gap-y-4"><h1 className="text-left text-5xl text-header header-gradient font-semibold text-white">Leaderboard</h1><div className="relative max-w-fit inline-flex items-center justify-between box-border px-1 h-7 text-small rounded-full bg-transparent border border-blue-200 text-blue-200"><span className="flex-1 text-inherit font-normal px-2">Coming Soon</span></div></div>
                    </div>
                </div>
                {/* <div id="big-board-filters-and-leaderboard-container" className="hidden xl:flex flex-col justify-between xl:w-[28%] py-[20px] pe-[3%] ps-[2%]">
                    <FiltersWidget className="hidden xl:flex" alphaPostFilters={alphaPostFilters} setAlphaPostFilters={setAlphaPostFilters} />
                    <LeaderboardWidget className="hidden xl:flex" />
                </div> */}

                <AlphaPostWriterModal assetClass={alphaPostFilters.assetClass} verifyIfPostMeetsFilters={verifyIfPostMeetsFilters} postsListFetch={postsListFetch} setPostsListFetch={setPostsListFetch} />
            </div>
        </div>
    )
};

export default BigBoard;