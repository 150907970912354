import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { Link } from 'react-router-dom';

import loadingIconAnim from "../../media/icons/loadingIcon.gif"
import { ReactComponent as TwitterXIcon } from "../../media/icons/twitterXIcon.svg"
import { ReactComponent as TelegramIcon } from "../../media/icons/telegramIcon.svg"
import TelegramLoginButton from 'react-telegram-login';

import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";

import { useUser } from "../Providers/useUser"

const ConnectionButton: FC<any> = (props) => {
    const { className, customHandler } = props;

    const { loggingIn, currentUser, setCurrentUser, login, logout } = useUser();

    const [showMenu, setShowMenu] = useState(false);

    async function connectTwitter() {
        setShowMenu(false);
        await login("twitter");
    }

    async function connectTelegram(telegramData: any) {
        setShowMenu(false);
        await login("telegram", new URLSearchParams(telegramData).toString());
    }

    function disconnect() {
        logout();
    }

    // return withViewAccountButton ? (
    //     <div className={`flex ${className ?? ""}`}>
    //         {currentUser !== null && <button className="btn-default-reversed view-account-btn p-0"><a className="text-black p-2" href={`${MAIN_WEBSITE_URL}/brokers-grove/account/info`}><FontAwesomeIcon icon={faUser} /></a></button>}
    //         <div className={`connection-button ${loggingIn ? "loading" : ""} ${buttonClassName ?? ""}`}>
    //             {
    //                 // loggingIn ? <span>Logging in...</span>
    //                 loggingIn ? <img src={loadingIconAnim} className="loading-icon-anim" />
    //                     : currentUser !== null ? <button className="disconnect-btn text-normal font-bold" onClick={() => disconnect()}>DISCONNECT</button>
    //                         : <button className="connect-btn text-normal font-bold" onClick={() => connect()}>CONNECT</button>
    //             }
    //         </div>
    //     </div>

    // ) : (
    //     <div className={`connection-button ${loggingIn ? "loading" : ""} ${className ?? ""}`}>
    //         {
    //             // loggingIn ? <span>Logging in...</span>
    //             loggingIn ? <img src={loadingIconAnim} className="loading-icon-anim" />
    //                 : currentUser !== null ? <button className="disconnect-btn text-normal font-bold" onClick={() => disconnect()}>DISCONNECT</button>
    //                     : <button className="connect-btn text-normal font-bold" onClick={() => connect()}>CONNECT</button>
    //         }
    //     </div>
    // )

    const menuContent = <><MenuHandler>
        <button onClick={() => !loggingIn && setShowMenu(!showMenu)} className={`connection-button min-w-[150px] flex items-center bg-transparent text-white border border-neutral-600 rounded-lg transition-all duration-300 hover:opacity-80 hover:text-black hover:bg-white ${loggingIn ? "loading opacity-80 text-black bg-white" : ""} ${className ?? ""} p-2`}>
            {
                loggingIn ? <img src={loadingIconAnim} className="loading-icon-anim w-[20px] h-auto m-auto" />
                    : currentUser !== null ? <div className="flex items-center justify-center text-normal text-sm md:text-base font-bold w-full px-2">{currentUser.userAuth.twitter ? <TwitterXIcon className="h-[15px] md:h-[20px] w-auto me-4" /> : <TelegramIcon className="h-[15px] md:h-[20px] w-auto me-4" />} {currentUser.userInfo.username}</div>
                        : <div className="text-normal text-sm md:text-base font-bold w-full">CONNECT</div>
            }
        </button>
    </MenuHandler>
        <MenuList className="bg-neutral-900 text-white border border-neutral-800 shadow-neutral-950 shadow-sm z-[1100]">
            {
                currentUser === null ?
                    <>
                        <MenuItem onClick={connectTwitter} className="bg-transparent rounded-full active:bg-unset focus:bg-unset hover:bg-neutral-950 active:text-white focus:text-white hover:text-white hover:scale-105 duration-300 px-4 my-2"><div className="flex items-center text-base w-full text-normal"><TwitterXIcon className="h-[20px] w-auto me-4" /> Log in with Twitter</div></MenuItem>
                        <MenuItem className="hover:bg-unset hover:text-white hover:scale-105 duration-300 p-0 my-2"><TelegramLoginButton botName="alpha_primordium_bot" dataOnauth={connectTelegram} /></MenuItem>
                    </>
                    :
                    <>
                        <MenuItem className="bg-transparent focus:bg-transparent hover:!bg-neutral-950 active:text-white focus:text-white hover:text-white my-2"><Link to={`/profile/${currentUser.userAuth.twitter ? "x" : "t"}/${currentUser.userInfo.username}`} className="flex justify-center items-center w-full text-normal font-bold">PROFILE</Link></MenuItem>
                        <MenuItem onClick={disconnect} className="bg-transparent focus:bg-transparent hover:!bg-neutral-950 active:text-white focus:text-white hover:text-white my-2"><div className="flex justify-center items-center w-full text-normal font-bold">DISCONNECT</div></MenuItem>
                    </>
            }
        </MenuList></>

    return ((customHandler ?? true) ?
        <Menu
            placement="bottom-start"
            dismiss={{
                itemPress: false,
            }}
            open={showMenu}
            handler={() => !loggingIn && setShowMenu(!showMenu)}
        >
            {menuContent}
        </Menu> : <Menu
            placement="bottom-start"
            dismiss={{
                itemPress: false,
            }}
        >
            {menuContent}
        </Menu>
    )

}

export default ConnectionButton;
