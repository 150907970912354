import { FC, useState } from "react";

import PopoverContainer, { PopoverTriggerType } from '@idui/react-popover'

const CopiableElement: FC<any> = (props) => {
    const { copy, className, component, popoverContent }: { copy: string, className: string, component: any, popoverContent?: { notClicked: string, clicked: string } } = props;

    const [clicked, setClicked] = useState(false)

    const copyElement = () => {
        setClicked(true)
        if (navigator.clipboard !== undefined) navigator.clipboard.writeText(copy);
        setTimeout(() => setClicked(false), 1000)
    }

    return (
        <PopoverContainer className="copiable-element-popover z-[9999] text-center px-4 py-0" content={clicked ? popoverContent?.clicked ?? "Copied" : popoverContent?.notClicked ?? "Copy"} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
            <span className={`copiable-element text-sm transition-all duration-200 hover:cursor-pointer hover:opacity-80 ${className}`} onClick={copyElement}>
                {component}
            </span>
        </PopoverContainer>
    )
}

export default CopiableElement;