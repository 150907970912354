import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "../../../Components/Providers/useUser";

import {
    Menu,
    MenuHandler,
    MenuList,
} from "@material-tailwind/react";


import alphaPostIcon from "../../../media/icons/newsIcon.png";
import technicalAnalysisPostIcon from "../../../media/icons/technicalAnalysisIcon.png";
import FriendTechIcon from "../../../media/icons/friendTechIcon.png";

import { useAlphaPostWriter } from "../../../Components/Providers/useAlphaPostWriter";

const CreateAlphaPostButton: FC<any> = (props) => {
    const { currentUser, setConnectionDialogOpen } = useUser();

    const history = useHistory();

    const [menuOpened, setMenuOpened] = useState(false);
    const { setIsWritingPost, newPostInfo, setNewPostInfo } = useAlphaPostWriter();

    useEffect(() => {
        if (!currentUser) setMenuOpened(false);
    }, [currentUser]);

    function startWriting() {
        setIsWritingPost(true)
        setMenuOpened(false)
    }

    return (
        <button className="flex w-full items-center justify-center space-x-2 rounded-full p-4 text-center text-sm hover:ring-1 focus:outline-none  bg-[#cbd7ff13] ring-white/25" type="button" onClick={startWriting}><span className="text-lg font-semibold text-white">Post</span></button>

        // <Menu
        //     placement="top-start"
        //     dismiss={{
        //         itemPress: false,
        //     }}
        //     open={menuOpened}
        //     handler={() => { console.log(currentUser); currentUser ? setMenuOpened(!menuOpened) : setConnectionDialogOpen(true) && setMenuOpened(false) }}
        // >
        //     <MenuHandler>
        //         <button className="flex w-full items-center justify-center space-x-2 rounded-full p-4 text-center text-sm hover:ring-1 focus:outline-none  bg-[#cbd7ff13] ring-white/25" type="button"><span className="text-lg font-semibold text-white">Post</span></button>

        //         {/* <button id="alpha-club-post-btn" className="btn-default flex justify-center items-center py-[0.2rem] min-w-[77px]"><FontAwesomeIcon icon={faPenToSquare} className="me-1" /> Post</button> */}
        //     </MenuHandler>
        //     <MenuList className="bg-neutral-900 px-2 py-1 border border-neutral-800 shadow-neutral-950 shadow-md z-[9999]">
        //         <div className="flex flex-row flex-wrap justify-center focus-visible:outline-none">
        //             <button className="flex items-center text-white hover:bg-neutral-950 py-2 px-3 rounded-md transition-colors duration-300 m-2"
        //                 onClick={() => {
        //                     const changedNewPostInfo = { ...newPostInfo };
        //                     changedNewPostInfo.newPostContent.postType = "alpha";
        //                     history.push(window.location.pathname.replace("friendTech", "crypto"));
        //                     setNewPostInfo(changedNewPostInfo);
        //                     setIsWritingPost(true)
        //                     setMenuOpened(false)
        //                 }}
        //             >
        //                 <img src={alphaPostIcon} className="w-auto h-[20px] me-2" />
        //                 <h5 className="text-normal">ALPHA</h5>
        //             </button>
        //             <button className="flex items-center text-white hover:bg-neutral-950 py-2 px-3 rounded-md transition-colors duration-300 m-2"
        //                 onClick={() => {
        //                     const changedNewPostInfo = { ...newPostInfo };
        //                     changedNewPostInfo.newPostContent.postType = "technicalAnalysis";
        //                     history.push(window.location.pathname.replace("friendTech", "crypto"));
        //                     setNewPostInfo(changedNewPostInfo);
        //                     setIsWritingPost(true)
        //                     setMenuOpened(false)
        //                 }}
        //             >
        //                 <img src={technicalAnalysisPostIcon} className="w-auto h-[20px] me-2" />
        //                 <h5 className="text-normal">T.A</h5>
        //             </button>
        //             <button className="flex items-center text-white hover:bg-neutral-950 py-2 px-3 rounded-md transition-colors duration-300 m-2"
        //                 onClick={() => {
        //                     const changedNewPostInfo = { ...newPostInfo };
        //                     changedNewPostInfo.newPostContent.postType = "friendTech";
        //                     history.push(window.location.pathname.replace("crypto", "friendTech"));
        //                     setNewPostInfo(changedNewPostInfo);
        //                     setIsWritingPost(true)
        //                     setMenuOpened(false)
        //                 }}
        //             >
        //                 <img src={FriendTechIcon} className="w-auto h-[20px] me-2" />
        //                 <h5 className="text-normal">FT</h5>
        //             </button>
        //         </div>
        //     </MenuList>
        // </Menu>
    )
};

export default CreateAlphaPostButton;