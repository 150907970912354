import { useEffect, useState } from "react";

import mixpanel from "mixpanel-browser";

import { firebaseAuth, firestoreDB } from "../../utils/firebaseApp"
import { signInWithPopup, TwitterAuthProvider, signOut, signInWithCustomToken } from "firebase/auth";

import api from "../../utils/api/api";

import { UserAccount, ConfidentialUserAccount, DEFAULT_CONFIDENTIAL_ACCOUNT, UserOnchainStats } from "../../utils/types";
import { UserContext } from "./useUser"
import { doc, getDoc } from "firebase/firestore";
import { FIRESTORE_COLLECTION_NAMES } from "../../utils/constants";

const provider = new TwitterAuthProvider();

export function UserProvider(
    { children }: any
) {
    const [loggingIn, setLoggingIn] = useState(false)
    const [connectionDialogOpen, setConnectionDialogOpen] = useState(false)
    const [currentUser, setCurrentUser] = useState<(UserAccount & { confidential: ConfidentialUserAccount, onchainStats: UserOnchainStats }) | null>(null)

    useEffect(() => {
        const unsub = firebaseAuth.onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                console.log("SIGNED IN")
                setSignedInUser();
            } else {
                // No user is signed in.
                console.log("NOT SIGNED IN")
                setCurrentUser(null)
            }
        });
        return () => unsub();
    }, [loggingIn, currentUser])

    useEffect(() => {
        console.log("Current user: " + currentUser)
    }, [currentUser])

    useEffect(() => {
        console.log("Logging in: " + loggingIn)
    }, [loggingIn])

    const login = async (platform: "twitter" | "telegram" | "telegramWebApp" = "twitter", data: any = undefined) => {
        if (loggingIn) return false;

        setLoggingIn(true)
        setCurrentUser(null)

        if (platform === "twitter") return await loginWithTwitter();
        else if (platform === "telegram" || platform === "telegramWebApp") return await loginWithTelegram(data, platform === "telegramWebApp");
    }

    const loginWithTwitter = async () => {
        return await signInWithPopup(firebaseAuth, provider)
            .then(async (result) => {
                // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                // You can use these server side with your app's credentials to access the Twitter API.
                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                const secret = credential?.secret;
                if (token === undefined || secret === undefined) {
                    setLoggingIn(false)
                    return false;
                }

                const loginResponse = await api.auth.twitter.completeTwitterConnect(token, secret)

                if (loginResponse === null || !loginResponse.success) return false;
                console.log(loginResponse.userAccount)
                const userConfidentialDoc = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.users, loginResponse.userAccount.userId, "confidential", "confidential"));
                setCurrentUser({ ...loginResponse.userAccount, confidential: (userConfidentialDoc.data() as ConfidentialUserAccount | undefined ?? DEFAULT_CONFIDENTIAL_ACCOUNT), onchainStats: await getUserOnchainStats() });
                setLoggingIn(false)
                mixpanel.track("Login", {
                    platform: "Twitter"
                })
                return true;
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);

                setLoggingIn(false)
                return false;
            });
    }

    const loginWithTelegram = async (telegramInitData: string, fromWebApp: boolean) => {
        if (telegramInitData === undefined) return false;

        const loginResponse = await api.auth.telegram.connectWithTelegram(telegramInitData, fromWebApp);
        setLoggingIn(false)

        if (loginResponse === null || !loginResponse.success) {
            return false;
        }
        // setCurrentUser(loginResponse.userAccount);
        const userCredentials = await signInWithCustomToken(firebaseAuth, loginResponse.token);
        const userConfidentialDoc = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.users, loginResponse.userAccount.userId, "confidential", "confidential"));
        setCurrentUser({ ...loginResponse.userAccount, confidential: (userConfidentialDoc.data() as ConfidentialUserAccount | undefined ?? DEFAULT_CONFIDENTIAL_ACCOUNT), onchainStats: await getUserOnchainStats() });
        mixpanel.track("Login", {
            platform: "Telegram"
        })
        return true;
    }

    async function setSignedInUser() {
        if (firebaseAuth.currentUser === null) return setCurrentUser(null);
        if (currentUser?.userId === firebaseAuth.currentUser.uid || loggingIn) return;
        console.log("Setting signed in user");
        setLoggingIn(true);
        try {
            const userDoc = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.users, firebaseAuth.currentUser.uid));
            const userConfidentialDoc = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.users, firebaseAuth.currentUser.uid, "confidential", "confidential"));
            userDoc.exists() ? setCurrentUser({ ...userDoc.data() as UserAccount, confidential: (userConfidentialDoc.data() as ConfidentialUserAccount | undefined ?? DEFAULT_CONFIDENTIAL_ACCOUNT), onchainStats: await getUserOnchainStats() }) : setCurrentUser(null);
        } catch (error) {
            console.error(error);
            signOut(firebaseAuth);
        }
        setLoggingIn(false);
    }

    async function getUserOnchainStats(userWalletAddress = "0xtest") {
        const placeholder: UserOnchainStats = {
            wallet: {
                address: userWalletAddress,
                balance: 3.45,
            },
            shares: {
                clubs: {
                    "c4b7f307-d0ff-43f6-8471-8904809c79e6": 3,
                    "f71ad2f3-f055-4fba-8fbf-669d3a12e99f": 17,
                    "53b76a7b-870e-4ea3-bb41-09960ca3ac8f": 1,
                    "59f03502-cf55-4af8-88db-69fab9d385f1": 1,
                },
            }
        }
        return placeholder;
    }

    const logout = () => {
        signOut(firebaseAuth);
        mixpanel.track("Logout")
    }

    return (
        <UserContext.Provider
            value={{
                loggingIn,
                currentUser,
                setCurrentUser,
                login,
                logout,
                connectionDialogOpen,
                setConnectionDialogOpen,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}