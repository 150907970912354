import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import mixpanel from 'mixpanel-browser';

import { ThemeProvider } from "@material-tailwind/react";

mixpanel.init('de2175f1e36e2334e6cf01219d43adac', { debug: true });
mixpanel.track("Enter Website")
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

const theme = {
  input: {
    defaultProps: {
      color: "white",
    },
    styles: {
      base: {
        label: {
          color: "peer-placeholder-shown:text-white",
        }
      }
    }
  }
}

root.render(
  <Router>
    <ThemeProvider value={theme}>
      <App />
    </ThemeProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
