import axios from "axios";

import { AlphaPostInfo, PostsQueryOrder, PostsQueryField, AssetClass, AlphaPostComment } from "../../types";

import { API_URL } from "../api"
import { firebaseAuth } from "../../firebaseApp";

async function createPost(
    alphaPostContent: AlphaPostInfo["content"],
    imageFiles: File[],
    alphaPostIsToBeAnalyzed: boolean,
): Promise<{ success: boolean, alphaPostInfo: AlphaPostInfo | null, error: string | null }> {
    const formData = new FormData();
    imageFiles.forEach(imageFile => formData.append("images", imageFile));
    formData.append("alphaPostContent", JSON.stringify(alphaPostContent));
    formData.append("alphaPostIsToBeAnalyzed", JSON.stringify(alphaPostIsToBeAnalyzed));

    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return { success: false, alphaPostInfo: null, error: "User not connected." };

    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + firebaseAuthToken,
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/alpha-club/big-board/posts/create`, formData, customConfig);
    } catch (error) {
        console.error(error);
        return { success: false, alphaPostInfo: null, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, alphaPostInfo: null, error: responseData.error };
    }

    return { success: true, alphaPostInfo: responseData.alphaPostInfo, error: null };
}

// async function getPostsInfo(
//     alphaPostId: string[]
// ): Promise<{ success: boolean, alphaPosts: AlphaPostInfo[] }> {
//     const params = {
//         alphaPostId,
//     }
//     let response;
//     try {
//         response = await axios.get(`${API_URL}/alpha-club/big-board/posts`, { params });
//     } catch (error) {
//         console.error(error);
//         return { success: false, alphaPosts: [] };
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return { success: false, alphaPosts: [] };
//     }

//     return responseData;
// }


async function interactWithPost(
    interactionType: string,
    alphaPostId: string,
    isRemoveInteraction: boolean
): Promise<boolean> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return false;
    const headers = {
        "Authorization": "Bearer " + firebaseAuthToken,
    }

    let response;
    try {
        if (isRemoveInteraction) response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/interact/${interactionType}?alphaPostId=${alphaPostId}`, { headers });
        else response = await axios.get(`${API_URL}/alpha-club/big-board/posts/interact/${interactionType}?alphaPostId=${alphaPostId}`, { headers });
    } catch (error) {
        // console.error(error);
        return false;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData.success;
}

// async function getRecentPosts(
//     queryOrder: PostsQueryOrder | undefined,
//     queryOrderField: PostsQueryField | undefined,
//     // timeRangeStartAfter: number | null,
//     // timeRangeStartBefore: number | null,
//     queryLength: number,

//     createdBefore: number | undefined,
//     createdAfter: number | undefined,

//     filterAssetIds: string[] | undefined,
//     filterAssetClass: AssetClass,
//     filterAuthorsIds: string[] | undefined,
//     // filterEliteOnly: boolean,
// ): Promise<{ success: boolean, alphaPosts: AlphaPostInfo[] }> {
//     console.log(filterAssetIds)
//     const params: any = {
//         queryOrder,
//         queryOrderField,
//         queryLength,

//         createdBefore,
//         createdAfter,

//         filterAssetIds,
//         filterAssetClass: filterAssetClass.valueOf(),
//         filterAuthorsIds
//     }
//     // if (filterEliteOnly) params.filterEliteOnly = true;
//     let response;
//     try {
//         response = await axios.get(`${API_URL}/alpha-club/big-board/posts/recent`, { params });
//     } catch (error) {
//         console.error(error);
//         return { success: false, alphaPosts: [] };
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return { success: false, alphaPosts: [] };
//     }

//     return { success: true, alphaPosts: responseData.alphaPosts };
// }

async function deletePost(
    alphaPostId: string,
): Promise<boolean> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return false;

    const customConfig: any = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + firebaseAuthToken,
        },
        params: {
            alphaPostId,
        }
    };

    let response;
    try {
        response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/delete`, customConfig);
    } catch (error) {
        console.error(error);
        return false;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData.success;
}


async function createComment(
    alphaPostId: string,
    commentContent: AlphaPostComment["content"],
    imageFiles: File[],
    replyCommentId?: string
): Promise<{ success: boolean, alphaPostComment?: AlphaPostComment, error?: string }> {
    const formData = new FormData();
    imageFiles.forEach(imageFile => formData.append("images", imageFile));
    formData.append("alphaPostId", alphaPostId);
    formData.append("commentContent", JSON.stringify(commentContent));
    if (replyCommentId) formData.append("replyCommentId", replyCommentId);

    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return { success: false, error: "User not connected." };

    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + firebaseAuthToken,
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/alpha-club/big-board/posts/comments/create`, formData, customConfig);
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, error: responseData.error };
    }

    return { success: true, alphaPostComment: responseData.alphaPostComment };
}

// async function getCommentInfo(
//     originAlphaPostId: string,
//     commentId: string
// ) {
//     const params = {
//         originAlphaPostId,
//         commentId,
//     }
//     let response;
//     try {
//         response = await axios.get(`${API_URL}/alpha-club/big-board/posts/comments`, { params });
//     } catch (error) {
//         console.error(error);
//         return { success: false, error };
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//     }

//     return responseData;
// }

async function deleteComment(
    alphaPostId: string,
    commentId: string,
): Promise<{ success: boolean, error?: string }> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return { success: false, error: "User not connected." };
    const headers = {
        "Authorization": "Bearer " + firebaseAuthToken,
    }

    let response;
    try {
        const params = {
            alphaPostId,
            commentId,
        }
        response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/comments`, { params, headers });
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, error: responseData.error };
    }

    return { success: true };
}


async function savePost(
    postId: string,
    postTypeUserAlphaInfo: string
): Promise<{ success: boolean, error: string | undefined }> {
    const data = JSON.stringify({ postId, postTypeUserAlphaInfo });
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return { success: false, error: "User not connected." };
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + firebaseAuthToken,
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/alpha-club/big-board/posts/save`, data, customConfig);
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData;
}

async function removeSavedPost(
    postId: string,
    postTypeUserAlphaInfo: string
): Promise<{ success: boolean, error: string | undefined }> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return { success: false, error: "User not connected." };
    const headers = {
        "Authorization": "Bearer " + firebaseAuthToken,
    }

    const params = {
        postId,
        postTypeUserAlphaInfo
    };

    let response;
    try {
        response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/save`, { params, headers });
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData;
}

export default {
    createPost,
    // getPostsInfo,
    interactWithPost,
    // getRecentPosts,
    deletePost,
    createComment,
    // getCommentInfo,
    deleteComment,
    savePost,
    removeSavedPost,
}