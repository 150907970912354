import { QueryCompositeFilterConstraint, QueryFieldFilterConstraint, QueryLimitConstraint, QueryOrderByConstraint, and, collection, doc, documentId, getDoc, getDocs, limit, or, orderBy, query, where } from "firebase/firestore";
import { FIRESTORE_COLLECTION_NAMES } from "./constants";
import { firestoreDB } from "./firebaseApp";
import { AlphaPostInfo, AssetClass, CreatorClub, IAlphaClubMarketNews, PostsQueryField, PostsQueryOrder, UserAccount } from "./types";
import { PostOrderBy } from "../Pages/AlphaClub/types";
import { getFormattedUnixDate } from "./utils";


async function getSpecificPostsInfo(
    alphaPostIds: string[]
): Promise<{ success: boolean, alphaPosts: AlphaPostInfo[], error?: string }> {
    const MAX_POSTS_PER_REQUEST = 5;

    const alphaPosts: any[] = [];
    try {
        const alphaPostsDocsSnapshotQuery = query(collection(firestoreDB, FIRESTORE_COLLECTION_NAMES.alphaClub.main, "alphaPosts", FIRESTORE_COLLECTION_NAMES.alphaClub.posts), where(documentId(), "in", alphaPostIds.slice(0, MAX_POSTS_PER_REQUEST)), limit(MAX_POSTS_PER_REQUEST));
        const alphaPostsDocsSnapshot = await getDocs(alphaPostsDocsSnapshotQuery);
        alphaPostsDocsSnapshot.docs.forEach((doc) => alphaPosts.push(doc.data() as AlphaPostInfo));
    } catch (err: any) {
        console.error(err);
        return { success: false, error: err, alphaPosts: [] };
    }

    return { success: true, alphaPosts };
}

const RECENT_ALPHA_POSTS_QUERY_MAX_LENGTH = 10;
async function getRecentPosts(
    postOrderBy: PostOrderBy | null,
    // queryOrderEnum: PostsQueryOrder | undefined,
    // queryOrderFieldEnum: PostsQueryField | undefined,
    queryLength: number,

    createdBefore: number | undefined,
    createdAfter: number | undefined,

    filterAssetIds: string[] | undefined,
    filterAssetClassEnum: AssetClass | null,
    filterAuthorsIds: string[] | undefined,
): Promise<{ success: boolean, alphaPosts: AlphaPostInfo[], error?: string }> {
    console.log(filterAssetIds)
    // let queryOrder: string = queryOrderEnum?.valueOf() ?? "desc";
    // let queryOrderField: string = queryOrderFieldEnum?.valueOf() ?? "creation.timestamp";
    queryLength = Number(queryLength) ?? RECENT_ALPHA_POSTS_QUERY_MAX_LENGTH;

    createdBefore = createdBefore === undefined ? postOrderBy === PostOrderBy.Recent ? Date.now() : 1000000000000 : createdBefore;
    createdAfter = createdBefore === undefined ? postOrderBy === PostOrderBy.Recent ? 0 : -1000000000000 : createdAfter;

    if (!Array.isArray(filterAssetIds) || filterAssetIds.length === 0) filterAssetIds = undefined;
    if (!Array.isArray(filterAuthorsIds) || filterAuthorsIds.length === 0) filterAuthorsIds = undefined;

    const alphaPosts: AlphaPostInfo[] = [];
    try {
        const queryConstraints: (QueryFieldFilterConstraint | QueryCompositeFilterConstraint)[] = [];
        if (filterAssetClassEnum === null) queryConstraints.push(where("content.prediction", "==", null));
        else {
            let filterAssetClass: string = filterAssetClassEnum.valueOf();
            if (filterAssetClass !== undefined) queryConstraints.push(where("content.prediction.asset.assetClass", "==", filterAssetClass));
        }
        if (filterAssetIds !== undefined) queryConstraints.push(where("content.prediction.asset.assetId", "in", filterAssetIds));
        if (filterAuthorsIds !== undefined) queryConstraints.push(where("creation.user.userId", "in", filterAuthorsIds));

        const orderByQueryConstraint: QueryOrderByConstraint = (postOrderBy === PostOrderBy.Recent || postOrderBy === null) ? orderBy("creation.timestamp", "desc") :
            orderBy("interactions.totalVoteScore", "desc");

        const currentFormattedUnixDate = getFormattedUnixDate(new Date());
        const oneDayAgoFormattedUnixDate = getFormattedUnixDate(new Date(Date.now() - (24 * 60 * 60 * 1000)));
        console.log(currentFormattedUnixDate.formattedHour, Number(currentFormattedUnixDate.formattedHour) === 1 ? "24" : (Number(currentFormattedUnixDate.formattedHour) - 1).toString())
        if (postOrderBy === PostOrderBy.Recent || postOrderBy === null) queryConstraints.push(where("creation.timestamp", "<", Number(createdBefore)), where("creation.timestamp", ">", Number(createdAfter)));
        else if (postOrderBy === PostOrderBy.Top1h) queryConstraints.push((
            where("interactions.totalVoteScore", "<", Number(createdBefore)),
            where("interactions.totalVoteScore", ">", Number(createdAfter)),
            where("creation.timestampDate", "==", currentFormattedUnixDate.formattedDate),
            or(
                where("creation.timestampHour", "==", currentFormattedUnixDate.formattedHour),
                where("creation.timestampHour", "==", Number(currentFormattedUnixDate.formattedHour) === 1 ? "24" : (Number(currentFormattedUnixDate.formattedHour) - 1).toString()),
            )
        ));
        else if (postOrderBy === PostOrderBy.Top24h) queryConstraints.push((
            where("interactions.totalVoteScore", "<", Number(createdBefore)),
            where("interactions.totalVoteScore", ">", Number(createdAfter)),
            or(
                where("creation.timestampDate", "==", currentFormattedUnixDate.formattedDate),
                where("creation.timestampDate", "==", oneDayAgoFormattedUnixDate.formattedDate),
            )
        ));

        const docsQuery = query(collection(firestoreDB, FIRESTORE_COLLECTION_NAMES.alphaClub.main, "alphaPosts", FIRESTORE_COLLECTION_NAMES.alphaClub.posts), and(...queryConstraints), orderByQueryConstraint, limit(Math.min(queryLength, RECENT_ALPHA_POSTS_QUERY_MAX_LENGTH)));
        const docsSnapshot = await getDocs(docsQuery);
        docsSnapshot.docs.forEach((doc) => alphaPosts.push(doc.data() as AlphaPostInfo));
    } catch (error: any) {
        console.error(error);
        return { success: false, error, alphaPosts: [] };
    }

    return { success: true, alphaPosts };
}


async function getCommentInfo(
    commentId: string
): Promise<{ success: boolean, alphaPostComment?: any, error?: string }> {
    let alphaPostComment;
    try {
        const alphaPostCommentSnapshot = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.alphaClub.main, "alphaPosts", FIRESTORE_COLLECTION_NAMES.alphaClub.postsComments, commentId));
        alphaPostComment = alphaPostCommentSnapshot.data();
        if (!alphaPostCommentSnapshot.exists || alphaPostComment === undefined) return { success: false, error: "Board post comment not found." };
    } catch (error: any) {
        console.error(error);
        return { success: false, error };
    }

    return { success: true, alphaPostComment };
}


const MARKET_NEWS_QUERY_MAX_LENGTH = 200;
async function getMarketNews(
    queryLength?: number,
    createdBefore?: number,
): Promise<{ success: boolean, marketNews?: IAlphaClubMarketNews[], error?: string }> {
    queryLength = Number(queryLength ?? MARKET_NEWS_QUERY_MAX_LENGTH);
    createdBefore = Number(createdBefore ?? Date.now());

    const marketNews: IAlphaClubMarketNews[] = [];
    try {
        const marketNewsDocSnapshotQuery = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.alphaClub.main, FIRESTORE_COLLECTION_NAMES.alphaClub.news));
        const marketNewsDocData = marketNewsDocSnapshotQuery.data();
        if (marketNewsDocData === undefined) throw new Error("No market news data found.");
        marketNewsDocData.recentNewsTimeline.reverse();
        marketNewsDocData.recentNewsTimeline.forEach((alphaClubMarketNews: IAlphaClubMarketNews) => {
            if (marketNews.length < queryLength! && alphaClubMarketNews.timestamp < createdBefore!) marketNews.push(alphaClubMarketNews);
        });
    } catch (err: any) {
        console.error(err);
        return { success: false, error: err };
    }

    return { success: true, marketNews };
}

const USERS_QUERY_MAX_LENGTH = 10;
async function searchUserInfoByPlatform(
    queriedUsername: string,
    queriedAuthPlatform: string,
): Promise<UserAccount | null> {
    let userAccount: UserAccount | UserAccount[] | null;
    try {
        const userAccountSnapshot = await getDocs(query(collection(firestoreDB, FIRESTORE_COLLECTION_NAMES.users), where(queriedAuthPlatform !== "alphaClub" ? `userAuth.${queriedAuthPlatform}.name` : "userId", "==", queriedUsername), limit(1)));
        userAccount = userAccountSnapshot.empty ? null : userAccountSnapshot.docs[0].data() as UserAccount;
    } catch (err) {
        console.error(err);
        return null;
    }

    return userAccount;
}
async function searchUserInfoByIds(
    queriedUserIds: string | string[],
): Promise<UserAccount[] | null> {
    if (queriedUserIds !== undefined && (!(queriedUserIds instanceof Array) && typeof queriedUserIds !== "string")) return null;
    // if (queriedBrokerIds instanceof Array && queriedBrokerIds.length > MAX_QUERY_SIZE_IN_ARRAY) return res.json({ success: false, error: `Can only read a max of ${MAX_QUERY_SIZE_IN_ARRAY} accounts at once.` });

    let userAccount: UserAccount | UserAccount[] | null;
    try {
        userAccount = [];
        if (!(queriedUserIds instanceof Array)) queriedUserIds = [queriedUserIds!];
        const userAccountDocsSnapshotQuery = await getDocs(query(collection(firestoreDB, FIRESTORE_COLLECTION_NAMES.users), where(documentId(), "in", queriedUserIds.slice(0, USERS_QUERY_MAX_LENGTH)), limit(USERS_QUERY_MAX_LENGTH)));
        userAccountDocsSnapshotQuery.docs.forEach((userAccountDoc) => (userAccount as UserAccount[]).push(userAccountDoc.data() as UserAccount));
    } catch (err) {
        console.error(err);
        return null;
    }

    return userAccount;
}

const CREATOR_CLUBS_QUERY_MAX_LENGTH = 10;
async function getUserCreatedClubs(
    userId: string
) {
    const creatorClubs: CreatorClub[] = [];
    try {
        const creatorClubsSnapshot = await getDocs(query(collection(firestoreDB, FIRESTORE_COLLECTION_NAMES.creatorClubs), where("creation.creatorId", "==", userId), limit(CREATOR_CLUBS_QUERY_MAX_LENGTH)));
        creatorClubsSnapshot.docs.forEach((doc) => creatorClubs.push(doc.data() as CreatorClub));
    } catch (error: any) {
        console.error(error);
        return { success: false, error };
    }

    return { success: true, creatorClubs };
}

async function getCreatorClubInfo(
    creatorClubId: string
) {
    let creatorClub: CreatorClub | null;
    try {
        const creatorClubSnapshot = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.creatorClubs, creatorClubId));
        creatorClub = creatorClubSnapshot.data() as CreatorClub | undefined ?? null;
    } catch (error: any) {
        console.error(error);
        return null;
    }

    return creatorClub;
}

export default {
    alphaPosts: {
        getSpecificPostsInfo,
        getRecentPosts,
        getCommentInfo,
    },
    marketNews: {
        getMarketNews,
    },
    users: {
        searchUserInfoByPlatform,
        searchUserInfoByIds,
    },
    creatorClub: {
        getUserCreatedClubs,
        getCreatorClubInfo,
    }
}