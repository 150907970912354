import { TokenInfo, CoinInfo, NFTInfo, AssetClass, TokenSecurityMetrics, TokenPoolStats, AlphaPostInfo, FtShareInfo } from "../../utils/types"

export interface IAlphaPostFilters {
    assets: (TokenInfo | CoinInfo | NFTInfo | FtShareInfo)[] | null;
    authors: string[] | null;
    assetClass: AssetClass | null; // null => general posts
    search: string | null;
    orderBy: PostOrderBy;
    loadingFilters: boolean;
}

export interface PostsListFetch {
    fetching: boolean;
    alphaPosts: AlphaPostInfo[];
    fetchError: boolean;
    allFetched: boolean;
    fetch: Function;
}

export interface SelectedPostFetch {
    fetching: boolean;
    fetchError: boolean;
    deleting: boolean;
    alphaPost: AlphaPostInfo | null;
}

export enum PostOrderBy {
    Recent = "recent",
    Top1h = "top1h",
    Top24h = "top24h",
}
// export enum PostsQueryOrder {
//     ASCENDING = "asc",
//     DESCENDING = "desc",
// }
// export enum PostsQueryField {
//     CreationTime = "creation.timestamp",
//     TotalVotesScore = "interactions.totalVoteScore"
// }

export interface PostTokenSecurityMetricsFetch {
    tokenSecurityMetrics: TokenSecurityMetrics | null,
    fetching: boolean,
    drawerOpen: boolean,
    drawerVisible: boolean,
}

export interface PostTokenPoolStatsFetch {
    tokenPoolStats: TokenPoolStats | null,
    fetching: boolean,
}