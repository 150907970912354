import { FC, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import queryString from "query-string";

import { ToastContainer } from 'react-toastify';

import BigBoard from "./Pages/AlphaClub/BigBoard"
import UserProfile from "./Pages/Profile/UserProfile";
import Navbar from "./Components/Navbar"
import ConnectionDialog from "./Components/Connection/ConnectionDialog";
import TelegramConnectionLoader from "./Components/TelegramConnectionLoader"
import { AssetClass } from "./utils/types";

import { useMediaQuery } from "react-responsive";

const MainHandler: FC<any> = (props) => {

    const history = useHistory();
    const location = useLocation();
    const [locationPaths, setLocationPaths] = useState<string[]>([])

    const [telegramDataToValidate, setTelegramDataToValidate] = useState<string>();
    const [isTelegramWebApp, setIsTelegramWebApp] = useState<boolean>(false);

    useEffect(() => {
        const telegramWebApp = (window as any).Telegram?.WebApp;
        setIsTelegramWebApp(telegramWebApp && telegramWebApp.platform !== "unknown")
    }, [])

    useEffect(() => {
        console.log(location.pathname)

        // location.pathname.replace(/https:\/\/.+\//, "")
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        console.log(paths)
        setLocationPaths(paths)

        if (paths[0] === "telegram") handleTelegramOrigin();
    }, [location])

    function handleTelegramOrigin() {
        const telegramWebApp = (window as any).Telegram?.WebApp;
        // telegramWebApp.expand();
        if (telegramWebApp && telegramWebApp.initData.length > 0) {
            setTelegramDataToValidate(telegramWebApp.initData);
        } else {
            const query = queryString.parse(location.search);
            if (query.username && query.id && query.hash) {
                const telegramInitDataQuery = {
                    id: query.id,
                    first_name: query.first_name,
                    auth_date: query.auth_date,
                    hash: query.hash
                }
                if (query.username) (telegramInitDataQuery as any).username = query.username;
                if (query.photo_url) (telegramInitDataQuery as any).photo_url = query.photo_url;
                if (query.last_name) (telegramInitDataQuery as any).last_name = query.last_name;
                setTelegramDataToValidate(new URLSearchParams(telegramInitDataQuery as any).toString());
            } else {
                history.push(`/${locationPaths.filter((locationPath: string) => locationPath !== "telegram").join("/")}`);
            }
        }
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1140px)' })

    return (
        <div id="main-handler" className="flex w-full h-screen flex-col xl:grid xl:grid-cols-12 xl:grid-rows-1">
            <Navbar isTelegramWebApp={isTelegramWebApp} />
            <div id="app-content" className="relative col-span-12 flex flex-1 flex-col xl:col-span-10" style={{ maxHeight: isTabletOrMobile ? "calc(100vh - 81px)" : "100vh", height: "100vh" }}>
                <Switch>
                    <Redirect exact from='/' to='/big-board/crypto' />
                    <Redirect exact from='/big-board' to='/big-board/crypto' />
                    {locationPaths[0] === "big-board" && <Route path="/big-board" children={<BigBoard isTelegramWebApp={isTelegramWebApp} searchedAssetClass={locationPaths.length > 1 ? locationPaths[1] === "crypto" ? AssetClass.Crypto : locationPaths[1] === "friendTech" ? AssetClass.FriendTech : locationPaths[1] === "nft" ? AssetClass.NFT : null : undefined} searchedAlphaPostId={locationPaths[2] !== "create" ? locationPaths[2] : undefined} createNewPostIntent={locationPaths[2] === "create"} />}></Route>}
                    <Route path="/telegram" children={<TelegramConnectionLoader telegramDataToValidate={telegramDataToValidate} locationPaths={locationPaths} />}></Route>
                    {locationPaths[0] === "profile" && <Route path="/profile" children={<UserProfile locationPaths={locationPaths} />}></Route>}
                </Switch>
            </div>

            <ConnectionDialog />

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
        // <div id="main-handler" className="flex flex-col">
        //     <Navbar isTelegramWebApp={isTelegramWebApp} />
        //     <div id="app-content" className="flex flex-col">
        //         <Switch>
        //             <Redirect exact from='/' to='/big-board/crypto' />
        //             <Redirect exact from='/big-board' to='/big-board/crypto' />
        //             {locationPaths[0] === "big-board" && <Route path="/big-board" children={<BigBoard isTelegramWebApp={isTelegramWebApp} searchedAssetClass={locationPaths.length > 1 ? locationPaths[1] === "crypto" ? AssetClass.Crypto : AssetClass.NFT : undefined} searchedAlphaPostId={locationPaths[2] !== "create" ? locationPaths[2] : undefined} createNewPostIntent={locationPaths[2] === "create"} />}></Route>}
        //             <Route path="/telegram" children={<TelegramConnectionLoader telegramDataToValidate={telegramDataToValidate} locationPaths={locationPaths} />}></Route>
        //             <Route path="/profile" children={<UserProfile locationPaths={locationPaths} />}></Route>
        //         </Switch>
        //     </div>

        //     <ConnectionDialog />


        //     <ToastContainer
        //         position="bottom-center"
        //         autoClose={5000}
        //         hideProgressBar={false}
        //         newestOnTop={false}
        //         closeOnClick
        //         rtl={false}
        //         pauseOnFocusLoss
        //         draggable
        //         pauseOnHover
        //         theme="light"
        //     />
        // </div>
    )
}

export default MainHandler