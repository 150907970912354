import "./AlphaPostPreview.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import gsap from "gsap";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import createDOMPurify from "dompurify";
import { AdvancedChart } from "react-tradingview-embed";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faArrowTrendDown, faClock, faLink, faArrowUpFromBracket, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faRegularBookmark } from '@fortawesome/free-regular-svg-icons'
import { faTwitter } from "@fortawesome/free-brands-svg-icons"


// import EthIcon from "../../../media/icons/ethereumIcon.png";
import { ReactComponent as CaretUp } from "../../../media/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../../../media/icons/CaretDown.svg";
import { ReactComponent as ShareIcon } from "../../../media/icons/shareIcon.svg";
import { ReactComponent as OutlineBookmarkIcon } from "../../../media/icons/outlineBookmarkIcon.svg";
import { ReactComponent as BookmarkIcon } from "../../../media/icons/bookmarkIcon.svg";
import { ReactComponent as EthIcon } from "../../../media/icons/ethereumIcon.svg";
import loadingIconAnim from "../../../media/icons/loadingIcon.gif"
import friendTechIcon from "../../../media/icons/friendTechIcon.png";

import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Menu,
    MenuHandler,
    MenuList,
} from "@material-tailwind/react";

import CopiableElement from "../../../Components/CopiableElement";

import { useUser } from "../../../Components/Providers/useUser"
import { PostTokenPoolStatsFetch, PostTokenSecurityMetricsFetch } from "../types";
import { AssetClass, AlphaPostInfo, NFTInfo, PostInteraction, TokenInfo } from "../../../utils/types";
import { getFullBaseUrl, getScrollBarWidth, numberWithCommas, getRoundedPrice, timestampToSmallDate, timestampToRelative, randomGradientImg, formatNumberForPrice, delay, getSharePostOnTwitterLink } from "../../../utils/utils";
import api from "../../../utils/api/api";
import TokenSecurityMetricsDrawer from "./TokenSecurityMetricsDrawer";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import AlphaPostChat from "./AlphaPostChat";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ALPHA_TIME_RANGE_ICONS } from "../../../utils/constants";


const purify = createDOMPurify(window);

const AlphaPostPreview: FC<any> = (props) => {
    const { postPreviewOpen, setPostPreviewOpen, closePostPreview, post, removePostFromPostsList, interactWithPost, onPostClick, toggleSavePost }: { postPreviewOpen: boolean, setPostPreviewOpen: Function, closePostPreview: Function, post: AlphaPostInfo, removePostFromPostsList: Function, interactWithPost: any, onPostClick: Function, toggleSavePost: Function } = props;

    const history = useHistory();
    const location = useLocation();
    const { currentUser } = useUser();
    const sharePostButtonRef = useRef<HTMLButtonElement>(null);

    const [isPostOpen, setIsPostOpen] = useState(false);
    const [deletingPost, setDeletingPost] = useState(false);
    const [selectedPostTimeLeftToDelete, setSelectedPostTimeLeftToDelete] = useState<number>(1);
    const [postTokenSecurityMetricsFetch, setPostTokenSecurityMetricsFetch] = useState<PostTokenSecurityMetricsFetch>({ tokenSecurityMetrics: null, fetching: false, drawerOpen: false, drawerVisible: false })
    const [postTokenPoolStatsFetch, setPostTokenPoolStatsFetch] = useState<PostTokenPoolStatsFetch>({ tokenPoolStats: null, fetching: false });

    const alphaPostPreviewContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // if (searchedAlphaPostId !== undefined || assetClass === undefined) return;
        if (!isPostOpen) return;
        history.push(`${location.pathname.replace(`/${post.id}`, "")}/${post.id}`);
    }, [isPostOpen])

    const togglePostTimeline = useRef<gsap.core.Timeline | null>(null);
    useEffect(() => {
        setupTogglePostTimeline()
    }, [])

    async function setupTogglePostTimeline() {
        const scrollbarWidth = getScrollBarWidth();

        const openPostTimeline = gsap.timeline({ paused: true });
        openPostTimeline.timeScale(2);
        // openPostTimeline.addLabel("end", "+=0.2")

        openPostTimeline.set(`#post-preview-${post.id}`, { zIndex: "9999" });
        openPostTimeline.set(`#post-preview-${post.id}`, { transition: "unset" });
        openPostTimeline.set(`#post-preview-exit-background`, { visibility: "hidden" });
        openPostTimeline.set(`#post-preview-${post.id} .alpha-post-creation-absolute`, { visibility: "hidden" });
        // openPostTimeline.set("body", { overflowY: "hidden", paddingRight: `${scrollbarWidth}px` });
        openPostTimeline.set(`#post-preview-${post.id}`, { overflowY: "hidden" });
        openPostTimeline.set(`#post-preview-${post.id} .interactions-container`, { overflow: "hidden" });


        openPostTimeline.set(`#post-preview-exit-background`, { visibility: "visible" }, "<");
        openPostTimeline.fromTo(`#post-preview-exit-background`, { backdropFilter: "blur(0px)" }, { backdropFilter: "blur(4px)", duration: .3 }, "<");

        // openPostTimeline.fromTo(`#post-preview-${post.id} .alpha-post-preview-shade`, { opacity: 1 }, { duration: .3, opacity: 0 })
        openPostTimeline.fromTo(`#post-preview-${post.id} .alpha-post-bottom-fade`, { opacity: 1 }, { duration: .3, opacity: 0 }, "<")
        if (post.content.prediction?.asset?.type === "token") openPostTimeline.fromTo(`#post-preview-${post.id} .alpha-post-token-live-info-container`, { maxHeight: "0px" }, { maxHeight: "500px", duration: .5 }, "<");
        // openPostTimeline.fromTo(`#post-preview-${post.id} .alpha-post-footer-container`, { maxHeight: "0px" }, { maxHeight: "800px", duration: 0.2 }, "<");
        openPostTimeline.fromTo(`#post-preview-${post.id}`, { maxHeight: "300px" }, { maxHeight: "80vh", duration: 0.2 }, "<");

        if (window.innerWidth < 768) openPostTimeline.fromTo(`#post-preview-${post.id} .interactions-container`, { maxWidth: "50px", padding: "0.75rem 0.75rem 0 0.75rem" }, { maxWidth: "0px", padding: "0px", duration: 0.2 }, "<")

        togglePostTimeline.current = openPostTimeline;
    }

    function removeLastTwoTweensFromTimeline(timeline: gsap.core.Timeline) {
        var children = timeline.getChildren(false, true, true, 0);
        timeline.remove(children[children.length - 1]);
        timeline.remove(children[children.length - 2]);
    }

    async function openPost() {
        onPostClick(post)
        history.push(`${location.pathname.replace(`/${post.id}`, "")}/${post.id}`);
        // if (togglePostTimeline.current === null) return;

        // if (!isPostOpen) {
        //     const bigBoardContainerElement = document.querySelector("#big-board");
        //     const postPreviewElement = document.querySelector(`#post-preview-${post.id}`);
        //     if (postPreviewElement) postPreviewElement.scrollTop = 0;
        //     const postPreviewElementRect = postPreviewElement?.getBoundingClientRect();
        //     const yTranslate = 120 - (postPreviewElementRect?.top ?? 100);
        //     const widthOfScreenRatio = window.innerWidth > 1280 ? 0.7 : window.innerWidth > 1024 ? 0.75 : window.innerWidth > 768 ? 0.85 : 0.9;
        //     const xTranslate = (1 - widthOfScreenRatio) / 2 * window.innerWidth - (postPreviewElementRect?.left ?? 0) + (bigBoardContainerElement?.getBoundingClientRect().left ?? 0) / 2;
        //     const openWidthPercentage = (window.innerWidth * widthOfScreenRatio * 100) / (postPreviewElement?.clientWidth ?? 100);
        //     togglePostTimeline.current.fromTo(`#post-preview-${post.id}`, { scale: "1", y: 0, x: 0, width: "100%" }, { scale: "1.1", y: yTranslate, x: xTranslate, width: `${openWidthPercentage}%`, duration: 0.2 }, "<")
        //     togglePostTimeline.current.set(`#post-preview-${post.id}`, { overflowY: "auto" }, "end");
        //     togglePostTimeline.current.play();
        //     // console.log(gsap.globalTimeline.getChildren())
        //     togglePostTimeline.current.eventCallback("onComplete", (e) => removeLastTwoTweensFromTimeline(togglePostTimeline.current!));
        // }

        // setPostPreviewOpen(true)
        // setIsPostOpen(true)
    }

    async function closePost() {
        if (togglePostTimeline.current === null) return;

        if (isPostOpen) {
            // const postPreviewElement: HTMLElement = document.querySelector(`#post-preview-${post.id}`) as HTMLElement;
            // if (postPreviewElement) postPreviewElement.scrollTo({ top: 0, behavior: 'smooth' });
            // if (postPreviewElement) postPreviewElement.scrollTop = 0;
            // const postPreviewElementTransform = postPreviewElement?.style.transform;
            // const postPreviewElementTransformTranslate = postPreviewElementTransform?.split(") ").find((transform) => transform.includes("translate") || transform.includes("translate3d"));
            // const [currentXTranslate, currentYTranslate] = postPreviewElementTransformTranslate ? postPreviewElementTransformTranslate.split("(")[1].split(",") : ["0px", "0px"];
            // const currentYTranslate = postPreviewElementTransform ? postPreviewElementTransform.split(") ").find((transform) => transform.includes("translate") || transform.includes("translate3d"))?.split(",")[1].replaceAll(")", "") : 0;
            // const currentWidth = postPreviewElement.style.width;
            // togglePostTimeline.current.fromTo(`#post-preview-${post.id}`, { scale: "1", y: 0, x: 0, width: "100%" }, { scale: "1.1", y: currentYTranslate, x: currentXTranslate, width: currentWidth, duration: 0.5 }, "<")
            togglePostTimeline.current.from(`#post-preview-${post.id}`, { scale: "1", y: 0, x: 0, width: "100%", duration: 0.2 }, "<")
            togglePostTimeline.current.set(`#post-preview-${post.id}`, { overflowY: "auto" }, "end");
            togglePostTimeline.current.reverse();
            // console.log(gsap.globalTimeline.getChildren())
            togglePostTimeline.current.eventCallback("onReverseComplete", () => removeLastTwoTweensFromTimeline(togglePostTimeline.current!));
        }

        history.push(location.pathname.replace(`/${post.id}`, ""));
        setIsPostOpen(false);
    }

    useEffect(() => {
        if (!postPreviewOpen) closePost();
    }, [postPreviewOpen])


    useEffect(() => {
        if (post.content.prediction?.asset?.type !== "token" || !isPostOpen) return;
        fetchPostTokenPoolStats()
        fetchPostTokenSecurityMetrics()
        const fetchTokenPoolStatsInterval = setInterval(() => fetchPostTokenPoolStats(false), 30000);
        return () => clearInterval(fetchTokenPoolStatsInterval);
    }, [post.content.prediction?.asset, isPostOpen])

    async function fetchPostTokenPoolStats(indicateFetch: boolean = true) {
        if (post.content.prediction?.asset?.type !== "token") return;
        console.log("fetching token pool stats")
        setPostTokenPoolStatsFetch((prevPostTokenPoolStatsFetch: PostTokenPoolStatsFetch) => ({ ...prevPostTokenPoolStatsFetch, fetching: indicateFetch }));
        const response = await api.defi.getTokenPoolStats(post.content.prediction.asset.info as TokenInfo);
        setPostTokenPoolStatsFetch({ tokenPoolStats: response, fetching: false });
    }

    async function fetchPostTokenSecurityMetrics(indicateFetch: boolean = true) {
        if (post.content.prediction?.asset?.type !== "token") return;
        console.log("fetching token security metrics")
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, fetching: indicateFetch });
        const response = await api.defi.getTokenSecurityMetrics(post.content.prediction.asset.info as TokenInfo);
        await delay(100);
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, tokenSecurityMetrics: response, fetching: false, drawerVisible: true });
    }

    const boardPostPredictionTimeframe = post.content.prediction?.timeframe ?? null;
    const currencySpan = <span className="currency-icon">{post.content.prediction?.asset ? post.content.prediction.asset.assetClass === AssetClass.Crypto ? "$" : post.content.prediction.asset.assetClass === AssetClass.FriendTech ? <EthIcon className="w-[10px] h-auto ms-1" /> : <img src={(post.content.prediction.asset.info as NFTInfo).pricingIcon} /> : ""}</span>;


    // useEffect(() => {
    //     if (isPostOpen) updatePostTimeLeftToDelete();
    // }, [post, currentUser, isPostOpen, selectedPostTimeLeftToDelete])
    // async function updatePostTimeLeftToDelete() {
    //     if (post.creation.user.userId === currentUser?.userId) {
    //         if (selectedPostTimeLeftToDelete !== 0 && selectedPostTimeLeftToDelete !== undefined) await delay(1000)
    //         setSelectedPostTimeLeftToDelete(Math.max(post.creation.timestamp + MAX_TIME_TO_DELETE_ALPHA_POST - Date.now(), 0))
    //     } else {
    //         setSelectedPostTimeLeftToDelete(0)
    //     }
    // }

    async function tryDeleteSelectedPost() {
        if (currentUser === null) return;
        if (post.creation.user.userId !== currentUser?.userId || selectedPostTimeLeftToDelete === undefined || selectedPostTimeLeftToDelete <= 0) return;
        setDeletingPost(true);

        const success = await api.alphaPost.deletePost(post.id);
        if (success) {
            mixpanel.track("Post: Delete", {
                // timeLeftToDelete: selectedPostTimeLeftToDelete,
                postCreatorIsEliteMember: post.creation.user.isEliteCreator,
            })
            removePostFromPostsList(post.id)
            closePost();
            return;
        }
        setDeletingPost(false);
    }

    useEffect(() => {
        if (!isPostOpen) return;
        if (!alphaPostPreviewContainerRef.current) return;
        if (postTokenSecurityMetricsFetch.drawerOpen) {
            alphaPostPreviewContainerRef.current.scrollTop = 0;
            alphaPostPreviewContainerRef.current.style.setProperty("overflow-y", "hidden");
            // alphaPostModalParentElement.style.setProperty("padding-right", `${getScrollBarWidth()}px`);
        } else {
            alphaPostPreviewContainerRef.current.style.setProperty("overflow-y", "scroll");
            // alphaPostModalParentElement.style.removeProperty("padding-right");
        }
    }, [postTokenSecurityMetricsFetch.drawerOpen, alphaPostPreviewContainerRef.current])

    return <div id={`post-preview-${post.id}`} ref={alphaPostPreviewContainerRef} className={`alpha-post-preview ${isPostOpen ? "open" : ""} relative flex flex-col w-full min-h-[200px] overflow-hidden rounded-3xl border border-neutral-800 bg-neutral-900 shadow-xl transition-all duration-500 my-2`}>
        {/* <div className="alpha-post-preview-shade"></div> */}
        {isPostOpen ? <button className="absolute top-5 right-5 btn-simple text-white z-10"
            onClick={() => closePostPreview()}>
            <FontAwesomeIcon icon={faXmark} /></button> : ""}
        <div className="flex alpha-post-content h-full">
            <div className="interactions-container flex flex-col items-center" style={{ padding: "0.75rem 0.75rem 0 0.75rem" }}>
                <CaretUp className={`svg-inline--fa fa-caret-up hover:cursor-pointer fa-2x ${currentUser && post.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection shadow-selection" : ""}`} onClick={() => interactWithPost(post, PostInteraction.Upvote)} />
                {post.interactions.upvote.accounts.length - post.interactions.downvote.accounts.length}
                {/* <FontAwesomeIcon icon={faCaretDown} size="2x" className={brokerInteractionDetails.downvoted ? "text-error" : ""} onClick={() => interactWithPost(boardInfo, PostInteraction.Downvote)} /> */}
                <CaretDown className={`svg-inline--fa fa-caret-down hover:cursor-pointer fa-2x ${currentUser && post.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error shadow-error" : ""}`} onClick={() => interactWithPost(post, PostInteraction.Downvote)} />
                <div className="flex flex-col share-icon mt-3">
                    <Popover placement="right">
                        <PopoverHandler>
                            <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><ShareIcon /></button>
                        </PopoverHandler>
                        <PopoverContent className="bg-white z-[9999]">
                            <div className="flex items-center text-black mb-2">
                                <CopiableElement copy={`${getFullBaseUrl(window)}/big-board/${post.id}`} component={<span><FontAwesomeIcon icon={faLink} className="w-[20px] me-[0.8rem]" /> Copy link to post</span>} />
                            </div>
                            <div className="flex items-center mt-2">
                                <a style={{ textDecoration: "none", color: "black" }} href={getSharePostOnTwitterLink(post)} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                            </div>
                        </PopoverContent>
                    </Popover>
                    <button className="btn-simple mt-2" onClick={() => toggleSavePost(post)}>
                        {currentUser?.confidential.userAlphaInfo[post.content.prediction?.asset.type === "token" ? "crypto" : post.content.prediction?.asset.type === "nft" ? "nft" : post.content.prediction?.asset.type === "ftShare" ? "friendTech" : "global"]?.savedPostIds.includes(post.id) ? <BookmarkIcon /> : <OutlineBookmarkIcon />}
                    </button>
                </div>
            </div>
            <div className="information-container flex flex-col w-full px-3 pb-3" style={{ overflowY: "hidden" }} onClick={openPost}>
                <div className={`flex flex-col sticky top-0 ${isPostOpen ? "bg-dark-second" : ""} pt-3 pb-2`}>
                    {post.content.prediction?.asset ? <div className="post-trade-details flex flex-wrap">
                        <div className="flex items-center token-info ms-2 me-3 my-1 order-first">
                            <img src={post.content.prediction.asset.info.iconUrl} onError={({ currentTarget }) => {
                                (currentTarget as HTMLImageElement).onerror = null;
                                (currentTarget as HTMLImageElement).src = post.content.prediction!.asset!.type === "ftShare" ? friendTechIcon : randomGradientImg();
                            }} className="token-icon me-2" /> {post.content.prediction!.asset!.info.symbol} <span className="price-at-creation flex items-center ms-2">({post.content.prediction.asset.type === "ftShare" ? post.content.prediction.asset.priceAtCreation : numberWithCommas(getRoundedPrice(post.content.prediction.asset.priceAtCreation))} {currencySpan})</span>
                        </div>
                        <div className={`flex items-center price-range-info ${post.content.prediction.position === "long" ? "uptrend" : "downtrend"} mx-2 my-1 order-last`}>
                            {/* <FontAwesomeIcon icon={boardInfo.content.position === "long"  ? faArrowTrendUp : faArrowTrendDown} className="me-2" /> {numberWithCommas(getRoundedPrice(boardInfo.content.targetRange.start))}$ - {numberWithCommas(getRoundedPrice(boardInfo.content.targetRange.end))}$ */}
                            {post.content.prediction.advancedOptions?.entryTarget ? <span className="text-white">{numberWithCommas(getRoundedPrice(post.content.prediction.advancedOptions.entryTarget))} {currencySpan}</span> : ""} <FontAwesomeIcon icon={post.content.prediction.position === "long" ? faArrowTrendUp : faArrowTrendDown} className="mx-2" /> {post.content.prediction.advancedOptions ? <>{numberWithCommas(getRoundedPrice(post.content.prediction.advancedOptions.exitTargets[0]))} {currencySpan}</> : ""}
                        </div>
                        {boardPostPredictionTimeframe !== null ? <div className="flex items-center time-range-info mx-2 my-1 md:order-last">
                            <img src={ALPHA_TIME_RANGE_ICONS[post.content.prediction.timeframeRange]} className="h-[20px] w-auto me-2" />
                            {timestampToSmallDate(boardPostPredictionTimeframe.start / 1000)} - {timestampToSmallDate(boardPostPredictionTimeframe.end / 1000)}
                        </div> : null}
                    </div> : ""}
                    <div className="post-title flex items-center text-header mt-2">
                        <h4 className="header-gradient font-bold">{post.content.title}</h4>
                    </div>

                    {
                        post.content.prediction?.asset?.type === "token" ? <div className="flex flex-col alpha-post-token-live-info-container w-full h-full" style={{ maxHeight: "0px", overflow: "hidden" }}>
                            <div id="alpha-post-token-pool-stats" className="flex flex-col lg:flex-row items-center px-4 py-2 mb-2 mt-3">
                                <div className="flex flex-col mb-2 lg:mb-0">
                                    <div className="flex items-center">
                                        {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
                                            <h2 className="mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.price ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.price)}` : "-"}</h2>
                                            <h6 className={`ms-2 mb-0 ${(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? "text-selection" : "text-error"}`}>{(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? <CaretUp /> : <CaretDown />} {postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h.toFixed(2) ?? "-"}%</h6>
                                        </>}
                                    </div>
                                </div>
                                <div className="flex lg:ms-auto">
                                    <div className="flex flex-col items-center mx-3">
                                        <h6 className="pool-stat-header mb-1">Volume</h6>
                                        {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value">{postTokenPoolStatsFetch.tokenPoolStats?.volume24h ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.volume24h, true)}` : "-"}</h5>}
                                    </div>
                                    <div className="flex flex-col items-center mx-3">
                                        <h6 className="pool-stat-header mb-1">Liquidity</h6>
                                        {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value">{postTokenPoolStatsFetch.tokenPoolStats?.liquidity ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.liquidity, true)}` : "-"}</h5>}
                                    </div>
                                    <div className="flex flex-col items-center mx-3">
                                        <h6 className="pool-stat-header mb-1">FDV</h6>
                                        {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value">{postTokenPoolStatsFetch.tokenPoolStats?.fdv ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.fdv, true)}` : "-"}</h5>}
                                    </div>
                                </div>
                            </div>
                            <button id="alpha-post-token-security-metrics-btn" className="btn btn-default" onClick={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: true })}>Audit Scan</button>
                        </div> : ""
                    }
                </div>
                <div className="alpha-post-research-preview"><div dangerouslySetInnerHTML={{ __html: purify.sanitize(post.content.research.content) }}></div></div>
                {post.content.research.chartUrl ? <div className="alpha-post-research-chart w-full h-auto mt-3 mb-2"><img src={post.content.research.chartUrl} className="w-full h-auto" /></div> : post.content.research.media.length ? <div className="alpha-post-research-chart w-full h-auto mt-3 mb-2"><img src={post.content.research.media[0].url} className="w-full h-auto" /></div> : ""}

                <div className="mobile-open-interactions-container flex items-center md:hidden mt-2">
                    <CaretUp className={`svg-inline--fa fa-caret-up hover:cursor-pointer text-lg mx-2 ${currentUser !== null && post.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection" : ""}`} onClick={() => interactWithPost(post, PostInteraction.Upvote)} />
                    {post.interactions.upvote.accounts.length - post.interactions.downvote.accounts.length}
                    <CaretDown className={`svg-inline--fa fa-caret-down hover:cursor-pointer text-lg mx-2 ${currentUser !== null && post.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error" : ""}`} onClick={() => interactWithPost(post, PostInteraction.Downvote)} />

                    <div className="flex ms-3">
                        <Popover placement="right">
                            <PopoverHandler>
                                <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><FontAwesomeIcon icon={faArrowUpFromBracket} className="text-base" /></button>
                            </PopoverHandler>
                            <PopoverContent className="z-[9999]">
                                <div className="flex items-center text-black mb-2">
                                    <CopiableElement copy={`${getFullBaseUrl(window)}/big-board/${post.id}`} component={<span><FontAwesomeIcon icon={faLink} className="w-[20px] me-[0.8rem]" /> Copy link to post</span>} />
                                </div>
                                <div className="flex items-center mt-2">
                                    <a style={{ textDecoration: "none", color: "black" }} href={getSharePostOnTwitterLink(post)} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                                </div>
                            </PopoverContent>
                        </Popover>
                        <button className="btn-simple mt-2" onClick={() => toggleSavePost(post)}>
                            {currentUser?.confidential.userAlphaInfo[post.content.prediction?.asset.type === "token" ? "crypto" : post.content.prediction?.asset.type === "nft" ? "nft" : post.content.prediction?.asset.type === "ftShare" ? "friendTech" : "global"]?.savedPostIds.includes(post.id) ? <BookmarkIcon /> : <OutlineBookmarkIcon />}
                        </button>
                    </div>
                </div>

                {isPostOpen ? <div className={`alpha-post-creation flex flex-col relative ms-auto text-sm text-primary mt-3`}>
                    <div className="flex items-center">
                        <Link className="flex items-center me-1" to={`/profile/a/${post.creation.user.userId}`}><img src={post.creation.user.pfpUrl ?? undefined} className="pfp-icon me-1" /> {post.creation.user.username}</Link> · {timestampToRelative(post.creation.timestamp)}
                    </div>
                    {post.creation.user.userId === currentUser?.userId && !post.analysis.toBeAnalyzed && selectedPostTimeLeftToDelete !== undefined && selectedPostTimeLeftToDelete > 0 && <div className="alpha-post-delete-timer flex justify-end me-2 text-sm">
                        <Menu
                            placement="bottom-end"
                            dismiss={{
                                itemPress: false,
                            }}
                        >
                            <MenuHandler>
                                <button><FontAwesomeIcon icon={faTrash} /></button>
                            </MenuHandler>
                            <MenuList className="flex flex-col items-center text-black z-[9999]">
                                {
                                    deletingPost ?
                                        <>
                                            {/* <h6 className="hover:outline-none">Deleting</h6> */}
                                            <img src={loadingIconAnim} className="h-[25px] w-auto" />
                                        </> :
                                        <>
                                            <h6 className="hover:outline-none">Delete Post?</h6>
                                            <button className="bg-red-500 text-white py-1 w-full rounded-md hover:outline-none hover:bg-red-700 my-1" onClick={tryDeleteSelectedPost}>Confirm</button>
                                        </>
                                }
                            </MenuList>
                        </Menu>
                    </div>}
                </div> : ""}
                <div className={`alpha-post-creation-absolute flex items-center absolute bottom-[8px] right-0 text-sm text-primary z-10 me-5`}>
                    <Link className="flex items-center me-1" to={`/profile/a/${post.creation.user.userId}`}><img src={post.creation.user.pfpUrl ?? undefined} className="pfp-icon me-1" /> {post.creation.user.username}</Link> · {timestampToRelative(post.creation.timestamp)}
                </div>

                {isPostOpen ? <div className="flex flex-col alpha-post-footer-container">
                    <div id="nfa-disclaimer-container" className="flex flex-col w-full mt-4">
                        <h6 className="text-header">DISCLAIMER</h6>
                        <p className="text-normal">The information in this post and comments is not meant to be, and does not constitute, financial, investment, trading, or other types of advice or recommendations supplied or endorsed by Primordium Labs.</p>
                    </div>
                    <div style={{ height: "1px", background: "rgba(255, 255, 255, 0.4)", marginTop: "4rem", borderRadius: "3rem" }}></div>
                    <AlphaPostChat className="mt-10" isActive={isPostOpen} alphaPostId={post.id} alphaPostAsset={post.content.prediction?.asset} alphaPostCreatorId={post.creation.user.userId} />

                </div> : ""}
                <div className="alpha-post-bottom-fade"></div>
            </div>
        </div>
        {post.content.prediction?.asset?.type === "token" && !postTokenSecurityMetricsFetch.fetching && postTokenSecurityMetricsFetch.drawerVisible && <TokenSecurityMetricsDrawer tokenInfo={post.content.prediction.asset.info} postTokenSecurityMetricsFetch={postTokenSecurityMetricsFetch} close={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: false })} />}
    </div>
};

export default AlphaPostPreview;