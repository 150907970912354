import axios from "axios";

import { API_URL } from "../api";
import { UserAccount } from "../../types";
import { firebaseAuth } from "../../firebaseApp";

async function completeTwitterConnect(
    oauthAccessToken: string,
    oauthTokenSecret: string,
): Promise<{ userAccount: UserAccount, success: boolean, token: string } | null> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return null;
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + firebaseAuthToken,
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/auth/twitter/complete-twitter-connect`, JSON.stringify({ oauthAccessToken, oauthTokenSecret }), customConfig);
    } catch (error) {
        console.error(error);
        return null
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null;
    }

    return responseData;
}

export default {
    completeTwitterConnect
}