import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";

import { ReactComponent as TwitterXIcon } from "../../../media/icons/twitterXIcon.svg"
import { ReactComponent as TelegramIcon } from "../../../media/icons/telegramIcon.svg"
import { useUser } from "../../../Components/Providers/useUser";
import { SearchedUserAccountFetch, CreatorClubFetch } from "../types";
import TransactClubButton from "../../../Components/CreatorClub/TransactClubButton";

const UserProfileInfo: FC<any> = (props) => {
    const { searchedUserAccountFetch, userCreatedClubFetch }: { searchedUserAccountFetch: SearchedUserAccountFetch, userCreatedClubFetch: CreatorClubFetch } = props;

    const { currentUser } = useUser();

    const [userLinkedPlatforms, setUserLinkedPlatforms] = useState<string[]>([]);

    useEffect(() => {
        if (searchedUserAccountFetch.userAccount) {
            const userLinkedPlatforms: string[] = [];
            if (searchedUserAccountFetch.userAccount.userAuth.twitter?.id) userLinkedPlatforms.push("twitter");
            if (searchedUserAccountFetch.userAccount.userAuth.telegram?.id) userLinkedPlatforms.push("telegram");
            setUserLinkedPlatforms(userLinkedPlatforms);
        }
    }, [searchedUserAccountFetch.userAccount])

    return searchedUserAccountFetch.userAccount || searchedUserAccountFetch.fetching ? <div id="user-profile-info" className="flex flex-col md:flex-row items-center md:items-start w-full max-w-[800px] mt-16 px-5 md:ms-16">
        <div id="user-avatar" className="flex flex-col items-center justify-center me-5">
            {searchedUserAccountFetch.fetching ? <div className="loading-square !rounded-2xl !w-[150px] !h-[150px]"></div> : <img src={searchedUserAccountFetch.userAccount!.userInfo.pfpUrl} className="rounded-2xl w-[150px] h-[150px]" />}
        </div>
        <div className="flex flex-col mt-5 md:mt-0">
            <div className="flex justify-center md:justify-start items-center w-full">
                {searchedUserAccountFetch.fetching ? <div className="loading-square !w-[350px] !h-[50px] !my-[12.5px] !mx-0"></div> : <><h1 className="text-6xl">{searchedUserAccountFetch.userAccount!.userInfo.username}</h1> {searchedUserAccountFetch.userAccount!.userAuth.twitter ? <TwitterXIcon className="h-[30px] w-auto ms-4" /> : <TelegramIcon className="h-[30px] w-auto ms-4" />}</>}
            </div>
            <div className="flex items-center justify-center md:justify-start w-full mt-3">
                {
                    searchedUserAccountFetch.fetching ? <>
                        <div className="loading-square !w-[150px] !h-[38px] !me-2 !ms-0"></div>
                        <div className="loading-square !w-[150px] !h-[38px] !ms-2 !me-0"></div>
                    </> :
                        searchedUserAccountFetch.userAccount ?
                            <>
                                <button disabled={userLinkedPlatforms.includes("twitter") || currentUser === null || currentUser?.userId !== searchedUserAccountFetch.userAccount.userId || true} className={`btn-default whitespace-nowrap ${userLinkedPlatforms.includes("twitter") ? "bg-dark-third" : ""} flex items-center w-fit px-3 me-2`}><TwitterXIcon className="h-[20px] w-auto me-4" /> {userLinkedPlatforms.includes("twitter") ? searchedUserAccountFetch.userAccount!.userAuth.twitter?.name : currentUser?.userId === searchedUserAccountFetch.userAccount.userId ? "Link Twitter" : "Twitter not linked."}</button>
                                <button disabled={userLinkedPlatforms.includes("telegram") || currentUser === null || currentUser?.userId !== searchedUserAccountFetch.userAccount.userId || true} className={`btn-default whitespace-nowrap ${userLinkedPlatforms.includes("telegram") ? "bg-dark-third" : ""} flex items-center w-fit px-3 ms-2`}><TelegramIcon className="h-[20px] w-auto me-4" /> {userLinkedPlatforms.includes("telegram") ? searchedUserAccountFetch.userAccount!.userAuth.telegram?.name : currentUser?.userId === searchedUserAccountFetch.userAccount.userId ? "Link Telegram" : "Telegram not linked."}</button>
                            </> : ""
                }
            </div>
            {/* <div className="flex justify-center md:justify-start mt-4">
                {userCreatedClubFetch.creatorClub ? userCreatedClubFetch.fetchStatus === "fetching" || userCreatedClubFetch.creatorClubOnchainStatsFetch.fetchStatus === "fetching" ? <div className="loading-square w-[80px] h-[20px] mx-3"></div> : <span className="mx-3">{userCreatedClubFetch.creatorClubOnchainStatsFetch.creatorClubOnchainStats?.numShares ?? "-"} shares</span> : ""}
                {userCreatedClubFetch.creatorClub ? userCreatedClubFetch.fetchStatus === "fetching" || userCreatedClubFetch.creatorClubOnchainStatsFetch.fetchStatus === "fetching" ? <div className="loading-square w-[80px] h-[20px] mx-3"></div> : <span className="mx-3">{userCreatedClubFetch.creatorClubOnchainStatsFetch.creatorClubOnchainStats?.numShareholders ?? "-"} members</span> : ""}
            </div> */}

            {/* <div className="flex justify-center mt-5">
                <div className="flex flex-col text-header text-center w-1/2">
                    <h3 className="header-gradient font-bold">Rating</h3>
                    {searchedUserAccountFetch.fetching ? <div className="loading-square"></div> : <h5>{searchedUserAccountFetch.userAccount!.userAlphaInfo.crypto.ratings?.averages.lifetime ?? "-"}</h5>}
                </div>
                <div className="flex flex-col text-header text-center w-1/2">
                    <h3 className="header-gradient font-bold">PnL</h3>
                    {searchedUserAccountFetch.fetching ? <div className="loading-square"></div> : <h5>{searchedUserAccountFetch.userAccount!.userAlphaInfo.crypto.pnl?.averages.lifetime ?? "-"}</h5>}
                </div>
 
            </div> */}
            {/* <TransactClubButton isBuy={true} creatorClubFetch={userCreatedClubFetch} className="mx-4" />
                <TransactClubButton isBuy={false} creatorClubFetch={userCreatedClubFetch} className="mx-4" /> */}
            {/* {userCreatedClubFetch.creatorClub ? <p className="text-normal mt-3 ms-3">Private Telegram: {userCreatedClubFetch.fetchStatus === "fetching" ? <div></div> : userCreatedClubFetch.creatorClub?.linkedTelegramGroup?.inviteLink ?? "None"}</p> : ""} */}
        </div>

    </div> : null

};

export default UserProfileInfo;