import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";

const ErrorContainer: FC<any> = (props) => {
    const { text, buttonText, onClick, className } = props;

    return (
        <div className={`flex flex-col error-container items-center text-white ${className ?? ""}`}>
            <p>{text}</p>
            <button className="btn-simple w-fit px-3" onClick={onClick}><FontAwesomeIcon icon={faArrowRotateRight} /> {buttonText}</button>
        </div>
    )
}

export default ErrorContainer;
