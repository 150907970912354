import axios from "axios";

import { API_URL } from "../api";
import { UserAccount } from "../../types";

async function connectWithTelegram(
    telegramInitData: string,
    fromWebApp: boolean,
): Promise<{ userAccount: UserAccount, success: boolean, token: string } | null> {
    const data = JSON.stringify({ telegramInitData, fromWebApp });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/auth/telegram/connect`, data, customConfig);
    } catch (error: any) {
        console.error(error);
        return null;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null;
    }

    return responseData;
}

export default {
    connectWithTelegram
}