import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";
import UserProfileStats from "./UserProfileStats";
import { PostsListFetch } from "../../AlphaClub/types";

import { AssetClass } from "../../../utils/types";
import AlphaPostsWidget from "../../AlphaClub/Widgets/AlphaPostsWidget";
import { useUser } from "../../../Components/Providers/useUser";
import firebaseHelpers from "../../../utils/firebaseHelpers";
import UserProfileClub from "./UserProfileClub";
import { SearchedUserAccountFetch, CreatorClubFetch } from "../types";
import UserProfileShares from "./UserProfileShares";

enum UserProfileContentSelection {
    USER_CLUB,
    STATS,
    POSTS,
    BOOKMARKS,
    SHARES,
}

const NUM_BOOKMARKS_RESULTS_ON_FETCH = 5;
const NUM_POSTS_RESULTS_ON_FETCH = 10

const UserProfileContent: FC<any> = (props) => {
    const { searchedUserAccountFetch, searchedUserIsConnectedUser, userCreatedClubFetch }: { searchedUserAccountFetch: SearchedUserAccountFetch, searchedUserIsConnectedUser: boolean, userCreatedClubFetch: CreatorClubFetch } = props;

    const { currentUser } = useUser();

    const [userProfileContentSelection, setUserProfileContentSelection] = useState<UserProfileContentSelection>(UserProfileContentSelection.STATS);
    const [searchedUserPostsFetch, setSearchedUserPostsFetch] = useState<PostsListFetch>({ alphaPosts: [], fetching: false, fetchError: false, allFetched: false, fetch: fetchSearchedUserPosts });
    const [searchedUserBookmarksFetch, setSearchedUserBookmarksFetch] = useState<PostsListFetch>({ alphaPosts: [], fetching: false, fetchError: false, allFetched: false, fetch: fetchSearchedUserBookmarks });

    useEffect(() => {
        if (userProfileContentSelection === UserProfileContentSelection.STATS) return;
        else if (userProfileContentSelection === UserProfileContentSelection.POSTS) {
            if (searchedUserPostsFetch.alphaPosts.length === 0 || searchedUserPostsFetch.alphaPosts.filter((alphaPost) => alphaPost.creation.user.userId !== searchedUserAccountFetch.userAccount?.userId).length) fetchSearchedUserPosts(true);
        }
        else if (userProfileContentSelection === UserProfileContentSelection.BOOKMARKS) {
            if (searchedUserBookmarksFetch.alphaPosts.length === 0 || searchedUserBookmarksFetch.alphaPosts.filter((alphaPost) => alphaPost.creation.user.userId !== searchedUserAccountFetch.userAccount?.userId).length) fetchSearchedUserBookmarks(true);
        }
    }, [userProfileContentSelection, searchedUserAccountFetch.userAccount])

    async function fetchSearchedUserPosts(clearPreviousFetches: boolean) {
        await fetchSearchedUserPostsType(searchedUserPostsFetch, setSearchedUserPostsFetch, clearPreviousFetches);
    }
    async function fetchSearchedUserBookmarks(clearPreviousFetches: boolean) {
        if (!currentUser) return;
        const currentUserBookmarks = [...currentUser.confidential.userAlphaInfo.crypto.savedPostIds];
        currentUserBookmarks.reverse();
        const bookmarksIdsToFetch = currentUserBookmarks.slice(searchedUserBookmarksFetch.alphaPosts.length, searchedUserBookmarksFetch.alphaPosts.length + NUM_BOOKMARKS_RESULTS_ON_FETCH);
        if (bookmarksIdsToFetch.length === 0) return setSearchedUserBookmarksFetch({ ...searchedUserBookmarksFetch, allFetched: true });
        await fetchSearchedUserPostsType(searchedUserBookmarksFetch, setSearchedUserBookmarksFetch, clearPreviousFetches, bookmarksIdsToFetch);
    }

    async function fetchSearchedUserPostsType(postsFetch: PostsListFetch, setPostsFetch: (value: React.SetStateAction<PostsListFetch>) => void, clearPreviousFetches = false, alphaPostsIds?: string[]) {
        if (!searchedUserAccountFetch.userAccount || postsFetch.fetching || (!clearPreviousFetches && postsFetch.allFetched)) return;
        setPostsFetch({ ...postsFetch, fetching: true, alphaPosts: clearPreviousFetches ? [] : postsFetch.alphaPosts });
        const { success, alphaPosts } = alphaPostsIds ? await firebaseHelpers.alphaPosts.getSpecificPostsInfo(alphaPostsIds) : await firebaseHelpers.alphaPosts.getRecentPosts(null, NUM_POSTS_RESULTS_ON_FETCH, clearPreviousFetches ? Date.now() : postsFetch.alphaPosts.length > 0 ? postsFetch.alphaPosts[postsFetch.alphaPosts.length - 1].creation.timestamp : Date.now(), undefined, undefined, AssetClass.Crypto, [searchedUserAccountFetch.userAccount.userId]);
        // const newAlphaPosts = alphaPosts.map((post: AlphaPostInfo) => arrangePostComments(post))
        setPostsFetch({ ...postsFetch, fetching: false, alphaPosts: clearPreviousFetches ? alphaPosts : [...postsFetch.alphaPosts, ...alphaPosts], fetchError: !success, allFetched: success && alphaPosts.length < NUM_POSTS_RESULTS_ON_FETCH });
    }


    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom) {
            if (userProfileContentSelection === UserProfileContentSelection.POSTS) fetchSearchedUserPosts(false);
            else if (userProfileContentSelection === UserProfileContentSelection.BOOKMARKS) fetchSearchedUserBookmarks(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [searchedUserPostsFetch, searchedUserBookmarksFetch, searchedUserAccountFetch]);



    return (
        <div id="user-profile-content" className="flex flex-col flex-1 mt-16">
            <div id="user-profile-content-selection-container" className="flex w-full border-b border-dark-third">
                {/* <button className="btn-simple hover:backdrop-brightness-200 transition-all duration-200 w-full py-3" onClick={() => setUserProfileContentSelection(UserProfileContentSelection.USER_CLUB)}><h5 className="text-header header-gradient font-bold">Creator Club</h5></button> */}
                <button className="btn-simple hover:backdrop-brightness-200 transition-all duration-200 w-full py-3" onClick={() => setUserProfileContentSelection(UserProfileContentSelection.STATS)}><h5 className="text-header header-gradient font-bold">User Stats</h5></button>
                <button className="btn-simple hover:backdrop-brightness-200 transition-all duration-200 w-full py-3" onClick={() => setUserProfileContentSelection(UserProfileContentSelection.POSTS)}><h5 className="text-header header-gradient font-bold">Posts</h5></button>
                {searchedUserIsConnectedUser ? <button className="btn-simple hover:backdrop-brightness-200 transition-all duration-200 w-full py-3" onClick={() => setUserProfileContentSelection(UserProfileContentSelection.BOOKMARKS)}><h5 className="text-header header-gradient font-bold">Bookmarks</h5></button> : ""}
                {/* <button className="btn-simple hover:backdrop-brightness-200 transition-all duration-200 w-full py-3" onClick={() => setUserProfileContentSelection(UserProfileContentSelection.SHARES)}><h5 className="text-header header-gradient font-bold">Shares</h5></button> */}
            </div>
            {/* If fetching add animate alpha */}
            {!searchedUserAccountFetch.fetching && searchedUserAccountFetch.userAccount !== null && <div id="user-profile-content-container" className="flex justify-center w-full h-full overflow-y-auto no-scrollbar">
                {/* <UserProfileClub className={`${userProfileContentSelection !== UserProfileContentSelection.USER_CLUB ? "hidden" : ""}`} searchedUserAccountFetch={searchedUserAccountFetch} userCreatedClubFetch={userCreatedClubFetch} /> */}
                <UserProfileStats className={`${userProfileContentSelection !== UserProfileContentSelection.STATS ? "hidden" : ""} pt-24`} />
                <AlphaPostsWidget className={`${userProfileContentSelection !== UserProfileContentSelection.POSTS ? "hidden" : ""} w-full px-10 pt-8`} postsListFetch={searchedUserPostsFetch} setPostsListFetch={setSearchedUserPostsFetch} fetchAlphaPosts={fetchSearchedUserPosts} assetClass={AssetClass.Crypto} />
                <AlphaPostsWidget className={`${userProfileContentSelection !== UserProfileContentSelection.BOOKMARKS ? "hidden" : ""} w-full px-10 pt-8`} postsListFetch={searchedUserBookmarksFetch} setPostsListFetch={setSearchedUserBookmarksFetch} fetchAlphaPosts={fetchSearchedUserBookmarks} assetClass={AssetClass.Crypto} />
                {/* <UserProfileShares className={`${userProfileContentSelection !== UserProfileContentSelection.SHARES ? "hidden" : ""}`} searchedUserAccountFetch={searchedUserAccountFetch} /> */}
            </div>}
        </div>
    )
};

export default UserProfileContent;