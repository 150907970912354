interface HistoricalScorePostInfo {
    alphaPostId: string,
    score: number,
    creationTimestamp: number,
}
interface HistoricalScoreData {
    lowest: HistoricalScorePostInfo,
    highest: HistoricalScorePostInfo,
    all: HistoricalScorePostInfo[],
    averages: {
        lifetime: number,
        previousYear: number,
        previousQuarter: number,
        previousMonth: number,
        previousWeek: number,
    },
    mostRecent: HistoricalScorePostInfo,
}
interface TokenTypeUserAlphaInfo {
    numPosts: { total: number, ratingEnabled: number, analyzed: number, deleted: number, edited: number },
    // numComments: number,
    createdPosts: { alphaPostId: string, creationTimestamp: number }[],
    ratings: HistoricalScoreData | null,
    pnl: HistoricalScoreData | null,
    interactions: { mostUpvotes: HistoricalScorePostInfo, mostDownvotes: HistoricalScorePostInfo } | null,
}
export interface UserAuthPlatform {
    name: string;
    id: string;
    pfpUrl: string;
}
export interface UserAccount {
    userAuth: {
        twitter: UserAuthPlatform | null;
        telegram: UserAuthPlatform | null;
    };
    userAlphaInfo: {
        crypto: TokenTypeUserAlphaInfo,
        nft: TokenTypeUserAlphaInfo,
        friendTech: TokenTypeUserAlphaInfo,
        // eliteClubInfo: EliteClubInfo | null,
    },
    userInfo: { username: string, pfpUrl: string };
    userTimestamps: { accountCreationTimestamp: number };
    userId: string;
    linkedUserIds: string[];
}

export interface ConfidentialUserAccount {
    userAlphaInfo: {
        crypto: {
            savedPostIds: string[],
        },
        nft: {
            savedPostIds: string[],
        },
        friendTech: {
            savedPostIds: string[],
        },
        global: {
            savedPostIds: string[],
        }
    }
}

export const DEFAULT_CONFIDENTIAL_ACCOUNT: ConfidentialUserAccount = {
    userAlphaInfo: {
        crypto: {
            savedPostIds: [],
        },
        nft: {
            savedPostIds: [],
        },
        friendTech: {
            savedPostIds: [],
        },
        global: {
            savedPostIds: [],
        }
    }
}

export interface UserOnchainStats {
    wallet: {
        address: string,
        balance: number,
    },
    shares: {
        clubs: { [clubId: string]: number },
    }
}



export interface AlphaPostInfo {
    creation: {
        user: UserAccount["userInfo"] & { userId: string, isEliteCreator: boolean };
        timestamp: number;
        timestampDate: string;
        timestampHour: string;
        fromPlatform: "discord" | "telegram" | "alphaClub" | "discordAlphaClub" | "telegramAlphaClub";
    };
    content: {
        title: string;
        // chain: BoardPostChainInfo;
        // postType: "alpha" | "technicalAnalysis" | "friendTech" | "global";
        prediction: {
            asset: AlphaPostAssetInfo;
            position: "long" | "short";
            timeframe: { start: number, end: number };
            timeframeRange: AlphaPredictionTimeframeRange;
            advancedOptions: {
                entryTarget: number | null;
                exitTargets: number[];
                invalidationPoint: number | null;
            } | null;
        } | null;
        research: {
            content: string;
            media: { type: "image" | "video", url: string, description: string }[];
            chartUrl: string | null;
        };
    },
    interactions: {
        upvote: { accounts: string[] },
        downvote: { accounts: string[] },
        totalVoteScore: number,
    },
    analysis: {
        toBeAnalyzed: boolean;
        result: AlphaPostAnalysis | null,
    },
    id: string
    creatorClubId: string | null; // null means global timeline post
}

export interface AlphaPostAssetInfo {
    info: TokenInfo | CoinInfo | NFTInfo | FtShareInfo;
    assetClass: AssetClass,
    type: "coin" | "token" | "nft" | "ftShare",
    assetId: string, // CMC coin id or tokenAddress:networkId or shareAddress:FT
    priceAtCreation: number,
}
export interface FtShareInfo {
    ftId?: string;
    address: string;
    networkId: "FT",
    symbol: string; // twitterUsername
    twitterName: string;
    iconUrl: string;
    twitterUserId: string;

    metrics?: {
        holderCount: number;
        holdingCount: number;
        shareSupply: number;
        price: number;
    };
}
export interface CoinInfo {
    id: string;
    symbol: string;
    iconUrl: string;
}
export interface TokenInfo {
    name: string;
    symbol: string;
    address: string;
    networkId: number;
    topPoolId: string;
    iconUrl: string;

    metrics?: {
        liquidity: string;
        price: number;
        priceChange: number;
        volume: string;
    };

    chain: {
        slug?: string,
        icon?: string,
    };
}
export interface NFTInfo {
    address: string;
    networkId: number;
    symbol: string;
    chain: {
        slug: string,
        icon: string,
    };
    pricingIcon: string;
    slug: string;
    iconUrl: string;
    bannerUrl: string;
}


interface AlphaPostAnalysis {
    rating: number,
    pnl: number,
    timestamp: number,
}
export enum AlphaPredictionTimeframeRange {
    Scalp = "Scalp", // (Seconds to minutes)
    SwingTrade = "Swing", // (1 day - 1 week)
    ShortTerm = "Short", //  (1 week - 1 month)
    MidTerm = "Mid", // (1 month - 8 months)
    LongTerm = "Long", // (8 months - 2 years)
}

// content: {
//     title: string;
//     // chain: BoardPostChainInfo;
//     postType: "alpha" | "technicalAnalysis" | "friendTech" | "global";
//     prediction: {
//         asset: AlphaPostAssetInfo | null;
//         position: "long" | "short";
//         timeframe: { start: number, end: number };
//         timeframeRange: AlphaPredictionTimeframeRange;
//         advancedOptions: {
//             entryTarget: number;
//             exitTargets: number[];
//             optional: {
//                 invalidationPoint: number | null;
//                 taChartUrl: string | null;
//             }
//         } | null;
//     } | null;
//     research: {
//         content: string;
//         media: { type: "image" | "video", url: string, description: string }[];
//     };
// },
export interface NewAlphaPostContent {
    title: string;
    // chain: BoardPostChainInfo | null;

    // postType: "alpha" | "technicalAnalysis" | "friendTech" | "global";
    prediction: NewAlphaPostContentPrediction | null;

    research: {
        content: string,
        media: { file: File, filePreview: string }[],
        chartUrl: string | null,
    };
}
interface NewAlphaPostContentPrediction {
    asset: AlphaPostAssetInfo | null;
    position: "long" | "short";
    timeframeRange: AlphaPredictionTimeframeRange;
    advancedOptions: NewAlphaPostContentPredictionAdvancedOptions | null;
}
interface NewAlphaPostContentPredictionAdvancedOptions {
    entryTarget: number | undefined;
    exitTargets: number[];
    invalidationPoint: number | undefined;
}

const defaultNewAlphaPostContent: NewAlphaPostContent = {
    title: "",
    prediction: null,

    research: {
        content: "",
        media: [],
        chartUrl: null,
    },
}
const defaultNewAlphaPostContentPrediction: NewAlphaPostContentPrediction = {
    asset: null,
    position: "long",
    timeframeRange: AlphaPredictionTimeframeRange.SwingTrade,
    advancedOptions: null,
}
const defaultNewAlphaPostContentPredictionAdvancedOptions: NewAlphaPostContentPredictionAdvancedOptions = {
    entryTarget: undefined,
    exitTargets: [],
    invalidationPoint: undefined,
}
export const DEFAULT_NEW_ALPHA_POST_FIELDS: { CONTENT: NewAlphaPostContent, CONTENT_PREDICTION: NewAlphaPostContentPrediction, CONTENT_PREDICTION_ADVANCED_OPTIONS: NewAlphaPostContentPredictionAdvancedOptions } = {
    CONTENT: defaultNewAlphaPostContent,
    CONTENT_PREDICTION: defaultNewAlphaPostContentPrediction,
    CONTENT_PREDICTION_ADVANCED_OPTIONS: defaultNewAlphaPostContentPredictionAdvancedOptions,
}


export interface AlphaPostComments {
    comments: AlphaPostComment[],
    total: number,
    alphaPostId: string,
}

export interface AlphaPostComment {
    creation: {
        user: UserAccount["userInfo"] & { isEliteCreator: boolean, userId: string };
        timestamp: number;
        // deletionTimestamp: number | null,
        fromPlatform: "discord" | "telegram" | "alphaClub" | "discordAlphaClub" | "telegramAlphaClub";
    };
    content: {
        text: string | null;
        media: { type: "image" | "video", url: string, description: string }[];
    },
    id: string,
    replyCommentId?: string;
}

export enum PostInteraction {
    Upvote = "upvote",
    Downvote = "downvote",
}

export enum PostsQueryOrder {
    ASCENDING = "asc",
    DESCENDING = "desc",
}

export enum PostsQueryField {
    CreationTime = "creation.timestamp",
    TotalVotesScore = "interactions.totalVoteScore"
}

export interface PostFilters {
    asset: TokenInfo | null,
    position: Position,
    // startDate: number | null,
    // endDate: number | null,
    eliteCreatorOnly: boolean,
    queryField: PostsQueryField | undefined,
    queryOrder: PostsQueryOrder | undefined,
}

export enum Position {
    Long,
    Short,
    LongAndShort
}


// export interface TokenInfo {
// id: string;
// symbol: string;
// iconUrl: string;
// crypto: boolean;
// }

export interface SearchedTokensFetch {
    search: string,
    tokens: TokenInfo[],
    fetching: boolean,
}
export interface SearchedFtCreatorsFetch {
    search: string,
    ftCreators: FtCreator[],
    fetching: boolean,
}

export interface TokenSecurityMetrics {
    isHoneypot: boolean;
    buyTax: number;
    sellTax: number;
    holderCount: number;
    totalSupply: number;

    isWhiteListed: boolean;
    isBlackListed: boolean;
    isAntiWhale: boolean;
    isInDex: boolean;
    isMintable: boolean;
    isOpenSource: boolean;
    isProxy: boolean;

    honeypotWithSameCreator: boolean;
    antiWhaleModifiable: boolean;
    canTakeBackOwnership: boolean;
    cannotBuy: boolean;
    cannotSellAll: boolean;
    slippageModifiable: boolean;
    tradingCooldown: boolean;
    transferPausable: boolean;
    externalCall: boolean;
    personalSlippageModifiable: boolean;
    selfDestruct: boolean;

    creatorInfo: {
        address: string;
        balance: string;
        percent: string;
    };
    ownerInfo: {
        address: string;
        balance: string;
        change_balance: string;
        percent: string;
    } | null;
    hiddenOwner: boolean;

    lpInfo: {
        lpHoldersCount: number;
        topLpHolders: {
            address: string;
            tag: string;
            isContract: boolean;
            balance: string;
            percent: string;
            isLocked: boolean;
            lockedDetail?: {
                amount: number;
                endTime: string;
                optTime: string;
            }[];
        }[]
        lpTotalSupply: number;
    }

    topHolders: {
        address: string;
        tag: string;
        isContract: boolean;
        balance: string;
        percent: string;
        isLocked: boolean;
    }[];

    tokenName: string;
    tokenSymbol: string;
}

export interface TokenPoolStats {
    price: number;
    priceChange24h: number;
    volume24h: number;
    liquidity: number;
    fdv: number;
    marketCap: number;
}

export enum AssetClass {
    Crypto = "crypto",
    NFT = "nft",
    FriendTech = "friendTech",
}

export enum PostCreationError {
    Title = "Provide a title",
    Research = "Write at least a few words",
    Asset = "Select an asset",
    TimeframeStart = "Select a start date",
    TimeframeEnd = "Select an end date",
    EntryTarget = "Provide an entry target",
    ExitTarget = " Provide an exit target",
    HighInvalidationOnLong = "Invalidation can not be higher than entry on long",
    LowInvalidationOnShort = "Invalidation can not be lower than entry on short",
    MissingPostEndPoint = "Missing either invalidation or timeframe",
    TaChartUrl = "Missing or incorrect url",
}

export interface FtCreator {
    address: string;
    twitterName: string;
    twitterPfpUrl: string;
    twitterUserId: string;
    twitterUsername: string;
}

export interface IAlphaClubMarketNews {
    author: {
        name: string
    },
    content: {
        title: string,
        description: string,
        image: string | null
    },
    link: string;
    timestamp: number;
    id: string;
}



export interface CreatorClub {
    id: string,
    info: {
        name: string,
        pfpUrl: string,
    },
    creation: {
        creatorId: string,
        creationTimestamp: number,
    },
    linkedTelegramGroup: {
        id: number,
        inviteLink: string,
    } | null,
    config: {
        clubFee: number,
        clubFeeDistribution: { [userId: string]: number },
        whitelistedAlphaPosters: null | string[] | "all",
    },
    counters: {
        totalAlphaPosts: number,
        totalShareBuys: number,
        totalShareSells: number,
    }
    adminIds: string[],
}
export interface CreatorClubOnchainStats {
    numShares: number;
    numShareholders: number;
    sharePrice: {
        buy: number;
        sell: number;
    };
    clubHype: number;
    creatorClubId: string;
};