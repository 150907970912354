import "./TokenSelectorDropdown.css";
import React, { FC, useEffect, useState, useRef, useMemo } from "react";

import {
    Menu,
    MenuHandler,
    Button,
    MenuList,
    MenuItem,
    Input,
} from "@material-tailwind/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import { supportedTokensArray, suppportedNftProjectsArray } from "../utils/supportedTokens"
import { TokenInfo, AssetClass, SearchedTokensFetch, SearchedFtCreatorsFetch, AlphaPostAssetInfo, FtCreator, FtShareInfo } from "../utils/types";
import { DEFINED_TOKEN_ICON_URL, getAssetId, randomGradientImg } from "../utils/utils";
import { CHAIN_SLUGS } from "../utils/constants";
import api from "../utils/api/api";

import { ReactComponent as CaretUp } from "../media/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../media/icons/CaretDown.svg";
import { ReactComponent as MagnifyinGlass } from "../media/icons/MagnifyinGlass.svg";
import ethereumTokenIcon from "../media/icons/ethereumToken.png";
import friendTechIcon from "../media/icons/friendTechIcon.png";

const DEFAULT_SEARCHED_TOKENS_FETCH = { search: "", tokens: [], fetching: false };
const DEFAULT_SEARCHED_FT_CREATORS_FETCH = { search: "", ftCreators: [], fetching: false };

const TokenSelectorDropdown: FC<any> = (props) => {
    const { selectedAsset, setSelectedAsset }: { selectedAsset: AlphaPostAssetInfo | undefined, setSelectedAsset: Function } = props;

    const [menuOpened, setMenuOpened] = useState(false);
    const [searchedAssetClass, setSearchedAssetClass] = useState<AssetClass>(AssetClass.Crypto);
    const [search, setSearch] = useState("");
    const [searchedTokensFetch, setSearchedTokensFetch] = useState<SearchedTokensFetch>(DEFAULT_SEARCHED_TOKENS_FETCH);
    const [searchedFtCreatorsFetch, setSearchedFtCreatorsFetch] = useState<SearchedFtCreatorsFetch>(DEFAULT_SEARCHED_FT_CREATORS_FETCH);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(search)
            searchAssets()
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search, searchedAssetClass])

    async function searchAssets() {
        if (searchedAssetClass === AssetClass.Crypto) searchForTokens();
        else searchForFtCreators();
    }

    async function searchForTokens() {
        setSearchedFtCreatorsFetch(DEFAULT_SEARCHED_FT_CREATORS_FETCH)
        if (!search.length) {
            setSearchedTokensFetch({ ...searchedTokensFetch, tokens: [] })
            return;
        }
        setSearchedTokensFetch({ ...searchedTokensFetch, fetching: true })
        const searchTokensResult = await api.defi.searchToken(search, 10);
        const searchedTokens: TokenInfo[] = []
        for (const tokenResult of searchTokensResult) {
            searchedTokens.push({
                name: tokenResult.name,
                symbol: tokenResult.symbol,
                address: tokenResult.address,
                networkId: tokenResult.networkId,
                topPoolId: tokenResult.topPairId.split(":")[0],
                iconUrl: DEFINED_TOKEN_ICON_URL(tokenResult),

                metrics: {
                    liquidity: tokenResult.liquidity,
                    price: tokenResult.price,
                    priceChange: tokenResult.priceChange * 100,
                    volume: tokenResult.volume,
                },

                chain: { slug: CHAIN_SLUGS[tokenResult.networkId], icon: `https://icons.llamao.fi/icons/chains/rsz_${CHAIN_SLUGS[tokenResult.networkId]}.jpg` }
            })
        }
        console.log(searchedTokens)
        setSearchedTokensFetch({ search, tokens: searchedTokens, fetching: false })
    }

    async function searchForFtCreators() {
        setSearchedTokensFetch(DEFAULT_SEARCHED_TOKENS_FETCH)
        if (!search.length) {
            setSearchedFtCreatorsFetch({ ...searchedFtCreatorsFetch, ftCreators: [] })
            return;
        }
        setSearchedFtCreatorsFetch({ ...searchedFtCreatorsFetch, fetching: true })
        const searchFtCreatorsResult = await api.onchain.getFtCreators(search);
        console.log(searchFtCreatorsResult)
        setSearchedFtCreatorsFetch({ search: search, ftCreators: searchFtCreatorsResult, fetching: false })
    }

    function selectToken(tokenInfo: TokenInfo) {
        const assetInfo: AlphaPostAssetInfo = { info: tokenInfo, assetClass: AssetClass.Crypto, assetId: getAssetId(tokenInfo), priceAtCreation: 0, type: "token" }
        setSelectedAsset(assetInfo);
    }
    function selectFtCreator(ftCreator: FtCreator) {
        const ftShareInfo: FtShareInfo = {
            address: ftCreator.address,
            networkId: "FT",
            symbol: ftCreator.twitterUsername,
            twitterName: ftCreator.twitterName,
            iconUrl: ftCreator.twitterPfpUrl,
            twitterUserId: ftCreator.twitterUserId,
        };
        const assetInfo: AlphaPostAssetInfo = { info: { ...ftShareInfo }, assetClass: AssetClass.FriendTech, assetId: getAssetId(ftShareInfo), priceAtCreation: 0, type: "ftShare" };
        setSelectedAsset(assetInfo);
    }


    return (
        <Menu
            placement="bottom-start"
            dismiss={{
                itemPress: false,
            }}
            open={menuOpened}
            handler={() => setMenuOpened(!menuOpened)}
        >
            <MenuHandler>
                <Button className="flex items-center bg-neutral-800">{!selectedAsset ? `Select Token` : <><img src={selectedAsset.info.iconUrl} onError={({ currentTarget }) => {
                    (currentTarget as HTMLImageElement).onerror = null;
                    (currentTarget as HTMLImageElement).src = randomGradientImg();
                }} className="token-icon me-2" /> {(selectedAsset.info as any).name ?? selectedAsset.info.symbol}</>}</Button>
            </MenuHandler>
            <MenuList className="token-selector-dropdown-content z-[9999]">
                <div className="flex justify-end items-center mb-1">
                    <div className="flex ms-2">
                        <Button className={`flex justify-center items-center w-[40px] h-[30px] px-3 py-1 rounded-r-none ${searchedAssetClass === AssetClass.Crypto ? "" : "bg-neutral-500"}`} onClick={() => setSearchedAssetClass(AssetClass.Crypto)}><img src={ethereumTokenIcon} className="w-full h-auto" /></Button>
                        <Button className={`flex justify-center items-center w-[40px] h-[30px] px-3 py-1 rounded-l-none ${searchedAssetClass === AssetClass.FriendTech ? "" : "bg-neutral-500"}`} onClick={() => setSearchedAssetClass(AssetClass.FriendTech)}><img src={friendTechIcon} className="w-full h-auto" /></Button>
                    </div>
                </div>
                <Input
                    label="Search"
                    containerProps={{
                        className: "mb-4",
                    }}
                    labelProps={{
                        className: "peer-placeholder-shown:text-black"
                    }}
                    value={search}
                    color="black"
                    onChange={(e) => setSearch(e.target.value)}
                    className="select-token-dropdown-filter px-3 mb-1 w-full"
                />

                <div className="scrollable-dropdown-menu max-h-[300px] max-w-[300px] min-w-[300px] overflow-y-scroll overflow-x-hidden focus-visible:outline-none" >
                    {searchedAssetClass === AssetClass.Crypto ?
                        searchedTokensFetch.fetching ? "Loading" :
                            searchedTokensFetch.tokens.map((tokenInfo, index) => {
                                return (
                                    <MenuItem key={index} onClick={() => { selectToken(tokenInfo); setMenuOpened(false) }}>
                                        <div className="flex items-center">
                                            <LazyLoadImage src={tokenInfo.iconUrl} onError={({ currentTarget }) => {
                                                (currentTarget as HTMLImageElement).onerror = null;
                                                (currentTarget as HTMLImageElement).src = randomGradientImg();
                                            }} className="token-icon me-2" />
                                            <div className="flex justify-start items-center w-full">
                                                <div className="token-symbol w-20">{tokenInfo.symbol}</div>
                                                {tokenInfo.chain.icon ? <LazyLoadImage src={tokenInfo.chain.icon} onError={({ currentTarget }) => {
                                                    (currentTarget as HTMLImageElement).onerror = null;
                                                    (currentTarget as HTMLImageElement).src = randomGradientImg();
                                                }} className="token-icon me-2" /> : ""}
                                                <div className="token-stats flex ms-auto">
                                                    <div className="token-price me-2">${tokenInfo.metrics?.price?.toFixed(2) ?? "-"}</div>
                                                    {tokenInfo.metrics?.priceChange !== undefined ? <div className={`token-price-change ${tokenInfo.metrics?.priceChange >= 0 ? "text-selection" : "text-error"}`}>
                                                        {tokenInfo.metrics?.priceChange > 0 ? <CaretUp /> : <CaretDown />}
                                                        <span className="ms-1">{Math.abs(tokenInfo.metrics?.priceChange).toFixed(2)}%</span>
                                                    </div> : "-"}
                                                </div>
                                            </div>
                                        </div>
                                    </MenuItem>
                                )
                            }) : ""
                    }
                    {searchedAssetClass === AssetClass.FriendTech ?
                        searchedFtCreatorsFetch.fetching ? "Loading" :
                            searchedFtCreatorsFetch.ftCreators.map((ftCreator, index) => {
                                return (
                                    <MenuItem key={index} onClick={() => { selectFtCreator(ftCreator); setMenuOpened(false) }}>
                                        <div className="flex items-center">
                                            <LazyLoadImage src={ftCreator.twitterPfpUrl} onError={({ currentTarget }) => {
                                                (currentTarget as HTMLImageElement).onerror = null;
                                                (currentTarget as HTMLImageElement).src = randomGradientImg();
                                            }} className="pfp-icon me-2" />
                                            <div className="flex flex-col w-full">
                                                <h6 className="text-normal">@{ftCreator.twitterUsername}</h6>
                                                <p className="text-normal ms-2 text-gray-500">{ftCreator.twitterName}</p>
                                            </div>
                                        </div>
                                    </MenuItem>
                                )
                            }) : ""
                    }
                </div>
            </MenuList>
        </Menu>
    )
}

export default TokenSelectorDropdown;
