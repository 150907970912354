import { createContext, useContext } from "react";

import { UserAccount, UserOnchainStats, CreatorClub, CreatorClubOnchainStats } from "../../utils/types";

interface DataCacheContextState {
    fetchUser: (userId: string, includeOnchainStats: boolean) => Promise<{ userAccount: UserAccount, onchainStats: UserOnchainStats | null } | null>,
    cacheUser: (userAccount: UserAccount) => void,
    fetchCreatorClub: (creatorClubId: string, includeOnchainStats: boolean) => Promise<{ creatorClub: CreatorClub, creatorClubOnchainStats: CreatorClubOnchainStats | null } | null>,
    cacheCreatorClub: (creatorClub: CreatorClub) => void,
}

const DEFAULT_DATA_CACHE_CONTEXT: DataCacheContextState = {
    fetchUser: () => new Promise((() => null)),
    cacheUser: () => null,
    fetchCreatorClub: () => new Promise((() => null)),
    cacheCreatorClub: () => null,
}

export const DataCacheContext = createContext<DataCacheContextState>(DEFAULT_DATA_CACHE_CONTEXT)

export function useDataCache(): DataCacheContextState {
    return useContext(DataCacheContext);
}
