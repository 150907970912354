import { createContext, useContext } from "react";

import { UserAccount, ConfidentialUserAccount, UserOnchainStats } from "../../utils/types";

interface UserContextState {
    loggingIn: boolean,
    currentUser: (UserAccount & { confidential: ConfidentialUserAccount, onchainStats: UserOnchainStats }) | null,
    setCurrentUser: any,
    login: Function,
    logout: Function,
    connectionDialogOpen: boolean,
    setConnectionDialogOpen: Function,
}

const DEFAULT_USER_CONTEXT: UserContextState = {
    loggingIn: false,
    currentUser: null,
    setCurrentUser: null,
    login: () => null,
    logout: () => null,
    connectionDialogOpen: false,
    setConnectionDialogOpen: () => null,
}

export const UserContext = createContext<UserContextState>(DEFAULT_USER_CONTEXT)

export function useUser(): UserContextState {
    return useContext(UserContext);
}
