import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import coinTelegraphLogo from "../../../media/icons/coinTelegraph.webp"

import { timestampToRelative } from "../../../utils/utils"
import api from "../../../utils/api/api";
import firebaseHelpers from "../../../utils/firebaseHelpers";

interface IAlphaClubNews {
    author: {
        name: string
    },
    content: {
        title: string,
        description: string,
        image: string | null
    },
    link: string;
    timestamp: number;
    id: string;
}


interface INewsFeedFetch {
    recent: {
        feed: IAlphaClubNews[];
        fetching: boolean;
        currentError: string | undefined;
        fullyLoaded: boolean;
    };
    top: {
        feed: IAlphaClubNews[];
        fetching: boolean;
        currentError: string | undefined;
        fullyLoaded: boolean;
    };
}
const MARKET_NEWS_QUERY_LENGTH = 200;
const NewsFeedWidget: FC<any> = (props) => {
    const { className }: { className: string } = props;

    const [newsFeedOrder, setNewsFeedOrder] = useState<"recent" | "top">("recent");
    const [newsFeedFetch, setNewsFeedFetch] = useState<INewsFeedFetch>({ recent: { feed: [], fetching: false, currentError: undefined, fullyLoaded: false }, top: { feed: [], fetching: false, currentError: undefined, fullyLoaded: false } });

    useEffect(() => {
        if (newsFeedFetch[newsFeedOrder].feed.length === 0) fetchNewsFeed();
    }, [newsFeedOrder]);

    async function fetchNewsFeed() {
        if (newsFeedFetch[newsFeedOrder].fetching) return;
        setNewsFeedFetch({ ...newsFeedFetch, [newsFeedOrder]: { ...newsFeedFetch[newsFeedOrder], fetching: true } });
        const result = await firebaseHelpers.marketNews.getMarketNews(MARKET_NEWS_QUERY_LENGTH);
        const updatedNewsFeedFetch = { ...newsFeedFetch, fetching: false };
        if (result.success) {
            updatedNewsFeedFetch[newsFeedOrder].feed = [...updatedNewsFeedFetch[newsFeedOrder].feed, ...result.marketNews!];
            if (result.marketNews!.length < MARKET_NEWS_QUERY_LENGTH) updatedNewsFeedFetch[newsFeedOrder].fullyLoaded = true;
        } else {
            updatedNewsFeedFetch[newsFeedOrder].currentError = result.error ?? "An error occurred. Try again.";
        }
        setNewsFeedFetch(updatedNewsFeedFetch);
    }

    const newsContainer = (news: IAlphaClubNews | undefined, key: string) => <a href={news?.link} target="_blank" rel="noopener noreferrer" key={key} className="news-feed-widget-body-post flex flex-col relative height-auto box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 transition-transform-background motion-reduce:transition-none group max-w-[400px] overflow-visible rounded-[14px] bg-white/5 text-gray-200 shadow-xl hover:border-white dark:bg-dark" tabIndex={-1}>
        <div className="p-3 z-10 w-full justify-start items-center shrink-0 overflow-inherit color-inherit subpixel-antialiased rounded-t-large flex gap-3">
            {news === undefined ? <div className="loading-square !rounded-md !w-[40px] !h-[40px] !mx-0"></div> : <img alt="news" width="40" height="40" className="rounded-md" src={coinTelegraphLogo} />}
            <div className="flex flex-col gap-1">
                {news === undefined ? <div className="loading-square !w-[115px] !h-[20px]"></div> : <p className="text-base">CoinTelegraph</p>}
                {news === undefined ? <div className="loading-square !w-[115px] !h-[16px]"></div> : <p className="text-sm text-stone-500">cointelegraph.com</p>}
            </div>
            <div className=" absolute right-2 top-2 translate-x-4 p-2 opacity-0 transition-all duration-200 ease-in-out group-hover:translate-x-0 group-hover:opacity-100">
                <svg className="text-gray-400" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none" stroke="none"></path><path d="M17 7l-10 10"></path><path d="M8 7l9 0l0 9"></path></svg>
            </div>
        </div>
        <hr className="h-[1px] w-full shrink-0 border-none bg-white/25 dark:bg-[#29292C]" role="separator" />
        <div className="relative flex w-full p-5 flex-auto flex-col place-content-inherit align-items-inherit h-auto break-words text-left overflow-y-auto subpixel-antialiased">
            {news === undefined ? <><div className="loading-square !w-full !h-[20px]"></div><div className="loading-square !w-[80%] !h-[20px] !mt-1 !mx-0"></div></> : <p>{news?.content.title}</p>}
        </div>
        <hr className="h-[1px] w-full shrink-0 border-none bg-white/25 dark:bg-[#29292C]" role="separator" />
        <div className="p-3 h-auto flex w-full items-center overflow-hidden color-inherit subpixel-antialiased rounded-b-large text-stone-600">
            {news === undefined ? <div className="loading-square !w-[115px] !h-[18px] !mx-0"></div> : <p>{news && timestampToRelative(news.timestamp)}</p>}
        </div>
    </a>

    {/* <div className="news-feed-widget-body-post-author text-primary">
            {news ? <h6>{news.author.name}</h6> : <div className="loading-square ms-0 w-[120px] h-[25px]"></div>}
        </div>
        {news ? <a href={news.link} target="_blank" rel="noopener noreferrer" className="news-feed-widget-body-post-content flex flex-col text-white no-underline transition-colors duration-200 hover:text-light-first">
            <h6 className="mb-1">{news.content.title}</h6>
            {news.content.image !== null ? <img src={news.content.image} alt="Post Image" /> : ""}
            <p className="mt-1 mb-0">{news.content.description}</p>
            <span className="text-end">{timestampToRelative(news.timestamp)}</span>
        </a> : <div className="flex flex-col mt-3">
            <div className="loading-square w-full mb-1"></div>
            <div className="loading-square w-full h-[180px]"></div>
            <div className="flex flex-col">
                <div className="loading-square mt-2 ms-0 w-[90%]"></div>
                <div className="loading-square w-full mt-1"></div>
                <div className="loading-square mt-1 ms-0 w-[85%]"></div>
            </div>
            <div className="loading-square mt-2 me-0 w-[100px] h-[25px]"></div>
        </div>} */}


    return (
        <div id="news-feed-widget" className={`no-scrollbar flex h-2/3 flex-col gap-y-4`}>
            <div id="news-feed-widget-header" className="flex flex-col justify-between text-center z-10">
                <div id="news-feed-widget-header-title" className="flex justify-center text-header py-2">
                    <h3 className="text-header header-gradient font-bold">NEWS FEED</h3>
                </div>
            </div>
            <div id="news-feed-widget-body-posts" className="no-scrollbar flex flex-col gap-y-4 overflow-y-scroll">
                {newsFeedFetch[newsFeedOrder].feed.map((news: IAlphaClubNews) => newsContainer(news, news.id))}
                {newsFeedFetch[newsFeedOrder].fetching ? Array(3).fill(0).map((_, index) => newsContainer(undefined, index.toString())) : ""}
            </div>
        </div>
    )
};

export default NewsFeedWidget;