import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";

import alphaClubLogo from "../../../media/icons/alphaClubLogo.png";

const UserProfileStats: FC<any> = (props) => {
    const { className } = props;

    return (
        <div id="user-profile-stats" className={`flex flex-col items-center justify-center w-full pb-24 ${className}`}>
            <img src={alphaClubLogo} className="w-3/5 max-w-[300px] h-auto" />
            <h2 className="text-header header-gradient font-bold mt-5">User Stats Coming Soon</h2>
        </div>
    )
};

export default UserProfileStats;