import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { Player } from '@lottiefiles/react-lottie-player';

import AlphaClubLogo from "../media/icons/alphaClubLogo.png"
// import { ReactComponent as Logo } from "../media/icons/logo.svg";
import alphaLoadingAnimation from "../media/videos/alphaLoading.json";
import { useUser } from "./Providers/useUser";

const TelegramConnectionLoader: FC<any> = (props) => {
    const { telegramDataToValidate, locationPaths } = props;

    const history = useHistory();
    const { login, loggingIn, logout, currentUser } = useUser();

    const [isLoginFailed, setIsLoginFailed] = useState(false);

    useEffect(() => {
        initiateTelegramConnection()
    }, [telegramDataToValidate]);

    useEffect(() => {
        if (currentUser !== null) removeTelegramFromNavigation();
    }, [currentUser])

    async function initiateTelegramConnection() {
        if (telegramDataToValidate === undefined || loggingIn || currentUser !== null || isLoginFailed) return;
        logout();
        setIsLoginFailed(false);
        const success = await login("telegram", telegramDataToValidate);
        setIsLoginFailed(!success)
        if (success) {
            removeTelegramFromNavigation()
        }
    }

    function removeTelegramFromNavigation() {
        history.push(`/${locationPaths.filter((locationPath: string) => locationPath !== "telegram").join("/")}${window.location.search.split("id=")[0]}`);
    }

    const animationRef = useRef<any>(null);
    function stopAnimation() {
        animationRef.current.stop();
    }

    return (
        <div id="telegram-connection-loader" className="fixed flex flex-col justify-center items-center inset-0 w-full h-full bg-neutral-900 text-white z-[100]">
            {/* <img src={AlphaClubLogo} className="w-[125px] h-[auto]" /> */}
            <Player
                onEvent={event => {
                    if (event === 'loop' && !loggingIn) stopAnimation();
                }}
                ref={animationRef}
                autoplay
                loop
                keepLastFrame={true}
                src={alphaLoadingAnimation}
                className="w-[125px] h-[auto]"
            >
            </Player>
            <h4 className="text-header header-gradient uppercase font-bold mt-2">Primordium Alpha Club</h4>
            {loggingIn && <h6 className="text-normal-base-size mt-2">Connecting with Telegram...</h6>}
            {!loggingIn && isLoginFailed && <h6 className="text-normal text-error text-center mt-4">Failed to login with Telegram.<br />Close the tab and try again.</h6>}
        </div>
    )
}

export default TelegramConnectionLoader;