import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory, useLocation, Link } from 'react-router-dom';

import ConnectionButton from "./Connection/ConnectionButton"

import AlphaClubLogo from "../media/icons/alphaClubLogo.png"
import { ReactComponent as UnibotIcon } from "../media/icons/unibotIcon.svg"
import { ReactComponent as BurgerIcon } from "../media/icons/burgerIcon.svg"
import CreateAlphaPostButton from "../Pages/AlphaClub/Components/CreateAlphaPostButton";

import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
} from "@material-tailwind/react";

import { useMediaQuery } from "react-responsive";

enum Page {
    BigBoard = "big-board",
    Profile = "profile",
}
const pathToPage: { [path: string]: Page } = {
    "big-board": Page.BigBoard,
    "profile": Page.Profile,
}

const Navbar: FC<any> = (props) => {
    const { className, transparent, isTelegramWebApp, tokenType } = props

    const location = useLocation()
    const [currentPage, setCurrentPage] = useState<Page | null>(null) // BigBoard & eliteMembership

    useEffect(() => {
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        const basePath: string = paths[0];
        setCurrentPage(pathToPage[basePath] ?? null)

    }, [location.pathname])

    // useEffect(() => {
    //     gsap.registerPlugin(ScrollTrigger)
    //     animateNavbar()
    // }, [])

    // function animateNavbar() {
    //     const navbarTimeline = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: "#app-content",
    //             start: "top top",
    //             end: "top top+=500px",
    //             scrub: true,
    //             onLeave: () => gsap.set("#big-board-nav>div", { boxShadow: "0px 11px 31px 0px rgba(0,0,0,0.75)" }),
    //             onEnterBack: () => gsap.set("#big-board-nav>div", { boxShadow: "none" }),
    //         }
    //     });

    //     navbarTimeline.to("#big-board-nav", { background: "rgba(0,0,0,1)", paddingTop: "5px", paddingBottom: "5px" })
    // }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1140px)' })

    return (
        <div id="big-board-nav" className={`col-span-12 flex items-center xl:items-stretch shadow-md bg-[#131217] border-b border-b-white/25 xl:flex-col xl:max-w-lg xl:border-r xl:border-r-white/25 xl:col-span-2 xl:pb-8`}>
            <div className="flex px-5 py-6">
                <Link to="/" className="flex items-center"><img src={AlphaClubLogo} className="w-auto h-[32px]" /><h1 className="text-gradient-aqua ml-2 text-2xl font-bold">AlphaClub</h1></Link>
            </div>
            {
                !isTabletOrMobile ? <>
                    <div className="flex-1 px-8 py-5">
                        <div className="flex-1 flex flex-col gap-4">
                            <Link className={"menu-item group rounded-lg p-4 hover:bg-gray-600/10 " + (currentPage === Page.BigBoard ? "bg-gray-600/10" : "")} to="/big-board"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path></svg> <span className="text-lg capitalize">home</span></Link>
                            {/* <a className="menu-item group rounded-lg p-4 hover:bg-gray-600/10" href="/news"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"></path><path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"></path></svg> <span className="text-lg capitalize">news</span></a> */}

                            <Link className="menu-item group rounded-lg p-4 opacity-30 hover:bg-gray-600/10 pointer-events-none" aria-disabled to="/profile"><svg className="h-5 w-5 text-white" fill="none" height="1em" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" x2="16.65" y1="21" y2="16.65"></line></svg> <span className="text-lg capitalize">explore</span></Link>
                            <Link className="menu-item group rounded-lg p-4 opacity-30 hover:bg-gray-600/10 pointer-events-none" aria-disabled to="/profile"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg> <span className="text-lg capitalize">trending</span></Link>
                            <Link className={"menu-item group rounded-lg p-4 hover:bg-gray-600/10 " + (currentPage === Page.Profile ? "bg-gray-600/10" : "")} to="/profile"><svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z" fill="currentColor"></path></svg> <span className="text-lg capitalize">profile</span></Link>
                        </div>
                    </div>
                    <div className="px-8">
                        <CreateAlphaPostButton />
                    </div>
                    <hr className="mx-8 my-4 text-black opacity-25"></hr>
                    <footer className="px-8 flex flex-col justify-center">
                        <div className="text-neutral-200 mt-2 mb-1 text-xs">Built by Primordium Labs</div>
                        <a href="https://unibot.app" target="_blank" rel="noopener noreferrer" className="flex text-purple-500 mb-2 text-xs">Powered by <UnibotIcon className="w-[15px h-[15px] ms-2 me-1" /> Unibot</a>
                        <div className="mt-2 flex items-center">
                            <a className="text-white" href="https://t.me/alpha_primordium_bot" rel="noopener noreferrer" target="_blank">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"></path></svg>
                            </a>
                            <div className="mx-2"></div>
                            <a className="text-white" href="https://twitter.com/PrimordiumBot" rel="noopener noreferrer" target="_blank">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                            </a>
                        </div>
                    </footer>
                </> : <Menu
                    placement="bottom-start"
                    dismiss={{
                        itemPress: false,
                    }}
                >
                    <MenuHandler>
                        {/* <button className={`connection-button min-w-[150px] flex items-center bg-transparent text-white border border-white rounded-lg transition-all duration-300 hover:opacity-80 hover:text-black hover:bg-white ${loggingIn ? "loading opacity-80 text-black bg-white" : ""} ${className ?? ""} p-2`}>
                            {
                                loggingIn ? <img src={loadingIconAnim} className="loading-icon-anim w-[20px] h-auto m-auto" />
                                    : currentUser !== null ? <div className="flex items-center justify-center text-normal text-sm md:text-base font-bold w-full px-2">{currentUser.userAuth.twitter ? <TwitterXIcon className="h-[15px] md:h-[20px] w-auto me-4" /> : <TelegramIcon className="h-[15px] md:h-[20px] w-auto me-4" />} {currentUser.userInfo.username}</div>
                                        : <div className="text-normal text-sm md:text-base font-bold w-full">CONNECT</div>
                            }
                        </button> */}
                        <Button className="bg-transparent p-3 h-fit ms-auto me-6"><BurgerIcon /></Button>
                    </MenuHandler>
                    {/* <PopoverContent className="flex flex-col bg-neutral-900 outline-none !border !border-neutral-800 shadow-black p-2" style={{ border: "inherit" }}>
                        <div className="flex w-full">
                            <Button className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Token ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Token)}>
                                <p className="text-sm">{SearchType.Token.valueOf()}</p>
                                <p className="flex items-center justify-between w-full text-xs text-gray-300">Find posts related to a singular asset. {selectedOption === SearchType.Token ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
                            </Button>
                        </div>
                        <div className="flex w-full">
                            <Button className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Author ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Author)}>
                                <p className="text-sm">{SearchType.Author.valueOf()}</p>
                                <p className="flex items-center justify-between w-full text-xs text-gray-300">Finds content written by a specific author. {selectedOption === SearchType.Author ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
                            </Button>
                        </div>
                    </PopoverContent> */}
                    <MenuList className="bg-neutral-900 text-white border border-neutral-800 shadow-neutral-950 shadow-md z-[1100]">
                        <MenuItem className={"rounded-lg px-4 py-2 hover:!bg-gray-600/10 " + (currentPage === Page.BigBoard ? "bg-gray-600/10" : "")}>
                            <Link to="/big-board" className="menu-item group">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path></svg> <span className="text-lg capitalize">home</span>
                            </Link>
                        </MenuItem>
                        {/* <MenuItem className="menu-item group rounded-lg p-4 hover:bg-gray-600/10" href="/news"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"></path><path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"></path></svg> <span className="text-lg capitalize">news</span
                        ></MenuItem> */}
                        <MenuItem className={"rounded-lg px-4 py-2 opacity-30 hover:!bg-gray-600/10 pointer-events-none " + (currentPage === Page.Profile ? "bg-gray-600/10" : "")} aria-disabled>
                            <Link to="/profile" className="menu-item group">
                                <svg className="h-5 w-5 text-white" fill="none" height="1em" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" x2="16.65" y1="21" y2="16.65"></line></svg> <span className="text-lg capitalize">explore</span>
                            </Link>
                        </MenuItem>
                        <MenuItem className={"rounded-lg px-4 py-2 opacity-30 hover:!bg-gray-600/10 pointer-events-none " + (currentPage === Page.Profile ? "bg-gray-600/10" : "")} aria-disabled>
                            <Link to="/profile" className="menu-item group">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg> <span className="text-lg capitalize">trending</span>
                            </Link>
                        </MenuItem>
                        <MenuItem className={"rounded-lg px-4 py-2 hover:!bg-gray-600/10 " + (currentPage === Page.Profile ? "bg-gray-600/10" : "")}>
                            <Link to="/profile" className="menu-item group">
                                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" className="h-5 w-5 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3
                        12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z" fill="currentColor"></path></svg> <span className="text-lg capitalize">profile</span>
                            </Link>
                        </MenuItem>
                        <ConnectionButton className="mt-3" customHandler={false} />

                    </MenuList>
                </Menu >
            }

        </div>
    )
}

export default Navbar;
