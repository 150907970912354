import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { CreatorClubOnchainStats, UserAccount } from "../../utils/types";
import { useUser } from "../../Components/Providers/useUser";
import api from "../../utils/api/api";
import UserProfileInfo from "./Components/UserProfileInfo";
import UserProfileContent from "./Components/UserProfileContent";
import { delay } from "../../utils/utils";
import { SearchedUserAccountFetch, CreatorClubFetch } from "./types";
import firebaseHelpers from "../../utils/firebaseHelpers";
import { useDataCache } from "../../Components/Providers/useDataCache";

const AUTH_PLATFORMS: { [key: string]: string } = {
    "x": "twitter",
    "t": "telegram",
    "a": "alphaClub",
};

const UserProfile: FC<any> = (props) => {
    const { locationPaths } = props;

    const history = useHistory();
    const { currentUser, loggingIn } = useUser();
    const { cacheUser, fetchUser, fetchCreatorClub, cacheCreatorClub } = useDataCache();

    const [searchedUserIsConnectedUser, setSearchedUserIsConnectedUser] = useState<boolean>(false);
    const [searchedUserAccountFetch, setSearchedUserAccountFetch] = useState<SearchedUserAccountFetch>({ search: null, userAccount: null, fetching: false });

    useEffect(() => {
        const searchedUserAuthPlatform = locationPaths[1];
        const searchedUserUsername = locationPaths[2];

        if (searchedUserAuthPlatform === undefined || searchedUserUsername === undefined) {
            if (currentUser) {
                const currentUserAuthPlatform = currentUser.userAuth.twitter ? "x" : "t";
                const currentUserUsername = currentUser.userInfo.username;
                window.history.replaceState(null, "New Page Title", `/profile/${currentUserAuthPlatform}/${currentUserUsername}`) // history.push(`/profile/a/${currentUser.userId}`)
                const authPlatform = AUTH_PLATFORMS[currentUserAuthPlatform as keyof typeof AUTH_PLATFORMS]
                setSearchedUserAccountFetch({ ...searchedUserAccountFetch, search: { authPlatform, username: currentUserUsername } });
                console.log(authPlatform, currentUserUsername)
            }
            // currentUser && window.history.replaceState(null, "New Page Title", `/profile/${currentUser.userAuth.twitter ? "x" : "t"}/${currentUser.userInfo.username}`) // history.push(`/profile/a/${currentUser.userId}`)
            return;
        };
        const authPlatform = AUTH_PLATFORMS[searchedUserAuthPlatform as keyof typeof AUTH_PLATFORMS];
        console.log(authPlatform, searchedUserUsername)
        setSearchedUserAccountFetch({ ...searchedUserAccountFetch, search: { authPlatform, username: searchedUserUsername, userId: authPlatform === "alphaClub" ? searchedUserUsername : undefined } });
    }, [locationPaths])

    useEffect(() => {
        // console.log(searchedUserAccountFetch.search?.username)
        if (searchedUserAccountFetch.search === null) return;
        setSearchedUserIsConnectedUser(
            currentUser !== null &&
            searchedUserAccountFetch.search !== null &&
            getUsersSearchedPlatformName(currentUser) === searchedUserAccountFetch.search.username
        )
    }, [searchedUserAccountFetch.search, currentUser])

    useEffect(() => {
        console.log(searchedUserAccountFetch.search)
        fetchSearchedUser();
    }, [searchedUserAccountFetch.search])

    async function fetchSearchedUser() {
        if (searchedUserAccountFetch.search === null || (searchedUserAccountFetch.userAccount && searchedUserAccountFetch.search.username === getUsersSearchedPlatformName(searchedUserAccountFetch.userAccount))) return;
        setSearchedUserAccountFetch({ ...searchedUserAccountFetch, fetching: true });
        // const searchUserAccountResponse = await api.users.searchUserAccount(searchedUserAccountFetch.search.username, searchedUserAccountFetch.search.authPlatform, searchedUserAccountFetch.search.userId);
        const searchedUserAccount = await firebaseHelpers.users.searchUserInfoByPlatform(searchedUserAccountFetch.search.username, searchedUserAccountFetch.search.authPlatform);
        if (searchedUserAccount) {
            setSearchedUserAccountFetch({ ...searchedUserAccountFetch, userAccount: searchedUserAccount, fetching: false });
            cacheUser(searchedUserAccount);
            if (searchedUserAccountFetch.search.authPlatform === "alphaClub") {
                window.history.replaceState(null, "New Page Title", `/profile/${searchedUserAccount.userAuth.twitter ? "x" : "t"}/${searchedUserAccount.userInfo.username}`)
                // history.push(`/profile/${searchedUserAccount.userAuth.twitter ? "x" : "t"}/${searchedUserAccount.userInfo.username}`);
            }
        } else {
            setSearchedUserAccountFetch({ ...searchedUserAccountFetch, userAccount: null, fetching: false });
        }
    }

    function getUsersSearchedPlatformName(user: UserAccount) {
        if (!searchedUserAccountFetch.search) return null;
        if (searchedUserAccountFetch.search.authPlatform === "alphaClub") return user.userInfo.username;
        else return (user.userAuth as any)[searchedUserAccountFetch.search.authPlatform]?.name;
    }


    // Creator Clubs
    // const [userCreatedClubFetch, setUserCreatedClubFetch] = useState<CreatorClubFetch>({
    //     fetchStatus: "idle", creatorClub: null, creatorClubOnchainStatsFetch: {
    //         fetchStatus: "idle",
    //         creatorClubOnchainStats: null,
    //     }
    // });

    // useEffect(() => {
    //     fetchUserClub();
    // }, [searchedUserAccountFetch.userAccount])

    // async function fetchUserClub() {
    //     if (!searchedUserAccountFetch.userAccount) return setUserCreatedClubFetch({ fetchStatus: "idle", creatorClub: null, creatorClubOnchainStatsFetch: { fetchStatus: "idle", creatorClubOnchainStats: null } });
    //     setUserCreatedClubFetch({ ...userCreatedClubFetch, fetchStatus: "fetching" });
    //     const userCreatedClubsResponse = await firebaseHelpers.creatorClub.getUserCreatedClubs(searchedUserAccountFetch.userAccount.userId);
    //     if (userCreatedClubsResponse.success) {
    //         const creatorClub = userCreatedClubsResponse.creatorClubs ? (userCreatedClubsResponse.creatorClubs[0] ?? null) : null;
    //         if (creatorClub) cacheCreatorClub(creatorClub);
    //         setUserCreatedClubFetch({ creatorClub, fetchStatus: "success", creatorClubOnchainStatsFetch: { fetchStatus: "idle", creatorClubOnchainStats: null } });
    //     } else {
    //         setUserCreatedClubFetch({ creatorClub: null, fetchStatus: "error", creatorClubOnchainStatsFetch: { fetchStatus: "idle", creatorClubOnchainStats: null } });
    //     }
    // }

    // useEffect(() => {
    //     fetchUserClubOnchainStats();
    // }, [userCreatedClubFetch.creatorClub])

    // async function fetchUserClubOnchainStats() {
    //     if (!userCreatedClubFetch.creatorClub) return setUserCreatedClubFetch({ ...userCreatedClubFetch, creatorClubOnchainStatsFetch: { fetchStatus: "success", creatorClubOnchainStats: null } });
    //     setUserCreatedClubFetch({ ...userCreatedClubFetch, creatorClubOnchainStatsFetch: { ...userCreatedClubFetch.creatorClubOnchainStatsFetch, fetchStatus: "fetching" } });
    //     const fetchedCreatorClub = await fetchCreatorClub(userCreatedClubFetch.creatorClub.id, true);
    //     if (fetchedCreatorClub?.creatorClubOnchainStats) {
    //         setUserCreatedClubFetch({ ...userCreatedClubFetch, creatorClubOnchainStatsFetch: { fetchStatus: "success", creatorClubOnchainStats: fetchedCreatorClub.creatorClubOnchainStats } });
    //         return;
    //     }
    //     setUserCreatedClubFetch({ ...userCreatedClubFetch, creatorClubOnchainStatsFetch: { ...userCreatedClubFetch.creatorClubOnchainStatsFetch, fetchStatus: "success" } });
    // }

    return (
        <div id="user-profile" className="flex justify-center text-white w-full pt-20" style={{ height: "inherit" }}>
            <div className="flex flex-col 2xl:flex-row w-full">
                <UserProfileInfo searchedUserAccountFetch={searchedUserAccountFetch} searchedUserIsConnectedUser={searchedUserIsConnectedUser} />
                <UserProfileContent searchedUserAccountFetch={searchedUserAccountFetch} searchedUserIsConnectedUser={searchedUserIsConnectedUser} />
            </div>
        </div>
    )
};

export default UserProfile;