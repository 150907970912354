import React, { FC, useEffect, useState, useRef, useMemo } from "react";

import {
    Popover,
    PopoverHandler,
    PopoverContent
} from "@material-tailwind/react";

import ExpandableImage from "../../../Components/ExpandableImage";

import { AlphaPostComment } from "../../../utils/types";
import { extractTextFromHTML, timestampToRelative } from "../../../utils/utils";

import createDOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
const purify = createDOMPurify(window);

const AlphaPostCommentViewer: FC<any> = (props) => {
    const { comment, seperateFromPreviousMessage, userIsMessageSender, commenterIsPostCreator, deleteComment, commentReply, setCommentReply, getFetchedCommentById }: { comment: AlphaPostComment, seperateFromPreviousMessage: true, userIsMessageSender: true, commenterIsPostCreator: true, deleteComment: Function, commentReply: AlphaPostComment | null, setCommentReply: React.Dispatch<React.SetStateAction<AlphaPostComment | null>>, getFetchedCommentById: Function } = props;

    const [openPopover, setOpenPopover] = React.useState(false);

    useEffect(() => {
        if (openPopover && !userIsMessageSender) setOpenPopover(false)
    }, [comment.id, userIsMessageSender])

    const triggers = {
        onMouseEnter: () => setOpenPopover(true),
        onMouseLeave: () => setOpenPopover(false),
    };

    const repliedToComment: AlphaPostComment | undefined = React.useMemo(() => comment.replyCommentId ? getFetchedCommentById(comment.replyCommentId) : undefined, [comment.replyCommentId])

    return (
        <Popover open={openPopover} handler={() => setOpenPopover(!openPopover)} placement={userIsMessageSender ? "left" : "right"}>
            <div key={comment.id} className={"flex items-center w-full " + (userIsMessageSender ? "justify-end" : "justify-start") + (seperateFromPreviousMessage ? " mt-3" : " mt-1")}>
                <div className={"flex w-[80%] max-w-[700px] items-start"} style={{ justifyContent: "inherit" }}>
                    <div className={"flex flex-col rounded-md px-2 " + (userIsMessageSender ? "items-end order-1 me-2" : "items-start order-2 ms-2") + (commentReply?.id === comment.id ? " bg-dark-third" : "") + (!seperateFromPreviousMessage ? userIsMessageSender ? " me-[48px]" : " ms-[48px]" : "")}>
                        {seperateFromPreviousMessage ? <span className={"flex items-center font-body text-xs " + (commenterIsPostCreator ? "text-primary" : "text-secondary")}><a target="_blank" rel="noopener noreferrer" href={`/profile/a/${comment.creation.user.userId}`}>{comment.creation.user.username}</a><span className="mx-1">·</span>{timestampToRelative(comment.creation.timestamp)}</span> : null}
                        {
                            repliedToComment ? <div className={"flex items-center mt-1 mb-2 " + (repliedToComment.content.text ? " h-[40px]" : " h-[70px]")}>
                                <div className="w-[1px] h-full bg-primary me-2"></div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-primary">{repliedToComment.creation.user.username}</span>
                                    <span className="text-xs">{repliedToComment.content.text ? extractTextFromHTML(repliedToComment.content.text) : repliedToComment.content.media.length ? <img src={repliedToComment.content.media[0].url} className="w-auto h-12 object-cover rounded-lg" /> : null}</span>
                                </div>
                            </div> : null
                        }
                        <PopoverHandler {...triggers}>
                            <div className="flex flex-col" style={{ alignItems: "inherit" }}>
                                <span className="block" dangerouslySetInnerHTML={comment.content.text ? { __html: purify.sanitize(comment.content.text) } : undefined}></span>
                                {comment.content.media.length ? <div className={"flex flex-wrap mt-2 " + (userIsMessageSender ? "justify-end" : "justify-start")}>
                                    {comment.content.media.map((media, index) => <ExpandableImage key={index} imageUrl={media.url} containerClass="w-full h-auto rounded-md overflow-hidden me-2 mb-2" />)}
                                </div> : null}
                            </div>
                        </PopoverHandler>
                    </div>
                    {seperateFromPreviousMessage ? <img src={comment.creation.user.pfpUrl} className={"w-[40px] h-[40px] rounded-full " + (userIsMessageSender ? "order-2" : "order-1")} /> : null}
                </div>
            </div>
            <PopoverContent {...triggers} className="z-[9999] max-w-[26rem] bg-transparent border-0 p-0">
                {userIsMessageSender ?
                    <button className="flex items-center focus-visible:outline-none text-secondary hover:text-red-600 transition-colors duration-200 p-0" onClick={() => deleteComment(comment.id)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                    </button> :
                    <button className="flex items-center focus-visible:outline-none text-secondary hover:text-gray-600 transition-colors duration-200 p-0" onClick={() => setCommentReply(comment)}>
                        <FontAwesomeIcon icon={faReply} className="-scale-x-100 ms-2" />
                    </button>
                }
            </PopoverContent>
        </Popover>
    )
}

export default AlphaPostCommentViewer;
