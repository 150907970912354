import { AlphaPostAssetInfo, AlphaPredictionTimeframeRange } from "./types";

import scalpIconImg from "../media/icons/postTimeRangeIcons/scalpIcon.png";
import swingIconImg from "../media/icons/postTimeRangeIcons/swingIcon.png";
import shortTermIconImg from "../media/icons/postTimeRangeIcons/shortTermIcon.png";
import midTermIconImg from "../media/icons/postTimeRangeIcons/midTermIcon.png";
import longTermIconImg from "../media/icons/postTimeRangeIcons/longTermIcon.png";

export const MAX_TIME_TO_DELETE_ALPHA_POST = 1 * 60 * 60 * 1000; // 1 hour

export const MAIN_WEBSITE_URL = "https://alpha.primordiumlabs.app/big-board/crypto";
export const MAIN_FORUM_TG_GROUP = (tokenType: AlphaPostAssetInfo["type"] | null) => `https://t.me/AlphaPrimordiumClubs/${tokenType ? tokenType === "coin" || tokenType === "token" ? "36961" : tokenType === "ftShare" ? "36963" : "" : "1"}`

export const FIRESTORE_COLLECTION_NAMES = {
    users: "users",
    leaderboards: "leaderboards",
    alphaClub: { main: "alphaClub", posts: "posts", postsComments: "postsComments", news: "marketNews" },
    creatorClubs: "creatorClubs",
}

export const TA_TRADING_VIEW_CHART_IMAGE_BASE_URL = "https://www.tradingview.com/x/";
export const ALPHA_POST_CREATION_CONFIG = {
    MINIMUM_RESEARCH_LENGTH: 150,
    MAXIMUM_MEDIA_ON_POST: 4,
    MAXIMUM_MEDIA_SIZE: 10 * 1024 * 1024, // 10 MB
}

export const NEWS_LINKS = {
    COINDESK: "https://www.coindesk.com/pf/api/v3/content/fetch/you-can-just-subscribe-to-coinflash-bro"
}

export const CHAIN_SLUGS: { [networkId: number]: string } = {
    1: "ethereum",
    8: "ubiq",
    10: "optimism",
    19: "songbird",
    20: "elastos",
    24: "kardiachain",
    25: "cronos",
    30: "rsk",
    40: "telos",
    50: "xdc",
    52: "csc",
    55: "zyx",
    56: "binance",
    57: "syscoin",
    60: "gochain",
    61: "ethereumclassic",
    66: "okexchain",
    70: "hoo",
    82: "meter",
    87: "nova network",
    88: "tomochain",
    100: "xdai",
    106: "velas",
    108: "thundercore",
    122: "fuse",
    128: "heco",
    137: "polygon",
    200: "xdaiarb",
    246: "energyweb",
    250: "fantom",
    269: "hpb",
    288: "boba",
    311: "omax",
    314: "filecoin",
    321: "kucoin",
    324: "era",
    336: "shiden",
    361: "theta",
    416: "sx",
    534: "candle",
    570: "rollux",
    592: "astar",
    820: "callisto",
    888: "wanchain",
    1088: "metis",
    1101: "polygon_zkevm",
    1116: "core",
    1231: "ultron",
    1234: "step",
    1284: "moonbeam",
    1285: "moonriver",
    1440: "living assets mainnet",
    2000: "dogechain",
    2222: "kava",
    2332: "soma",
    4689: "iotex",
    5050: "xlc",
    5551: "nahmii",
    6969: "tombchain",
    7700: "canto",
    8217: "klaytn",
    8453: "base",
    9001: "evmos",
    9790: "carbon",
    10000: "smartbch",
    15551: "loop",
    17777: "eos_evm",
    32520: "bitgert",
    32659: "fusion",
    32769: "zilliqa",
    39815: "oho",
    42161: "arbitrum",
    42170: "arbitrum_nova",
    42220: "celo",
    42262: "oasis",
    43114: "avalanche",
    47805: "rei",
    55555: "reichain",
    71402: "godwoken",
    333999: "polis",
    420420: "kekchain",
    888888: "vision",
    1313161554: "aurora",
    1666600000: "harmony",
    11297108109: "palm",
    836542336838601: "curio"
};

export const CHAIN_SLUG_TO_GECKO_ID: { [chainSlug: string]: string } = {
    "ethereum": "eth",
    "binance": "bsc",
    "base": "base",
    "polygon": "polygon-pos",
    "avalanche": "avax",
    "moonriver": "movr",
    "cronos": "cro",
    "harmony": "one",
    "boba": "boba",
    "fantom": "ftm",
    "smartbch": "bch",
    "aurora": "aurora",
    "metis": "metis",
    "arbitrum": "arbitrum",
    "fuse": "fuse",
    "okexchain": "okexchain",
    "kucoin": "kcc",
    "iotex": "iotx",
    "celo": "celo",
    "xdai": "xdai",
    "heco": "heco",
    "moonbeam": "glmr",
    "optimism": "optimism",
    "energyweb": "nrg",
    "hoo": "hsc",
    "wanchain": "wan",
    "placeholder_1": "ronin",
    "kardiachain": "kai",
    "meter": "mtr",
    "velas": "velas",
    "shiden": "sdn",
    "telos": "tlos",
    "syscoin": "sys",
    "placeholder_2": "terra",
    "oasis": "oasis",
    "astar": "astr",
    "elastos": "ela",
    "placeholder_3": "milkada",
    "placeholder_4": "dfk",
    "evmos": "evmos",
    "placeholder_5": "solana",
    "placeholder_6": "bttc",
    "sx": "sxn",
    "xdc": "xdc",
    "placeholder_7": "cube_network",
    "klaytn": "klaytn",
    "kava": "kava",
    "bitgert": "bitgert",
    "tombchain": "tombchain",
    "dogechain": "dogechain",
    "placeholder_8": "findora",
    "thundercore": "thundercore",
    "placeholder_9": "clover-finance",
    "arbitrum_nova": "arbitrum_nova",
    "canto": "canto",
    "ethereumclassic": "ethereum_classic",
    "step": "step-network",
    "placeholder_10": "ethw",
    "placeholder_11": "ethereumfair",
    "godwoken": "godwoken",
    "songbird": "songbird",
    "placeholder_12": "redlight_chain",
    "kekchain": "kekchain",
    "tomochain": "tomochain",
    "placeholder_13": "fx",
    "placeholder_14": "muuchain",
    "placeholder_15": "platon_network",
    "placeholder_16": "exosama",
    "placeholder_17": "oasys",
    "placeholder_18": "bitkub_chain",
    "placeholder_19": "empire_network",
    "placeholder_20": "alvey",
    "placeholder_21": "wemix",
    "placeholder_22": "flare",
    "placeholder_23": "onus",
    "placeholder_24": "aptos",
    "core": "core",
    "placeholder_25": "goerli-testnet",
    "filecoin": "filecoin",
    "placeholder_26": "bonerium",
    "placeholder_27": "lung-chain",
    "placeholder_28": "zksync",
    "placeholder_29": "poochain",
    "loop": "loop-network",
    "placeholder_30": "bitindi-chain",
    "placeholder_31": "multivac",
    "polygon_zkevm": "polygon-zkevm",
    "beaneco_smartchain": "beaneco-smartchain",
    "eos_evm": "eos-evm",
    "placeholder_32": "apex",
    "callisto": "callisto",
    "ultron": "ultron",
    "nova network": "nova-network",
    "placeholder_33": "sui-network",
    "placeholder_34": "pulsechain",
    "placeholder_35": "trustless-computer",
    "placeholder_36": "enuls",
    "placeholder_37": "tenet",
    "rollux": "rollux",
    "placeholder_38": "starknet-alpha",
};

export const UNIBOT_REF_CODE = "primordium";

export const ALPHA_TIME_RANGE_ICONS = {
    [AlphaPredictionTimeframeRange.Scalp]: scalpIconImg,
    [AlphaPredictionTimeframeRange.SwingTrade]: swingIconImg,
    [AlphaPredictionTimeframeRange.ShortTerm]: shortTermIconImg,
    [AlphaPredictionTimeframeRange.MidTerm]: midTermIconImg,
    [AlphaPredictionTimeframeRange.LongTerm]: longTermIconImg,
}
