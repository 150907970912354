import { createContext, useContext } from "react";

import { NewAlphaPostInfo, getDefaultNewPostInfo } from "./AlphaPostWriterProvider";

interface AlphaPostWriterContextState {
    isWritingPost: boolean,
    setIsWritingPost: Function,
    isWriterDialogOpen: boolean,
    setIsWriterDialogOpen: Function,
    newPostInfo: NewAlphaPostInfo,
    setNewPostInfo: Function,
    getDefaultNewPostInfo: Function,
    createPost: Function
}

const DEFAULT_ALPHA_POST_WRITER_CONTEXT: AlphaPostWriterContextState = {
    isWritingPost: false,
    setIsWritingPost: () => null,
    isWriterDialogOpen: false,
    setIsWriterDialogOpen: () => null,
    newPostInfo: getDefaultNewPostInfo(),
    setNewPostInfo: () => null,
    getDefaultNewPostInfo,
    createPost: () => null
}

export const AlphaPostWriterContext = createContext<AlphaPostWriterContextState>(DEFAULT_ALPHA_POST_WRITER_CONTEXT)

export function useAlphaPostWriter(): AlphaPostWriterContextState {
    return useContext(AlphaPostWriterContext);
}
