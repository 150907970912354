import "./TokenSecurityMetricsDrawer.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import gsap from "gsap";

import {
    Drawer,
} from "@material-tailwind/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { TokenInfo, TokenSecurityMetrics } from "../../../utils/types";
import { randomGradientImg, shortenAddress, timestampToRelative } from "../../../utils/utils";
import CopiableElement from "../../../Components/CopiableElement";

import geckoTerminalIcon from "../../../media/icons/geckoTerminalIcon.png";
import goPlusIcon from "../../../media/icons/goPlusIcon.png";
import uniCryptIcon from "../../../media/icons/unicryptIcon.png";
import { CHAIN_SLUGS, CHAIN_SLUG_TO_GECKO_ID } from "../../../utils/constants";

const TokenSecurityMetricsDrawer: FC<any> = (props) => {
    const { tokenInfo, postTokenSecurityMetricsFetch, close, className }: { tokenInfo: TokenInfo | undefined, postTokenSecurityMetricsFetch: { tokenSecurityMetrics: TokenSecurityMetrics | null, fetching: boolean, drawerOpen: boolean }, close: Function, className: string } = props;


    useEffect(() => {
        if (postTokenSecurityMetricsFetch.drawerOpen) animate()
    }, [postTokenSecurityMetricsFetch.drawerOpen])

    function animate() {
        const auditAlertRow = gsap.utils.toArray(".alert-row");
        const auditAlertRowTimeline = gsap.timeline({ defaults: { duration: 0.5, ease: "power3.inOut" } });
        auditAlertRowTimeline.from(auditAlertRow, { opacity: 0, y: 20, stagger: 0.05, delay: 0.2 })
    }

    return (
        <Drawer placement="right" open={postTokenSecurityMetricsFetch.drawerOpen} onClose={() => close()} size={500} className={`${className} text-white bg-neutral-900 p-4`}>
            <div className="flex flex-col h-full">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-header header-gradient font-bold">AUDIT SCAN</h2>
                    <button className="btn-simple" onClick={() => close()}><FontAwesomeIcon icon={faXmark} /></button>
                </div>
                {tokenInfo && <div id="audit-scan-token-info" className="flex mb-4">
                    <img src={tokenInfo.iconUrl} onError={({ currentTarget }) => {
                        (currentTarget as HTMLImageElement).onerror = null;
                        (currentTarget as HTMLImageElement).src = randomGradientImg();
                    }} className="w-20 h-20 rounded-full" />
                    <div className="flex flex-col justify-between ms-5">
                        <div className="flex items-center">
                            <h3>{tokenInfo.symbol}</h3>
                            <h6 className="ms-2">({tokenInfo.name})</h6>
                            <a href={`https://www.geckoterminal.com/${CHAIN_SLUG_TO_GECKO_ID[CHAIN_SLUGS[tokenInfo.networkId]]}/pools/${tokenInfo.topPoolId}`} target="_blank" rel="noopener noreferrer"><img src={geckoTerminalIcon} className="w-[25px] h-auto ms-2" /></a>
                        </div>
                        <div className="flex mt-2">
                            <CopiableElement copy={tokenInfo.address} className="me-2" popoverContent={{ notClicked: "Copy CA", clicked: "Copied" }} component={<button className="btn-default text-xs md:text-sm"><FontAwesomeIcon icon={faFileLines} className="me-1" /> {shortenAddress(tokenInfo.address)}</button>} />
                            <CopiableElement copy={tokenInfo.topPoolId} className="ms-2" popoverContent={{ notClicked: "Copy Pool", clicked: "Copied" }} component={<button className="btn-default text-xs md:text-sm"><FontAwesomeIcon icon={faFileLines} className="me-1" /> {shortenAddress(tokenInfo.topPoolId)}</button>} />
                        </div>
                    </div>
                </div>}
                <div id="audit-scan-alerts" className="relative grid grid-cols-2 w-full overflow-y-scroll mt-10 px-5">
                    <div id="audit-scan-alerts-header" className="sticky top-0 bg-neutral-900 col-span-2 grid grid-cols-2 w-full pb-2 z-10">
                        <div className="flex items-center">
                            <h3>Alerts</h3>
                        </div>
                        <div className="flex justify-center items-center">
                            <a href={`https://gopluslabs.io/token-security/${tokenInfo?.networkId}/${tokenInfo?.address}`} target="_blank" rel="noopener noreferrer"><img src={goPlusIcon} className="w-[35px] h-auto mb-1" /></a>
                        </div>
                    </div>


                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Contract Verified</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.isOpenSource ? <p className="text-selection">Yes <FontAwesomeIcon icon={faCheck} /></p> : <p className="text-error">No <FontAwesomeIcon icon={faXmark} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Locked Liquidity</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.lpInfo.topLpHolders.filter((holder) => holder.isLocked && holder.tag === "UniCrypt").length ? <div className="flex flex-col">
                                {
                                    postTokenSecurityMetricsFetch.tokenSecurityMetrics.lpInfo.topLpHolders.filter((holder) => holder.isLocked && holder.tag === "UniCrypt").map((holder, index) => {
                                        const percentageLocked = (holder.lockedDetail?.length ? holder.lockedDetail[0].amount : 0) / postTokenSecurityMetricsFetch.tokenSecurityMetrics!.lpInfo.lpTotalSupply * 100;
                                        const liquidityLockerIcon = holder.tag === "UniCrypt" ? uniCryptIcon : undefined
                                        const liquidityLockerLink = `https://app.uncx.network/amm/uni-v2/pair/${tokenInfo?.topPoolId}`;
                                        const lockedPeriodLeft = timestampToRelative(holder.lockedDetail?.length ? new Date(holder.lockedDetail[0].endTime).getTime() : Date.now());
                                        return <div key={index} className="flex items-center">
                                            <img src={liquidityLockerIcon} className="pfp-icon" />
                                            <a href={liquidityLockerLink} target="_blank" rel="noopener noreferrer" className="text-selection ms-2">{percentageLocked.toFixed(2)}% locked {lockedPeriodLeft.replace("in", "for")}</a>
                                        </div>
                                    })
                                }
                            </div> : <p className="text-error">No liquidity locked.</p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Honeypot</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.isHoneypot ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Buy Tax</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? <p className={postTokenSecurityMetricsFetch.tokenSecurityMetrics.buyTax > 0.01 ? "text-error" : "text-selection"}>{postTokenSecurityMetricsFetch.tokenSecurityMetrics.buyTax.toFixed(2)}%</p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Sell Tax</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? <p className={postTokenSecurityMetricsFetch.tokenSecurityMetrics.sellTax > 0.01 ? "text-error" : "text-selection"}>{postTokenSecurityMetricsFetch.tokenSecurityMetrics.sellTax.toFixed(2)}%</p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Tax Modifiable</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.slippageModifiable ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Proxy Contract</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.isProxy ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Mintable</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.isMintable ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Retrieve Ownership</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.canTakeBackOwnership ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    {/* <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Balance Modifiable</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.mod ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div> */}
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Hidden Owner</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.hiddenOwner ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Can Selfdestruct</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.selfDestruct ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>External Call Risk</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.externalCall ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Can Buy</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.cannotBuy ? <p className="text-error">No <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">Yes <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Can Sell All</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.cannotSellAll ? <p className="text-error">No <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">Yes <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Transfer Pausable</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.transferPausable ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Blacklisted</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.isBlackListed ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Whitelisted</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.isWhiteListed ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    {/* <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Transaction/Position Cap</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics. ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div> */}
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Antiwhale Modifiable</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.antiWhaleModifiable ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Trading Cooldown</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.tradingCooldown ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Personal Tax Modifiable</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.personalSlippageModifiable ? <p className="text-error">Yes <FontAwesomeIcon icon={faXmark} /></p> : <p className="text-selection">No <FontAwesomeIcon icon={faCheck} /></p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Liquidity Providers</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? <p>{postTokenSecurityMetricsFetch.tokenSecurityMetrics.lpInfo.lpHoldersCount}</p> : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Owner</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? postTokenSecurityMetricsFetch.tokenSecurityMetrics.ownerInfo ? <p><a className="text-blue-gray-200" href={`https://etherscan.io/address/${postTokenSecurityMetricsFetch.tokenSecurityMetrics.ownerInfo.address}`} target="_blank" rel="noopener noreferrer">{shortenAddress(postTokenSecurityMetricsFetch.tokenSecurityMetrics.ownerInfo.address)}</a> ({postTokenSecurityMetricsFetch.tokenSecurityMetrics.ownerInfo.percent}%)</p> : "No owner." : "-"}
                        </div>
                    </div>
                    <div className="alert-row text-normal col-span-2 grid grid-cols-2 w-full border-b border-neutral-700 py-2">
                        <div className="flex items-center">
                            <p>Creator</p>
                        </div>
                        <div className="flex justify-center items-center">
                            {postTokenSecurityMetricsFetch.fetching ? <div className="loading-square h-[20px]"></div> : postTokenSecurityMetricsFetch.tokenSecurityMetrics ? <p><a className="text-blue-gray-200" href={`https://etherscan.io/address/${postTokenSecurityMetricsFetch.tokenSecurityMetrics.creatorInfo.address}`} target="_blank" rel="noopener noreferrer">{shortenAddress(postTokenSecurityMetricsFetch.tokenSecurityMetrics.creatorInfo.address)}</a> ({postTokenSecurityMetricsFetch.tokenSecurityMetrics.creatorInfo.percent}%)</p> : "-"}
                        </div>
                    </div>
                </div>
            </div>

        </Drawer>
    )
}

export default TokenSecurityMetricsDrawer;
