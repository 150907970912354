import axios from "axios";
import twitter from "./twitter"
import telegram from "./telegram"

import { API_URL } from "../api"
import { UserAccount } from "../../types"
import { firebaseAuth } from "../../firebaseApp";

async function fetchConnectedUserAccount(): Promise<UserAccount | null> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return null;
    const headers = {
        "Authorization": "Bearer " + firebaseAuthToken,
    }

    let response;
    try {
        response = await axios.get(`${API_URL}/users/user-info/connected`, { headers });
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    // await delay(30000)
    return responseData.userAccount;
}
async function fetchUserAccount(): Promise<UserAccount | null> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    if (!firebaseAuthToken) return null;
    const headers = {
        "Authorization": "Bearer " + firebaseAuthToken,
    }

    let response;
    try {
        response = await axios.get(`${API_URL}/users/user-info`, { headers });
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    // await delay(30000)
    return responseData.userAccount;
}

export default {
    fetchConnectedUserAccount,
    fetchUserAccount,
    twitter,
    telegram,
}
