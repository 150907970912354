import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";


const ExpandableImage: FC<any> = (props) => {
    const { containerClass, imageClass, imageUrl }: { containerClass: string, imageClass: string, imageUrl: string } = props;

    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <>
            <div className={containerClass} onClick={() => setIsExpanded(false)}>
                <img src={imageUrl} className={imageClass} />
            </div>
            {/* <button className={`fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-[9999] ps-6 pe-10 flex justify-center items-center ${isExpanded ? "flex" : "hidden"}`} onClick={() => setIsExpanded(false)}>
                <img src={imageUrl} className="w-full h-auto max-w-[1600px]" />
            </button> */}
        </>
    )
}

export default ExpandableImage;