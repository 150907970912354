import "./AlphaPostCommentWriter.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMinus, faPlus, faCircleQuestion, faReply } from "@fortawesome/free-solid-svg-icons";

import PopoverContainer, { PopoverTriggerType } from '@idui/react-popover'


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

import { toast } from "react-toastify";

import loadingIconAnim from "../../../media/icons/loadingIcon.gif"
import tradingViewIcon from "../../../media/icons/tradingView.webp";

import { AlphaPostAssetInfo, AlphaPostComment, PostCreationError } from "../../../utils/types"
import { ALPHA_POST_CREATION_CONFIG, TA_TRADING_VIEW_CHART_IMAGE_BASE_URL } from "../../../utils/constants";
import { extractTextFromHTML, formatTradingViewChartUrl } from "../../../utils/utils";

const commentWriterConfig = {
    modules: {
        toolbar: "#alpha-post-comment-writer-research-toolbar .ql-toolbar"
    },

    formats: [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', // "code", "code-block"
    ]
}

interface NewComment {
    creating: boolean;
    content: {
        text: string;
        media: { type: "image" | "video", filePreview: string, file: File }[];
        taChartUrl: string | null;
    },
    includeChart: boolean;
    errors: string[]
}

const AlphaPostCommentWriter: FC<any> = (props) => {
    const { className, tradingViewChart, chartEnabled, commentReply, setCommentReply, sendComment }: { className: string, tradingViewChart: any, chartEnabled: boolean, commentReply: AlphaPostComment, setCommentReply: React.Dispatch<React.SetStateAction<AlphaPostComment | null>>, sendComment: Function } = props;

    const [newComment, setNewComment] = useState<NewComment>({ creating: false, content: { text: "", media: [], taChartUrl: null }, includeChart: false, errors: [] })

    useEffect(() => {
        setNewComment({ ...newComment, errors: [] })
    }, [newComment.content, newComment.includeChart])

    const toolbarImageInputRef = useRef<HTMLInputElement | null>(null);

    function handleImageInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (file.size > ALPHA_POST_CREATION_CONFIG.MAXIMUM_MEDIA_SIZE) return;
            const filePreview = URL.createObjectURL(file);
            setNewComment({ ...newComment, content: { ...newComment.content, media: [...newComment.content.media, { type: "image", filePreview, file }] } });
        }
    }

    function removeMedia(index: number) {
        const newMedia = [...newComment.content.media];
        newMedia.splice(index, 1);
        setNewComment({ ...newComment, content: { ...newComment.content, media: newMedia } });
    }

    async function createAlphaPostComment() {
        function checkCommentContent(newComment: NewComment) {
            const errors = []

            if (newComment.content === null) { errors.push("all"); return errors; }
            if (newComment.includeChart && (newComment.content.taChartUrl === null || newComment.content.taChartUrl.slice(0, 30) !== TA_TRADING_VIEW_CHART_IMAGE_BASE_URL)) errors.push("taChartUrl")
            return errors
        }
        const errors = checkCommentContent(newComment)
        console.log(errors)
        if (errors.length > 0) {
            setNewComment({ ...newComment, errors })
            return
        }

        setNewComment({ ...newComment, creating: true, errors });
        const newCommentContent: AlphaPostComment["content"] = {
            text: newComment.content.text,
            media: newComment.content.taChartUrl ? [{ type: "image", url: formatTradingViewChartUrl(newComment.content.taChartUrl), description: "Trading View Chart" }] : [],
        }

        const imageFiles: File[] = [];
        for (let i = 0; i < newComment.content.media.length; i++) {
            const media = newComment.content.media[i];
            URL.revokeObjectURL(media.filePreview);
            imageFiles.push(media.file);
        }
        // const result = await api.alphaPost.createPost(finalNewAlphaPostContent, imageFiles, newPostInfo.isRatingOn);

        const result = await sendComment(newCommentContent, imageFiles);
        // const result = await api.alphaPost.createComment(selectedPostFetch.alphaPost.id, commentReplyTarget === undefined ? undefined : commentReplyTarget?.originCommentId, commentReplyTarget?.id, newAlphaPostCommentContent);
        if (!result.success) {
            setNewComment({ ...newComment, creating: false });

            toast.error(result.error);
            return;
        }
        setNewComment({ ...newComment, creating: false, includeChart: false, content: { text: "", media: [], taChartUrl: null } });
    }

    return <div id="alpha-post-comment-writer" className={`${className} flex flex-col`}>
        {
            newComment.content.media.length > 0 ? <div className="flex flex-wrap items-center w-full h-24">
                {newComment.content.media.map((media, index: number) => <div key={index} className="relative flex justify-center items-center w-auto h-full bg-primary-background rounded-lg p-2">
                    {media.type === "image" ? <img src={media.filePreview} className="w-full h-full object-cover rounded-lg" /> : null}
                    <button className="absolute top-2 right-3" onClick={() => removeMedia(index)}><FontAwesomeIcon icon={faXmark} /></button>
                </div>)}
            </div> : null
        }
        {
            commentReply !== null ? <div className="flex items-center justify-between w-full bg-dark-second rounded-lg px-4 mb-1">
                <div className="flex items-center w-full h-full">
                    <FontAwesomeIcon icon={faReply} className="text-secondary me-2" />
                    <div className="flex flex-col">
                        <h6 className="text-sm text-primary">{commentReply.creation.user.username}</h6>
                        <span className="text-xs">{commentReply.content.text ? extractTextFromHTML(commentReply.content.text) : commentReply.content.media.length ? <img src={commentReply.content.media[0].url} className="w-auto h-12 object-cover rounded-lg" /> : null}</span>
                    </div>
                </div>
                <button className="flex items-center focus-visible:outline-none text-secondary hover:text-gray-600 transition-colors duration-200 p-0" onClick={() => setCommentReply(null)}>
                    <FontAwesomeIcon icon={faXmark} className="-scale-x-100 me-2" />
                </button>
            </div> : null
        }
        <ReactQuill theme="snow" id="alpha-post-comment-writer-research" readOnly={newComment.creating} value={newComment.content.text} onChange={(newValue) => {
            if (newComment.creating) return;
            const changedNewComment = { ...newComment };
            changedNewComment.content.text = newValue === "<p><br></p>" ? "" : newValue;
            changedNewComment.errors = changedNewComment.errors.filter(error => error !== PostCreationError.Research)
            setNewComment(changedNewComment);
        }} modules={commentWriterConfig.modules} formats={commentWriterConfig.formats} />

        <div id="chart-container" className="flex flex-col overflow-hidden border-x border-neutral-800">
            <div className={`${newComment.includeChart ? "expanded mt-0" : "collapsed -mt-[586px]"} active-chart transition-all duration-1000`}>
                {tradingViewChart}
                <div className="flex flex-col chart-link-container my-2 ms-2">
                    <div className="flex items-center w-full">
                        <input type="text" value={newComment.content.taChartUrl === null ? "" : newComment.content.taChartUrl} disabled={newComment.creating} onChange={(e) => {
                            if (newComment.creating) return;
                            const changedNewComment = { ...newComment };
                            changedNewComment.content.taChartUrl = e.target.value;
                            changedNewComment.errors = changedNewComment.errors.filter(error => error !== PostCreationError.TaChartUrl)
                            setNewComment(changedNewComment);
                        }
                        } className={`comment-writer-input text-white bg-transparent border border-neutral-800 rounded-lg ps-2 outline-none me-2 w-[90%] max-w-[400px] ${newComment.errors.includes(PostCreationError.TaChartUrl) ? "comment-writer-input-error" : ""}`} placeholder={`Chart link (${TA_TRADING_VIEW_CHART_IMAGE_BASE_URL}):`}></input>
                        <PopoverContainer className="chart-image-input-question-popover flex wrap" content={"To include your TA, click on the camera icon (top-right of chart) and on \"Copy Link to the chart image\", then paste the tradingview.com link here. The same process applies if you've done your TA on TradingView's website."} trigger={"hover" as PopoverTriggerType} animation={{ animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } }, initial: { opacity: 0, scale: 0.9 } }} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck() { }} onFocus={function noRefCheck() { }} withArrow={false} >
                            <FontAwesomeIcon icon={faCircleQuestion} />
                        </PopoverContainer>
                    </div>
                    {newComment.errors.includes(PostCreationError.TaChartUrl) && <span className="text-error">Invalid Trading View chart image url</span>}
                </div>
            </div>
        </div>
        <div id={`alpha-post-comment-writer-research-toolbar`} className="flex flex-wrap rounded-b-[1.2rem] border border-neutral-800 bg-neutral-900">
            <div className="ql-toolbar ql-snow flex flex-wrap !border-none">
                <span className="ql-formats">
                    <button type="button" className="ql-bold"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
                    <button type="button" className="ql-italic"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
                    <button type="button" className="ql-underline"><svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
                    <button type="button" className="ql-strike"><svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg></button>
                    <button type="button" className="ql-blockquote"><svg viewBox="0 0 18 18"> <rect className="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect> <rect className="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect> <path className="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path> <path className="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path> </svg></button>
                </span>
                <span className="ql-formats">
                    <button type="button" className="ql-list" value="ordered"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button>
                    <button type="button" className="ql-list" value="bullet"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
                    {/* <button type="button" className="ql-indent" value="-1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-stroke" points="5 7 5 11 3 9 5 7"></polyline> </svg></button> */}
                    <button type="button" className="ql-indent" value="+1"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline className="ql-fill ql-stroke" points="3 7 3 11 5 9 3 7"></polyline> </svg></button>
                </span>
                <span className="ql-formats">
                    <button type="button" className="ql-link"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg></button>
                    {/* <button type="button" className="ql-code"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button>
                <button type="button" className="ql-code-block"><svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg></button> */}
                </span>
                <span className="ql-formats">
                    <button type="button" className="ql-clean"><svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3"></line> <line className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3"></line> <line className="ql-stroke" x1="11" x2="15" y1="11" y2="15"></line> <line className="ql-stroke" x1="15" x2="11" y1="11" y2="15"></line> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect> </svg></button>
                </span>
                {/* <span className="ql-formats ms-auto"></span> */}
                <button onClick={() => toolbarImageInputRef.current && toolbarImageInputRef.current.click()} disabled={newComment.creating} type="button" className="ql-custom-image disabled:cursor-default disabled:opacity-70"><svg fill="currentColor" height="18px" width="18px" viewBox="0 0 500 500"><g><g><path d="M0,437.8c0,28.5,23.2,51.6,51.6,51.6h386.2c28.5,0,51.6-23.2,51.6-51.6V51.6c0-28.5-23.2-51.6-51.6-51.6H51.6    C23.1,0,0,23.2,0,51.6C0,51.6,0,437.8,0,437.8z M437.8,464.9H51.6c-14.9,0-27.1-12.2-27.1-27.1v-64.5l92.8-92.8l79.3,79.3    c4.8,4.8,12.5,4.8,17.3,0l143.2-143.2l107.8,107.8v113.4C464.9,452.7,452.7,464.9,437.8,464.9z M51.6,24.5h386.2    c14.9,0,27.1,12.2,27.1,27.1v238.1l-99.2-99.1c-4.8-4.8-12.5-4.8-17.3,0L205.2,333.8l-79.3-79.3c-4.8-4.8-12.5-4.8-17.3,0    l-84.1,84.1v-287C24.5,36.7,36.7,24.5,51.6,24.5z" /><path d="M151.7,196.1c34.4,0,62.3-28,62.3-62.3s-28-62.3-62.3-62.3s-62.3,28-62.3,62.3S117.3,196.1,151.7,196.1z M151.7,96    c20.9,0,37.8,17,37.8,37.8s-17,37.8-37.8,37.8s-37.8-17-37.8-37.8S130.8,96,151.7,96z" /></g></g></svg></button>
                <input ref={toolbarImageInputRef} type="file" accept="image/png,image/jpg,image/jpeg" name="images" className="hidden" onChange={(e) => { handleImageInputChange(e); e.target.value = "" }}></input>
            </div>
            <div className="ql-formats flex ms-auto items-center justify-center text-sm">
                {chartEnabled ? <button className="include-trading-view-btn btn-default-reversed flex items-center border-0 rounded-[0.7rem] disabled:cursor-default disabled:opacity-30 me-2 my-2 px-3" disabled={newComment.creating} onClick={() => { console.log(tradingViewChart); setNewComment({ ...newComment, includeChart: !newComment.includeChart }) }}>{newComment.includeChart ? <><FontAwesomeIcon icon={faMinus} /> <span className="ms-3 me-2">Remove</span></> : <><FontAwesomeIcon icon={faPlus} /> <span className="ms-3 me-2">Include</span></>} <img src={tradingViewIcon} width="20px" /></button> : ""}
                {/* <button className="rating-toggle-btn btn-default-reversed flex items-center w-fit me-2 my-2 pt-1" style={{ border: "0", borderRadius: ".7rem" }} onClick={() => setNewPostInfo({ ...newPostInfo, includeChart: !newPostInfo.includeChart })}>Rating: ON</button> */}
                <button id="alpha-post-comment-writer-post-btn" className="flex items-center btn-default-reversed w-fit rounded-[3rem] disabled:cursor-default disabled:opacity-80 px-3 my-2" disabled={newComment.creating} onClick={() => createAlphaPostComment()}>{newComment.creating ? <img src={loadingIconAnim} className="px-3 h-[20px] w-auto" /> : "Comment"}</button>
            </div>
        </div>
    </div >
};

export default AlphaPostCommentWriter;