import axios from "axios";
import { CreatorClubOnchainStats, FtCreator, FtShareInfo, UserOnchainStats } from "../../types"
import { API_URL } from "../api";

async function fetchUserOnchainStats(walletAddress: string): Promise<UserOnchainStats | null> {
    const placeholder: UserOnchainStats = {
        wallet: {
            address: walletAddress,
            balance: Math.random() * 5,
        },
        shares: {
            clubs: {
                "d0bf5b48-3351-40a5-bae9-eba8f97ca114": 1,
                "e2834a6d-241d-44e8-99e9-a61441b2f7db": 2,
                "e61c5d47-bb4b-47e3-bbf5-79cbca532150": 5,
            },
        }
    }
    return placeholder;
}

async function fetchClubOnchainStats(creatorClubId: string): Promise<CreatorClubOnchainStats | null> {
    const numShares = Math.random() * 100;
    const numShareholders = Math.max(numShares - Math.random() * 15, 1);
    const sharePriceSell = numShares * 0.2 / 100;
    const clubHype = Math.random() * 100;
    const sharePriceBuy = sharePriceSell + (clubHype / 100) * sharePriceSell / 2;
    const placeholder: CreatorClubOnchainStats = {
        numShares: Math.round(numShares),
        numShareholders: Math.round(numShareholders),
        sharePrice: {
            buy: Number(sharePriceBuy.toFixed(3)),
            sell: Number(sharePriceSell.toFixed(3)),
        },
        clubHype: Math.round(clubHype),
        creatorClubId,
    };
    return placeholder;
}

async function getFtCreators(searchUsername: string): Promise<FtCreator[]> {
    const params = {
        searchUsername,
    }
    let response;
    try {
        response = await axios.get(`${API_URL}/onchain/search-ft-creator`, { params });
    } catch (error) {
        console.error(error);
        return [];
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return [];
    }

    return responseData.ftCreators;
}

async function getFtShareInfo(searchAddress: string): Promise<FtShareInfo | undefined> {
    const params = {
        searchAddress,
    }
    let response;
    try {
        response = await axios.get(`${API_URL}/onchain/ft-share-info`, { params });
    } catch (error) {
        console.error(error);
        return;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return;
    }

    return responseData.ftShareInfo;
}

export default {
    fetchUserOnchainStats,
    fetchClubOnchainStats,
    getFtCreators,
    getFtShareInfo,
}