import { useState, useEffect } from 'react'

// import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
// import { TooltipProvider } from '@radix-ui/react-tooltip'
// import { useRouter } from 'next/navigation'

import friendTechIcon from "../../../media/icons/friendTechIcon.png";
import { ReactComponent as ConfirmTick } from "../../../media/icons/confirmTick.svg";
import { ReactComponent as CaretUp } from "../../../media/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../../../media/icons/CaretDown.svg";
// import { ChevronDownIcon } from '@/components/ui/ChevronDownIcon'
// import { SearchType } from '@/lib/app/SearchTypes'

// import { Tooltip, TooltipContent, TooltipTrigger } from '../../ui/tooltip'

import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
} from "@material-tailwind/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DEFINED_TOKEN_ICON_URL, randomGradientImg } from '../../../utils/utils';
import { AssetClass, FtCreator, FtShareInfo, SearchedTokensFetch, TokenInfo } from '../../../utils/types';
import api from '../../../utils/api/api';
import { CHAIN_SLUGS } from '../../../utils/constants';
import { IAlphaPostFilters } from '../types';

enum SearchType {
    Token = 'Token',
    Creator = "Creator",
    Author = 'Author',
}

export function SearchBox(props: any) {
    const { alphaPostFilters, setAlphaPostFilters }: { alphaPostFilters: IAlphaPostFilters, setAlphaPostFilters: React.Dispatch<React.SetStateAction<IAlphaPostFilters>> } = props;
    // SearchBox logic (classic search states)
    const [inputValue, setInputValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const handleSearch = async () => {

    }
    const handleSearchWithEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await handleSearch()
        }
    }

    // Search type logic (to handle search by token/author)
    type PlaceholderMap = Record<SearchType, string>
    const [selectedOption, setSelectedOption] = useState(SearchType.Token) // Default state is token (search by token)
    const placeholderMap: PlaceholderMap = {
        [SearchType.Token]: 'Search by token name/contract ...',
        [SearchType.Creator]: 'Search by creator handle/wallet ...',
        [SearchType.Author]: 'Search by author handle ...',
    }

    const [searchedTokens, setSearchedTokens] = useState<TokenInfo[]>([]);
    const [searchedFtCreators, setSearchedFtCreators] = useState<FtCreator[]>([]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            search()
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [inputValue])
    function search() {
        switch (selectedOption) {
            case SearchType.Token:
                searchForTokens()
                break;
            case SearchType.Creator:
                searchForFtCreators()
                break;
            case SearchType.Author:
                break;
        }
    }

    async function searchForTokens() {
        setSearchedTokens([])
        if (!inputValue.length) {
            return;
        }
        setLoading(true);
        const searchTokensResult = await api.defi.searchToken(inputValue, 10);
        const newSearchedTokens: TokenInfo[] = []
        for (const tokenResult of searchTokensResult) {
            newSearchedTokens.push({
                name: tokenResult.name,
                symbol: tokenResult.symbol,
                address: tokenResult.address,
                networkId: tokenResult.networkId,
                topPoolId: tokenResult.topPairId.split(":")[0],
                iconUrl: DEFINED_TOKEN_ICON_URL(tokenResult),

                metrics: {
                    liquidity: tokenResult.liquidity,
                    price: tokenResult.price,
                    priceChange: tokenResult.priceChange * 100,
                    volume: tokenResult.volume,
                },

                chain: { slug: CHAIN_SLUGS[tokenResult.networkId], icon: `https://icons.llamao.fi/icons/chains/rsz_${CHAIN_SLUGS[tokenResult.networkId]}.jpg` }
            })
        }
        console.log(newSearchedTokens)
        setLoading(false);
        setSearchedTokens(newSearchedTokens)
    }

    async function searchForFtCreators() {
        setSearchedFtCreators([])
        if (!inputValue.length) {
            return;
        }
        setLoading(true);
        const newSearchedFtCreators = await api.onchain.getFtCreators(inputValue);
        console.log(newSearchedFtCreators)
        setLoading(false);
        setSearchedFtCreators(newSearchedFtCreators)
    }

    function selectToken(tokenInfo: TokenInfo) {
        setAlphaPostFilters({ ...alphaPostFilters, assets: [tokenInfo] });
        setMenuOpened(false)
    }

    function selectCreator(ftCreator: FtCreator) {
        const ftShareInfo: FtShareInfo = {
            address: ftCreator.address,
            networkId: "FT",
            symbol: ftCreator.twitterUsername, // twitterUsername
            twitterName: ftCreator.twitterName,
            iconUrl: ftCreator.twitterPfpUrl,
            twitterUserId: ftCreator.twitterUserId,
        }
        setAlphaPostFilters({ ...alphaPostFilters, assets: [ftShareInfo] });
        setMenuOpened(false)
    }

    useEffect(() => {
        if (selectedOption === SearchType.Token && alphaPostFilters.assetClass === AssetClass.FriendTech) setSelectedOption(SearchType.Creator);
        else if (selectedOption === SearchType.Creator && alphaPostFilters.assetClass === AssetClass.Crypto) setSelectedOption(SearchType.Token);
    }, [alphaPostFilters.assetClass])


    const tokenContainerExample = (tokenInfo: TokenInfo) => <Button key={tokenInfo.address} className="bg-neutral-750 h-[44px]" onClick={() => selectToken(tokenInfo)}>
        <div className="flex items-center text-white">
            <LazyLoadImage src={DEFINED_TOKEN_ICON_URL(tokenInfo)} onError={({ currentTarget }) => {
                (currentTarget as HTMLImageElement).onerror = null;
                (currentTarget as HTMLImageElement).src = randomGradientImg();
            }} className="token-icon me-2" />
            <div className="flex justify-start items-center w-full leading-[20px]">
                <div className="token-symbol text-start w-[23%]">{tokenInfo.symbol}</div>
                {tokenInfo.chain.icon ? <LazyLoadImage src={tokenInfo.chain.icon} onError={({ currentTarget }) => {
                    (currentTarget as HTMLImageElement).onerror = null;
                    (currentTarget as HTMLImageElement).src = randomGradientImg();
                }} className="token-icon me-2" /> : ""}
                <div className="token-stats flex ms-auto items-center">
                    <div className="token-price me-2">${tokenInfo.metrics?.price?.toFixed(2) ?? "-"}</div>
                    {tokenInfo.metrics?.priceChange !== undefined ? <div className={`token-price-change flex items-center ${tokenInfo.metrics?.priceChange >= 0 ? "text-selection" : "text-error"}`}>
                        {tokenInfo.metrics?.priceChange > 0 ? <CaretUp /> : <CaretDown />}
                        <span className="ms-1">{Math.abs(tokenInfo.metrics?.priceChange).toFixed(2)}%</span>
                    </div> : "-"}
                </div>
            </div>
        </div>
    </Button>

    const creatorContainerExample = (ftCreator: FtCreator) => <Button key={ftCreator.address} className="bg-neutral-750 h-[44px]" onClick={() => selectCreator(ftCreator)}>
        <div className="flex items-center text-white">
            <LazyLoadImage src={ftCreator.twitterPfpUrl} onError={({ currentTarget }) => {
                (currentTarget as HTMLImageElement).onerror = null;
                (currentTarget as HTMLImageElement).src = friendTechIcon;
            }} className="token-icon me-2" />
            <div className="flex flex-col justify-center w-full">
                <p className="creator-username text-start" style={{ textTransform: "none" }}>{ftCreator.twitterUsername}</p>
                {/* <p className="creator-username text-start text-xs ps-3 text-neutral-300">{ftCreator.twitterUsername}</p> */}
            </div>
        </div>
    </Button>

    return <div onFocus={() => setMenuOpened(true)} onBlur={() => setMenuOpened(false)} className={`absolute flex flex-col w-[90%] md:w-[36rem] z-[9000] max-h-[300px] md:mx-auto rounded-xl border-[1px] px-3 py-2 overflow-y-hidden duration-300 ease-in hover:shadow-[0_0_0_1px_rgb(190,227,248)] border-[rgba(255,255,255,0.19)] bg-dark`} style={{ height: 56 + (menuOpened && inputValue.length > 0 ? Math.min(loading ? 3 : (selectedOption === SearchType.Token ? searchedTokens : searchedFtCreators).length, 5) * (44 + 8) : 0) + "px" }}>
        <div className="flex w-full items-center h-[38px]">
            <Popover placement="bottom">
                <PopoverHandler>
                    <Button className="bg-neutral-800 min-w-[100px] leading-[14px] px-3">{selectedOption.valueOf()}</Button>
                </PopoverHandler>
                <PopoverContent className="flex flex-col bg-neutral-900 outline-none !border !border-neutral-800 shadow-black p-2" style={{ border: "inherit" }}>
                    <div className="flex w-full">
                        {alphaPostFilters.assetClass === AssetClass.Crypto ? <Button className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Token ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Token)}>
                            <p className="text-sm">{SearchType.Token.valueOf()}</p>
                            <p className="flex items-center justify-between w-full text-xs text-gray-300">Find posts related to a singular asset. {selectedOption === SearchType.Token ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
                        </Button> :
                            <Button className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Creator ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Creator)}>
                                <p className="text-sm">{SearchType.Creator.valueOf()}</p>
                                <p className="flex items-center justify-between w-full text-xs text-gray-300">Find posts related to a singular FT creator. {selectedOption === SearchType.Creator ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
                            </Button>}
                    </div>
                    <div className="flex w-full">
                        <Button disabled className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Author ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Author)}>
                            <p className="text-sm">{SearchType.Author.valueOf()}</p>
                            <p className="flex items-center justify-between w-full text-xs text-gray-300">Finds content written by a specific author. {selectedOption === SearchType.Author ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
                        </Button>
                    </div>
                </PopoverContent>
            </Popover>
            <input
                className="mx-2 mt-0.5 w-full h-full border-none bg-transparent py-0.5 text-[15px] text-white outline-0 placeholder:text-[#BBBFC4] focus:ring-0 dark:text-slate-200"
                placeholder={placeholderMap[selectedOption]}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleSearchWithEnter}
            // onFocus={() => setInputFocused(true)}
            />
            <button className="rounded-full p-2 text-white hover:bg-gray-800" onClick={handleSearch}>
                {loading ? (
                    <svg
                        className="animate-spin text-white"
                        fill="none"
                        height="16"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
                        <path d="M12 6l0 -3"></path>
                        <path d="M16.25 7.75l2.15 -2.15"></path>
                        <path d="M18 12l3 0"></path>
                        <path d="M16.25 16.25l2.15 2.15"></path>
                        <path d="M12 18l0 3"></path>
                        <path d="M7.75 16.25l-2.15 2.15"></path>
                        <path d="M6 12l-3 0"></path>
                        <path d="M7.75 7.75l-2.15 -2.15"></path>
                    </svg>
                ) : (
                    <svg
                        fill="none"
                        height="1em"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <line x1="5" x2="19" y1="12" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                )}
            </button>
        </div>
        <div className="grid grid-cols-1 w-full overflow-y-scroll no-scrollbar gap-2 mt-2">
            {
                loading ? <>
                    {new Array(3).fill(0).map((_, i) => <div key={i} className="loading-square !w-full !h-[44px] !rounded-lg"></div>)}
                </> :
                    selectedOption === SearchType.Token ? searchedTokens.map((assetInfo) => tokenContainerExample(assetInfo)) : searchedFtCreators.map((ftCreator) => creatorContainerExample(ftCreator))
            }
        </div>
    </div>

    // return <Menu
    //     placement="bottom"
    //     dismiss={{
    //         itemPress: false,
    //     }}
    //     open={menuOpened && inputValue.length > 0}
    //     handler={() => setMenuOpened(!menuOpened)}
    // >
    //     <MenuHandler>

    //         {/* <button className="rounded-full p-2 text-white hover:bg-gray-800" onClick={handleSearch}>
    //             {loading ? (
    //                 <svg
    //                     className="animate-spin text-white"
    //                     fill="none"
    //                     height="16"
    //                     stroke="currentColor"
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth="2"
    //                     viewBox="0 0 24 24"
    //                     width="24"
    //                     xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
    //                     <path d="M12 6l0 -3"></path>
    //                     <path d="M16.25 7.75l2.15 -2.15"></path>
    //                     <path d="M18 12l3 0"></path>
    //                     <path d="M16.25 16.25l2.15 2.15"></path>
    //                     <path d="M12 18l0 3"></path>
    //                     <path d="M7.75 16.25l-2.15 2.15"></path>
    //                     <path d="M6 12l-3 0"></path>
    //                     <path d="M7.75 7.75l-2.15 -2.15"></path>
    //                 </svg>
    //             ) : (
    //                 <svg
    //                     fill="none"
    //                     height="1em"
    //                     stroke="currentColor"
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth="2"
    //                     viewBox="0 0 24 24"
    //                     width="1em"
    //                     xmlns="http://www.w3.org/2000/svg">
    //                     <line x1="5" x2="19" y1="12" y2="12"></line>
    //                     <polyline points="12 5 19 12 12 19"></polyline>
    //                 </svg>
    //             )}
    //         </button> */}
    //     </MenuHandler>
    //     <MenuList>
    //         <MenuItem value="1">Option 1</MenuItem>
    //         <MenuItem value="2">Option 2</MenuItem>
    //         <MenuItem value="3">Option 3</MenuItem>
    //     </MenuList>
    // </Menu>
    // <>
    //     <div
    //         className={`mx-auto flex w-full items-center rounded-xl border-[1px] px-3 py-2 duration-300 ease-in hover:shadow-[0_0_0_1px_rgb(190,227,248)] border-[rgba(255,255,255,0.19)] bg-dark md:w-[36rem]`}>
    //         <Popover placement="bottom">
    //             <PopoverHandler>
    //                 <Button className="bg-neutral-800 w-[20%] leading-[14px] px-3">{selectedOption.valueOf()}</Button>
    //             </PopoverHandler>
    //             <PopoverContent className="flex flex-col bg-neutral-900 outline-none !border !border-neutral-800 shadow-black p-2" style={{ border: "inherit" }}>
    //                 <div className="flex w-full">
    //                     <Button className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Token ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Token)}>
    //                         <p className="text-sm">{SearchType.Token.valueOf()}</p>
    //                         <p className="flex items-center justify-between w-full text-xs text-gray-300">Find posts related to a singular asset. {selectedOption === SearchType.Token ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
    //                     </Button>
    //                 </div>
    //                 <div className="flex w-full">
    //                     <Button className={"bg-transparent flex flex-col normal-case font-thin text-white w-full px-2 " + (selectedOption === SearchType.Author ? "bg-neutral-950" : "")} onClick={() => setSelectedOption(SearchType.Author)}>
    //                         <p className="text-sm">{SearchType.Author.valueOf()}</p>
    //                         <p className="flex items-center justify-between w-full text-xs text-gray-300">Finds content written by a specific author. {selectedOption === SearchType.Author ? <ConfirmTick className="ms-2 w-[12px] h-auto" /> : <span className="ms-2 w-[12px] h-auto"></span>}</p>
    //                     </Button>
    //                 </div>
    //             </PopoverContent>
    //         </Popover>
    //         <input
    //             className="mx-2 mt-0.5 w-full border-none bg-transparent py-0.5 text-[15px] text-white outline-0 placeholder:text-[#BBBFC4] focus:ring-0 dark:text-slate-200"
    //             placeholder={placeholderMap[selectedOption]}
    //             value={inputValue}
    //             onChange={(e) => setInputValue(e.target.value)}
    //             onKeyDown={handleSearchWithEnter}
    //         />
    //         <button className="rounded-full p-2 text-white hover:bg-gray-800" onClick={handleSearch}>
    //             {loading ? (
    //                 <svg
    //                     className="animate-spin text-white"
    //                     fill="none"
    //                     height="16"
    //                     stroke="currentColor"
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth="2"
    //                     viewBox="0 0 24 24"
    //                     width="24"
    //                     xmlns="http://www.w3.org/2000/svg">
    //                     <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
    //                     <path d="M12 6l0 -3"></path>
    //                     <path d="M16.25 7.75l2.15 -2.15"></path>
    //                     <path d="M18 12l3 0"></path>
    //                     <path d="M16.25 16.25l2.15 2.15"></path>
    //                     <path d="M12 18l0 3"></path>
    //                     <path d="M7.75 16.25l-2.15 2.15"></path>
    //                     <path d="M6 12l-3 0"></path>
    //                     <path d="M7.75 7.75l-2.15 -2.15"></path>
    //                 </svg>
    //             ) : (
    //                 <svg
    //                     fill="none"
    //                     height="1em"
    //                     stroke="currentColor"
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     strokeWidth="2"
    //                     viewBox="0 0 24 24"
    //                     width="1em"
    //                     xmlns="http://www.w3.org/2000/svg">
    //                     <line x1="5" x2="19" y1="12" y2="12"></line>
    //                     <polyline points="12 5 19 12 12 19"></polyline>
    //                 </svg>
    //             )}
    //         </button>
    //     </div>
    // </>
}
