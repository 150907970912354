import { type ReactNode, useEffect, useState, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";

import mixpanel from "mixpanel-browser";
import api from "../../utils/api/api";
import { toast } from "react-toastify";

import { ALPHA_POST_CREATION_CONFIG, TA_TRADING_VIEW_CHART_IMAGE_BASE_URL } from "../../utils/constants";
import { NewAlphaPostContent, DEFAULT_NEW_ALPHA_POST_FIELDS, PostCreationError, AlphaPostInfo, AssetClass } from "../../utils/types";
import { AlphaPostWriterContext } from "./useAlphaPostWriter";

import { useUser } from "./useUser";
import { delay } from "../../utils/utils";


export interface NewAlphaPostInfo {
    creating: boolean;
    newPostContent: NewAlphaPostContent;
    includeChart: boolean;
    isRatingOn: boolean;
    errors: PostCreationError[];
}

export const getDefaultNewPostInfo = (): NewAlphaPostInfo => { return { creating: false, newPostContent: structuredClone(DEFAULT_NEW_ALPHA_POST_FIELDS.CONTENT), includeChart: false, isRatingOn: false, errors: [] } }

export function AlphaPostWriterProvider(
    { children }: any
) {

    const history = useHistory();
    const { currentUser } = useUser();

    const [isWritingPost, setIsWritingPost] = useState(false)
    const [isWriterDialogOpen, setIsWriterDialogOpen] = useState(false)
    const [newPostInfo, setNewPostInfo] = useState<NewAlphaPostInfo>(getDefaultNewPostInfo());

    useEffect(() => {
        if (isWritingPost) mixpanel.track("Open Write Post Modal")
        if (isWritingPost && window.location.pathname.indexOf("/create") === -1) history.push(window.location.pathname + "/create")
        if (!isWritingPost && window.location.pathname.indexOf("/create") !== -1) history.push(window.location.pathname.replace("/create", ""))
    }, [isWritingPost])

    async function createPost() {
        console.log("Creating post")
        let newAlphaPostContent: NewAlphaPostContent = newPostInfo.newPostContent;
        if (currentUser === null) return;

        // function checkPostContent(postContent: NewAlphaPostContent) {
        //     const errors: PostCreationError[] = []
        //     console.log(postContent)
        //     if (postContent.asset === null) errors.push(PostCreationError.Asset)

        //     if (postContent.postType === "alpha") {
        //         // const predictionInfo = postContent.prediction.alpha;
        //         // if (getHtmlTextLength(postContent.research) < MINIMUM_ALPHA_RESEARCH_LENGTH) errors.push(PostCreationError.Research)
        //         // if (predictionInfo.timeframe.start === undefined) errors.push(PostCreationError.TimeframeStart)
        //         // if (predictionInfo.timeframe.end === undefined) errors.push(PostCreationError.TimeframeEnd)
        //     } else if (postContent.postType === "technicalAnalysis") {
        //         const predictionInfo = postContent.prediction.technicalAnalysis;
        //         if (predictionInfo.entryTarget === undefined) errors.push(PostCreationError.EntryTarget)
        //         if (predictionInfo.exitTargets[0] === undefined) errors.push(PostCreationError.ExitTarget)

        //         if (predictionInfo.advancedOptions.invalidationPoint !== undefined && predictionInfo.entryTarget !== undefined) {
        //             if (postContent.position === "long" && predictionInfo.advancedOptions.invalidationPoint >= predictionInfo.entryTarget) errors.push(PostCreationError.HighInvalidationOnLong);
        //             if (postContent.position === "short" && predictionInfo.advancedOptions.invalidationPoint <= predictionInfo.entryTarget) errors.push(PostCreationError.LowInvalidationOnShort);
        //         }

        //         if (predictionInfo.advancedOptions.invalidationPoint === undefined && (predictionInfo.advancedOptions.timeframe.start === undefined || predictionInfo.advancedOptions.timeframe.end === undefined)) errors.push(PostCreationError.MissingPostEndPoint)
        //         if (newPostInfo.includeChart && (predictionInfo.advancedOptions.taChartUrl === null || predictionInfo.advancedOptions.taChartUrl.slice(0, 30) !== TA_TRADING_VIEW_CHART_IMAGE_BASE_URL)) errors.push(PostCreationError.TaChartUrl)
        //     }

        //     return errors;
        // }

        if (newAlphaPostContent.research.chartUrl?.length === 0) newAlphaPostContent.research.chartUrl = null;
        if (newAlphaPostContent.prediction) {
            if (newAlphaPostContent.prediction.asset === null) {
                newAlphaPostContent.prediction = null;
            } else {
                if (newAlphaPostContent.prediction.advancedOptions) {
                    if (newAlphaPostContent.prediction.advancedOptions.entryTarget === undefined
                        && newAlphaPostContent.prediction.advancedOptions.exitTargets.length === 0
                        && newAlphaPostContent.prediction.advancedOptions.invalidationPoint === undefined
                    ) {
                        newAlphaPostContent.prediction.advancedOptions = null;
                    }
                    // if (newAlphaPostContent.prediction.advancedOptions.optional.invalidationPoint === undefined) newAlphaPostContent.prediction.advancedOptions.optional.invalidationPoint = null;
                }
            }
        }
        setNewPostInfo({ ...newPostInfo, creating: true });

        mixpanel.track("Writing Post: Send Post", {
            postCreatorUserId: currentUser.userId,
            postIsPrediction: newAlphaPostContent.prediction !== null,
            postHasAdvancedOptions: newAlphaPostContent.prediction?.advancedOptions !== null,
            postAssetId: newAlphaPostContent.prediction?.asset?.assetId,
            postPosition: newAlphaPostContent.prediction?.position,
            postHasChart: newAlphaPostContent.research.chartUrl !== null
        })

        let finalNewAlphaPostContent: AlphaPostInfo["content"] = structuredClone(newAlphaPostContent) as any;
        if (finalNewAlphaPostContent.prediction?.asset && finalNewAlphaPostContent.title.length === 0) finalNewAlphaPostContent.title = `${finalNewAlphaPostContent.prediction.asset.info.symbol} ${finalNewAlphaPostContent.prediction.position}`;
        const imageFiles: File[] = [];
        for (let i = 0; i < newAlphaPostContent.research.media.length; i++) {
            const media = newAlphaPostContent.research.media[i];
            URL.revokeObjectURL(media.filePreview);
            imageFiles.push(media.file);
        }
        finalNewAlphaPostContent.research.media = [];
        console.log(finalNewAlphaPostContent)
        const result: any = await api.alphaPost.createPost(finalNewAlphaPostContent, imageFiles, newPostInfo.isRatingOn);

        if (!result.success || result.alphaPostInfo === null) {
            const errorMessage = result.error !== null ? result.error : "An error occured while creating alpha post."
            console.error(errorMessage);
            toast.error(errorMessage)
            setNewPostInfo({ ...newPostInfo, creating: false })
            return;
        }

        setNewPostInfo(getDefaultNewPostInfo());
        setIsWritingPost(false);
        // setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: false, alphaPost: result.alphaPostInfo });
        // if (verifyIfPostMeetsFilters(result.alphaPostInfo)) updatePostInPostsList(result.alphaPostInfo, postsListFetch, setPostsListFetch);
        history.push(window.location.pathname.replace("/create", "") + `/${result.alphaPostInfo.id}`);
    }

    return (
        <AlphaPostWriterContext.Provider
            value={{
                isWritingPost,
                setIsWritingPost,
                isWriterDialogOpen,
                setIsWriterDialogOpen,
                newPostInfo,
                setNewPostInfo,
                getDefaultNewPostInfo,
                createPost
            }}
        >
            {children}
        </AlphaPostWriterContext.Provider>
    );
}