import "./AlphaPostChat.css";
import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import { AdvancedChart } from "react-tradingview-embed";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faXmark } from "@fortawesome/free-solid-svg-icons";

import AlphaPostCommentWriter from "./AlphaPostCommentWriter";
import AlphaPostCommentViewer from "./AlphaPostCommentViewer";

import { AlphaPostAssetInfo, AlphaPostComments, AlphaPostComment, CoinInfo, TokenInfo } from "../../../utils/types";
import { CHAIN_SLUGS, CHAIN_SLUG_TO_GECKO_ID, FIRESTORE_COLLECTION_NAMES, MAIN_FORUM_TG_GROUP } from "../../../utils/constants";
import { firestoreDB } from "../../../utils/firebaseApp";
import { doc, onSnapshot } from "firebase/firestore";
import { useUser } from "../../../Components/Providers/useUser";
import api from "../../../utils/api/api";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

interface AlphaPostCommentsFetch {
    fetchStatus: "idle" | "fetching" | "success" | "error",
    alphaPostComments: AlphaPostComments | null,
}

const AlphaPostChat: FC<any> = (props) => {
    const { className, isActive, alphaPostId, alphaPostAsset, alphaPostCreatorId }: { className: string, isActive: boolean, alphaPostId: string, alphaPostAsset: AlphaPostAssetInfo | undefined, alphaPostCreatorId: string } = props;

    const [alphaPostCommentsFetch, setAlphaPostCommentsFetch] = useState<AlphaPostCommentsFetch>({ fetchStatus: "idle", alphaPostComments: null });
    const [commentReply, setCommentReply] = useState<AlphaPostComment | null>(null);
    const { currentUser, setConnectionDialogOpen } = useUser();

    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!isActive) return;
        if (alphaPostId) {
            setAlphaPostCommentsFetch({ alphaPostComments: null, fetchStatus: "fetching" });
            const unsub = onSnapshot(doc(firestoreDB, FIRESTORE_COLLECTION_NAMES.alphaClub.main, "alphaPosts", FIRESTORE_COLLECTION_NAMES.alphaClub.postsComments, alphaPostId), async (doc) => {
                const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                if (source === "Local") return;
                const alphaPostComments: AlphaPostComments | null = (doc.data() ?? null) as AlphaPostComments | null;
                setAlphaPostCommentsFetch({ ...alphaPostCommentsFetch, alphaPostComments, fetchStatus: "success" });
            });
            return () => unsub();
        }
    }, [alphaPostId, isActive])

    useEffect(() => {
        scrollToBottom();
    }, [alphaPostCommentsFetch.alphaPostComments, scrollContainerRef.current])

    function scrollToBottom() {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }

    function getFetchedCommentById(commentId: string) {
        return alphaPostCommentsFetch.alphaPostComments?.comments.find((comment) => comment.id === commentId);
    }

    async function sendComment(newCommentContent: AlphaPostComment["content"], imageFiles: File[]) {
        if (!currentUser) {
            setConnectionDialogOpen(true);
            return { success: false };
        }
        if (alphaPostCommentsFetch.fetchStatus !== "success") return { success: false };
        const createCommentResult = await api.alphaPost.createComment(alphaPostId, newCommentContent, imageFiles, commentReply?.id);
        if (createCommentResult.success && createCommentResult.alphaPostComment) {
            console.log("Comment created successfully.");
            setAlphaPostCommentsFetch({ ...alphaPostCommentsFetch, alphaPostComments: { ...alphaPostCommentsFetch.alphaPostComments!, comments: alphaPostCommentsFetch.alphaPostComments ? [...alphaPostCommentsFetch.alphaPostComments!.comments, createCommentResult.alphaPostComment] : [createCommentResult.alphaPostComment] } })
            setCommentReply(null);
        }
        return createCommentResult;
    }

    async function deleteComment(commentId: string) {
        const deletedComment = alphaPostCommentsFetch.alphaPostComments?.comments.find((comment) => comment.id === commentId);
        if (deletedComment) setAlphaPostCommentsFetch({ ...alphaPostCommentsFetch, alphaPostComments: { ...alphaPostCommentsFetch.alphaPostComments!, comments: alphaPostCommentsFetch.alphaPostComments!.comments.filter((comment) => comment.id !== commentId) } })
        const response = await api.alphaPost.deleteComment(alphaPostId, commentId);
        if (!response.success && deletedComment) {
            undeleteComment(deletedComment);
        }
    }

    function undeleteComment(deletedComment: AlphaPostComment) {
        const filteredComments = alphaPostCommentsFetch.alphaPostComments?.comments.filter((comment) => comment.id !== deletedComment.id);
        if (!filteredComments) return;
        for (const comment of filteredComments) {
            if (comment.creation.timestamp > deletedComment.creation.timestamp) {
                filteredComments.splice(filteredComments.indexOf(comment), 0, deletedComment);
                break;
            }
        }
        setAlphaPostCommentsFetch({ ...alphaPostCommentsFetch, alphaPostComments: { ...alphaPostCommentsFetch.alphaPostComments!, comments: [...filteredComments] } })
    }

    const tradingViewChart = React.useMemo(() =>
        <div>
            {/* {selectedPostFetch.alphaPost !== null && selectedPostFetch.alphaPost.content.token !== null && <iframe height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/eth/pools/${}?embed=1&info=1&swaps=1`} frameBorder="0" allow="clipboard-write" allowFullScreen></iframe>} */}
            {alphaPostAsset ?
                alphaPostAsset.type === "coin" ? alphaPostAsset !== null && <AdvancedChart widgetProps={{ "theme": "dark", width: "100%", height: "500px", style: "1", symbol: `${(alphaPostAsset.info as CoinInfo).symbol}USD`, allow_symbol_change: false }} />
                    : alphaPostAsset.type === "token" ? <iframe height="500px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/${CHAIN_SLUG_TO_GECKO_ID[CHAIN_SLUGS[(alphaPostAsset.info as TokenInfo).networkId]]}/pools/${(alphaPostAsset.info as TokenInfo).topPoolId}?embed=1&info=0&swaps=0`} frameBorder="0" allow="clipboard-write" allowFullScreen></iframe>
                        : "" : ""
            }
            {/* {selectedPostFetch.alphaPost.content.token !== null && <AdvancedRealTimeChart theme="dark" width="100%" height="500px" symbol={`${selectedPostFetch.alphaPost.content.token.symbol}USD`} allow_symbol_change={false} />} */}
        </div>, [alphaPostAsset]);

    return (
        <div id="alpha-post-chat" className={`${className} flex flex-col pe-2`}>
            <div className="scroll-container flex flex-col max-h-[500px] overflow-y-scroll pb-5" ref={scrollContainerRef}>
                <div className="sticky top-0 flex items-center bg-neutral-900 z-10">
                    <h3 className="text-header header-gradient font-bold">Live Chat</h3>
                    <a href={MAIN_FORUM_TG_GROUP(alphaPostAsset?.type ?? null)} target="_blank" rel="noopener noreferrer" className="ms-2">
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </a>
                </div>
                {
                    alphaPostCommentsFetch.fetchStatus === "fetching" ? <>
                        <div className="loading-container w-full h-[70px] my-2 rounded-lg"></div>
                        <div className="loading-container w-full h-[70px] my-2 rounded-lg"></div>
                    </> :
                        alphaPostCommentsFetch.fetchStatus === "success" ? <>
                            {alphaPostCommentsFetch.alphaPostComments ? <>{alphaPostCommentsFetch.alphaPostComments.comments.map((comment) => {
                                const previousMessage = alphaPostCommentsFetch.alphaPostComments!.comments[alphaPostCommentsFetch.alphaPostComments!.comments.indexOf(comment) - 1]
                                const seperateFromPreviousMessage = previousMessage?.creation.user.userId !== comment.creation.user.userId || (comment.creation.timestamp - previousMessage.creation.timestamp) > 10 * 60 * 1000;
                                const userIsMessageSender = comment.creation.user.userId === currentUser?.userId;
                                const commenterIsPostCreator = comment.creation.user.userId === alphaPostCreatorId;
                                return <AlphaPostCommentViewer key={comment.id} comment={comment} seperateFromPreviousMessage={seperateFromPreviousMessage} userIsMessageSender={userIsMessageSender} commenterIsPostCreator={commenterIsPostCreator} deleteComment={deleteComment} commentReply={commentReply} setCommentReply={setCommentReply} getFetchedCommentById={getFetchedCommentById} />
                            })}
                            </> : <h6 className="text-white mx-auto my-5">No remarks. Be the first to comment!</h6>}
                            {/* {
                                    state === "open" ? <AlphaPostCommentWriter tradingViewChart={tradingViewChart} sendComment={sendComment} /> :
                                        <h3 className="mx-auto mt-4">Post closed.</h3>
                                } */}
                        </> : null
                }
            </div>
            {alphaPostCommentsFetch.fetchStatus === "success" ? <AlphaPostCommentWriter className="bg-neutral-900" tradingViewChart={tradingViewChart} chartEnabled={alphaPostAsset && (alphaPostAsset.type === "coin" || alphaPostAsset.type === "token")} commentReply={commentReply} setCommentReply={setCommentReply} sendComment={sendComment} /> : ""}
        </div>
    )
}

export default AlphaPostChat;
