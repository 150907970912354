import "./AlphaPostViewerModal.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faClock, faArrowTrendUp, faArrowTrendDown, faTrash, faLink } from '@fortawesome/free-solid-svg-icons'
import { faEthereum, faTwitter } from "@fortawesome/free-brands-svg-icons"

import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
    Dialog,
    DialogHeader,
    DialogBody,
    Popover,
    PopoverHandler,
    PopoverContent,
    Menu,
    MenuHandler,
    MenuList,
} from "@material-tailwind/react";


import AlphaPostChat from "./AlphaPostChat";
import ErrorContainer from "../../../Components/ErrorContainer"
import CopiableElement from "../../../Components/CopiableElement";
import TokenSecurityMetricsDrawer from "./TokenSecurityMetricsDrawer";

import { ReactComponent as UnibotIcon } from "../../../media/icons/unibotIcon.svg";
import { ReactComponent as CaretUp } from "../../../media/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../../../media/icons/CaretDown.svg";
import { ReactComponent as ShareIcon } from "../../../media/icons/shareIcon.svg";
import { ReactComponent as OutlineBookmarkIcon } from "../../../media/icons/outlineBookmarkIcon.svg";
import { ReactComponent as BookmarkIcon } from "../../../media/icons/bookmarkIcon.svg";
import { ReactComponent as EthIcon } from "../../../media/icons/ethereumIcon.svg";
import loadingIconAnim from "../../../media/icons/loadingIcon.gif"
import friendTechIcon from "../../../media/icons/friendTechIcon.png";

import api from "../../../utils/api/api"
import { PostTokenSecurityMetricsFetch, PostTokenPoolStatsFetch, SelectedPostFetch } from "../types"
import { timestampToSmallDate, timestampToRelative, timestampToClockTime, getFullBaseUrl, delay, numberWithCommas, getRoundedPrice, getScrollBarWidth, formatNumberForPrice, randomGradientImg, getSharePostOnTwitterLink } from "../../../utils/utils"
import { PostInteraction, TokenInfo, NFTInfo, AssetClass, FtShareInfo } from "../../../utils/types"
import { useUser } from "../../../Components/Providers/useUser"
import { ALPHA_TIME_RANGE_ICONS, CHAIN_SLUGS, CHAIN_SLUG_TO_GECKO_ID, MAIN_WEBSITE_URL, MAX_TIME_TO_DELETE_ALPHA_POST, UNIBOT_REF_CODE } from "../../../utils/constants";

import createDOMPurify from "dompurify";
import mixpanel from "mixpanel-browser";

const purify = createDOMPurify(window);

const AlphaPostViewerModal: FC<any> = (props) => {
    const { assetClass, fetchSearchedAlphaPostInfo, selectedPostFetch, setSelectedPostFetch, interactWithPost, removePostFromPostsList, toggleSavePost }: { assetClass: AssetClass | null, fetchSearchedAlphaPostInfo: Function, selectedPostFetch: SelectedPostFetch, setSelectedPostFetch: Function, interactWithPost: Function, removePostFromPostsList: Function, toggleSavePost: Function } = props;

    const history = useHistory();
    const location = useLocation();

    const { currentUser } = useUser();
    const sharePostButtonRef = useRef<HTMLButtonElement>(null);

    const [postTokenSecurityMetricsFetch, setPostTokenSecurityMetricsFetch] = useState<PostTokenSecurityMetricsFetch>({ tokenSecurityMetrics: null, fetching: false, drawerOpen: false, drawerVisible: false })
    const [postTokenPoolStatsFetch, setPostTokenPoolStatsFetch] = useState<PostTokenPoolStatsFetch>({ tokenPoolStats: null, fetching: false })
    const [ftShareInfoMetricsFetch, setFtShareInfoMetricsFetch] = useState<{ ftShareInfoMetrics: FtShareInfo["metrics"] | null, fetching: boolean }>({ ftShareInfoMetrics: null, fetching: false });

    const [selectedPostTimeLeftToDelete, setSelectedPostTimeLeftToDelete] = useState<number>(1);

    // useEffect(() => {
    //     // if (searchedAlphaPostId !== undefined || assetClass === undefined) return;
    //     if (selectedPostFetch.alphaPost === null) return;
    //     history.push(`${location.pathname.replace(`/${selectedPostFetch.alphaPost.id}`, "")}/${selectedPostFetch.alphaPost.id}`);
    // }, [selectedPostFetch.alphaPost])

    useEffect(() => {
        if (!selectedPostFetch.alphaPost?.content.prediction?.asset) return;
        if (selectedPostFetch.alphaPost.content.prediction.asset.type === "token") {
            fetchPostTokenPoolStats()
            fetchPostTokenSecurityMetrics()
            const fetchTokenPoolStatsInterval = setInterval(() => fetchPostTokenPoolStats(false), 30000);
            return () => clearInterval(fetchTokenPoolStatsInterval);
        } else if (selectedPostFetch.alphaPost.content.prediction.asset.type === "ftShare") {
            fetchPostTokenFtShareInfo()
        }
    }, [selectedPostFetch.alphaPost?.content.prediction?.asset])

    async function fetchPostTokenPoolStats(indicateFetch: boolean = true) {
        if (selectedPostFetch.alphaPost?.content.prediction?.asset?.type !== "token") return;
        console.log("fetching token pool stats")
        setPostTokenPoolStatsFetch(prevPostTokenPoolStatsFetch => ({ ...prevPostTokenPoolStatsFetch, fetching: indicateFetch }));
        const response = await api.defi.getTokenPoolStats(selectedPostFetch.alphaPost?.content.prediction.asset.info as TokenInfo);
        setPostTokenPoolStatsFetch({ tokenPoolStats: response, fetching: false });
    }

    async function fetchPostTokenSecurityMetrics(indicateFetch: boolean = true) {
        if (selectedPostFetch.alphaPost?.content.prediction?.asset?.type !== "token") return;
        console.log("fetching token security metrics")
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, fetching: indicateFetch });
        const response = await api.defi.getTokenSecurityMetrics(selectedPostFetch.alphaPost?.content.prediction.asset.info as TokenInfo);
        await delay(100);
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, tokenSecurityMetrics: response, fetching: false, drawerVisible: true });
    }

    async function fetchPostTokenFtShareInfo() {
        // if (!selectedPostFetch.alphaPost?.content.asset || selectedPostFetch.alphaPost.content.asset.type !== "ftShare"
        if (selectedPostFetch.alphaPost?.content.prediction?.asset?.type !== "ftShare"
            // || ftShareInfoMetricsFetch.fetching || ftShareInfoMetricsFetch.ftShareInfoMetrics !== null
        ) return;
        console.log("fetching ft share info")
        setFtShareInfoMetricsFetch({ ...ftShareInfoMetricsFetch, fetching: true });
        const response = await api.onchain.getFtShareInfo((selectedPostFetch.alphaPost.content.prediction.asset.info as FtShareInfo).address);
        setFtShareInfoMetricsFetch({ fetching: false, ftShareInfoMetrics: (response?.metrics ?? null) });
        if (response !== undefined) {
            const changedSelectedPostFetch = { ...selectedPostFetch };
            changedSelectedPostFetch.alphaPost!.content.prediction!.asset!.info = { ...response, metrics: (changedSelectedPostFetch.alphaPost!.content.prediction!.asset!.info as FtShareInfo).metrics ? { ...(changedSelectedPostFetch.alphaPost!.content.prediction!.asset!.info as FtShareInfo).metrics! } : response.metrics };
            setSelectedPostFetch(changedSelectedPostFetch);
        }
    }

    useEffect(() => {
        const alphaPostModalParentElement = document.body.querySelector("#alpha-post-modal")?.parentElement;
        if (!alphaPostModalParentElement) return;
        if (postTokenSecurityMetricsFetch.drawerOpen) {
            alphaPostModalParentElement.scrollTop = 0;
            alphaPostModalParentElement.style.setProperty("overflow-y", "hidden");
            alphaPostModalParentElement.style.setProperty("padding-right", `${getScrollBarWidth()}px`);
        } else {
            alphaPostModalParentElement.style.setProperty("overflow-y", "scroll");
            alphaPostModalParentElement.style.removeProperty("padding-right");
        }
    }, [postTokenSecurityMetricsFetch.drawerOpen])

    // useEffect(() => {
    //     updatePostTimeLeftToDelete();
    // }, [selectedPostFetch.alphaPost, currentUser, selectedPostTimeLeftToDelete])
    // async function updatePostTimeLeftToDelete() {
    //     if (selectedPostFetch.alphaPost !== null && selectedPostFetch.alphaPost.creation.user.userId === currentUser?.userId) {
    //         if (selectedPostTimeLeftToDelete !== 0 && selectedPostTimeLeftToDelete !== undefined) await delay(1000)
    //         setSelectedPostTimeLeftToDelete(Math.max(selectedPostFetch.alphaPost.creation.timestamp + MAX_TIME_TO_DELETE_ALPHA_POST - Date.now(), 0))
    //     } else {
    //         setSelectedPostTimeLeftToDelete(0)
    //     }
    // }

    function closeSelectedPost() {
        const closedPostPath = location.pathname.slice(1, location.pathname.length).split("/").slice(0, -1).join("/");
        history.push(`/${closedPostPath}`);
        // history.push(`/big-board/${assetClass.valueOf()}`)
        setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: false, drawerVisible: false });
        setTimeout(() => setSelectedPostFetch({ alphaPost: null, fetching: false, fetchError: false, deleting: false }), 100)
    }

    async function tryDeleteSelectedPost() {
        if (currentUser === null || selectedPostFetch.alphaPost === null) return;
        if (selectedPostFetch.alphaPost?.creation.user.userId !== currentUser?.userId || selectedPostTimeLeftToDelete === undefined || selectedPostTimeLeftToDelete <= 0) return;
        setSelectedPostFetch({ ...selectedPostFetch, deleting: true, fetchError: false });

        const success = await api.alphaPost.deletePost(selectedPostFetch.alphaPost.id);
        if (success) {
            mixpanel.track("Post: Delete", {
                timeLeftToDelete: selectedPostTimeLeftToDelete,
                postCreatorIsEliteMember: selectedPostFetch.alphaPost?.creation.user.isEliteCreator,
            })
            removePostFromPostsList(selectedPostFetch.alphaPost.id)
            closeSelectedPost();
            return;
        }
        setSelectedPostFetch({ ...selectedPostFetch, deleting: false, fetchError: false });
    }

    const postPredictionTimeframe = selectedPostFetch.alphaPost?.content.prediction?.timeframe ?? null;

    // const currencySpan = <span className="currency-icon">{selectedPostFetch.alphaPost?.content.asset.type !== "nft" ? "$" : <img src={(selectedPostFetch.alphaPost?.content.asset.info as NFTInfo).pricingIcon} />}</span>
    const currencySpan = <span className="currency-icon">{selectedPostFetch.alphaPost?.content.prediction?.asset ? selectedPostFetch.alphaPost.content.prediction.asset.assetClass === AssetClass.Crypto ? "$" : selectedPostFetch.alphaPost.content.prediction.asset.assetClass === AssetClass.FriendTech ? <EthIcon className="w-[10px] h-auto ms-1" /> : <img src={(selectedPostFetch.alphaPost.content.prediction.asset.info as NFTInfo).pricingIcon} /> : ""}</span>;

    return (
        <Dialog
            id="alpha-post-modal"
            className="bg-neutral-900 text-white border border-neutral-800 rounded-2xl m-0 z-[9999]"
            open={selectedPostFetch.fetching || selectedPostFetch.alphaPost !== null}
            handler={closeSelectedPost}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
            size="lg"
        >
            <DialogHeader>
                {(selectedPostFetch.fetching || selectedPostFetch.alphaPost !== null) && <h5 className="header-gradient font-bold">{selectedPostFetch.alphaPost === null ? <div className="loading-square" style={{ width: "300px", height: "45px" }}></div> : selectedPostFetch.alphaPost.content.title}</h5>}
                <button className="btn-simple text-white ms-auto" onClick={closeSelectedPost}><FontAwesomeIcon icon={faXmark} /></button>
            </DialogHeader>
            <DialogBody divider className="flex flex-col text-white border-b-0 py-4 p-sm-3">
                {!selectedPostFetch.fetching && selectedPostFetch.fetchError ? <ErrorContainer text="Could not load post." buttonText="Try again" onClick={fetchSearchedAlphaPostInfo} className="mt-5" />
                    : <div id="selected-alpha-post" className="flex flex-col">
                        <div className="flex">
                            <div className="alpha-post-interactions hidden md:flex flex-col items-center">
                                <CaretUp className={`svg-inline--fa fa-caret-up hover:cursor-pointer fa-2x ${currentUser !== null && selectedPostFetch.alphaPost?.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection" : ""}`} onClick={() => selectedPostFetch.alphaPost !== null && interactWithPost(selectedPostFetch.alphaPost, PostInteraction.Upvote)} />
                                {selectedPostFetch.alphaPost === null ? <div className="loading-square" style={{ width: "50px", height: "25px" }}></div> : selectedPostFetch.alphaPost.interactions.upvote.accounts.length - selectedPostFetch.alphaPost.interactions.downvote.accounts.length}
                                <CaretDown className={`svg-inline--fa fa-caret-down hover:cursor-pointer fa-2x ${currentUser !== null && selectedPostFetch.alphaPost?.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error" : ""}`} onClick={() => selectedPostFetch.alphaPost !== null && interactWithPost(selectedPostFetch.alphaPost, PostInteraction.Downvote)} />

                                {selectedPostFetch.alphaPost !== null && <div className="flex flex-col items-center mt-3">
                                    <Popover placement="right">
                                        <PopoverHandler>
                                            <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><ShareIcon /></button>
                                        </PopoverHandler>
                                        <PopoverContent className="z-[9999]">
                                            <div className="flex items-center text-black mb-2">
                                                <CopiableElement copy={`${getFullBaseUrl(window)}/big-board/${assetClass ? assetClass.valueOf() : "general"}/${selectedPostFetch.alphaPost.id}`} component={<span><FontAwesomeIcon icon={faLink} className="w-[20px] me-[0.8rem]" /> Copy link to post</span>} />
                                            </div>
                                            <div className="flex items-center mt-2">
                                                <a style={{ textDecoration: "none", color: "black" }} href={getSharePostOnTwitterLink(selectedPostFetch.alphaPost)} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                    <button className="btn-simple mt-2" onClick={() => toggleSavePost(selectedPostFetch.alphaPost)}>
                                        {currentUser?.confidential.userAlphaInfo[selectedPostFetch.alphaPost.content.prediction?.asset.type === "token" ? "crypto" : selectedPostFetch.alphaPost.content.prediction?.asset.type === "nft" ? "nft" : selectedPostFetch.alphaPost.content.prediction?.asset.type === "ftShare" ? "friendTech" : "global"]?.savedPostIds.includes(selectedPostFetch.alphaPost.id) ? <BookmarkIcon /> : <OutlineBookmarkIcon />}
                                    </button>
                                </div>}
                            </div>
                            <div className="alpha-post-info flex flex-col w-full md:ps-5">
                                {
                                    selectedPostFetch.alphaPost?.content.prediction?.asset ?
                                        <div className="alpha-post-details flex flex-wrap items-center text-normal mt-2">
                                            <div className="flex items-center token-info ms-1 me-3 my-1">
                                                {selectedPostFetch.alphaPost === null ? <div className="loading-square w-[90px]"></div> : <a className="simple-anchor flex items-center" target="_blank" rel="noopener noreferrer" href={selectedPostFetch.alphaPost.content.prediction.asset.type === "coin" ? `https://www.tradingview.com/symbols/${selectedPostFetch.alphaPost.content.prediction.asset.info.symbol}USDC/` : selectedPostFetch.alphaPost.content.prediction.asset.type === "token" ? `https://www.geckoterminal.com/${CHAIN_SLUG_TO_GECKO_ID[CHAIN_SLUGS[(selectedPostFetch.alphaPost.content.prediction.asset.info as TokenInfo).networkId]]}/pools/${(selectedPostFetch.alphaPost.content.prediction.asset.info as TokenInfo).topPoolId}` : selectedPostFetch.alphaPost.content.prediction.asset.type === "nft" ? `https://opensea.io/collection/${(selectedPostFetch.alphaPost.content.prediction.asset.info as NFTInfo).slug}` : selectedPostFetch.alphaPost.content.prediction.asset.type === "ftShare" ? `https://www.friend.tech/rooms/${(selectedPostFetch.alphaPost.content.prediction.asset.info as FtShareInfo).address}` : ""}>
                                                    <img src={selectedPostFetch.alphaPost.content.prediction.asset.info.iconUrl} onError={({ currentTarget }) => {
                                                        (currentTarget as HTMLImageElement).onerror = null;
                                                        (currentTarget as HTMLImageElement).src = selectedPostFetch.alphaPost?.content.prediction!.asset!.type === "ftShare" ? friendTechIcon : randomGradientImg();
                                                    }} className="token-icon me-2" /> {selectedPostFetch.alphaPost.content.prediction.asset.info.symbol} ({selectedPostFetch.alphaPost.content.prediction.asset.type === "ftShare" ? selectedPostFetch.alphaPost.content.prediction.asset.priceAtCreation : numberWithCommas(getRoundedPrice(selectedPostFetch.alphaPost.content.prediction.asset.priceAtCreation))} {currencySpan})
                                                </a>}
                                            </div>
                                            <div className={`flex items-center price-range-info mx-2 my-1 order-last`}>
                                                {/* {selectedPostFetch.alphaPost === null ? <div className="loading-square w-[90px]"></div> : <span><FontAwesomeIcon icon={selectedPostFetch.alphaPost.content.position === "long" ? faArrowTrendUp : faArrowTrendDown} className="me-2" /> {selectedPostFetch.alphaPost.content.postType === "technicalAnalysis" ? numberWithCommas(getRoundedPrice(selectedPostFetch.alphaPost.content.prediction.technicalAnalysis!.exitTargets[0])) + "$" : ""}</span>} */}
                                                {selectedPostFetch.alphaPost === null ? <div className="loading-square w-[90px]"></div> :
                                                    <div className="flex items-center">
                                                        {selectedPostFetch.alphaPost.content.prediction.advancedOptions?.entryTarget ? <span className="text-white">{numberWithCommas(getRoundedPrice(selectedPostFetch.alphaPost.content.prediction.advancedOptions.entryTarget))} {currencySpan}</span> :
                                                            ""}
                                                        <FontAwesomeIcon icon={selectedPostFetch.alphaPost.content.prediction.position === "long" ? faArrowTrendUp : faArrowTrendDown} className={`${selectedPostFetch.alphaPost.content.prediction.position === "long" ? "text-selection" : "text-error"} mx-2`} />
                                                        <span className={selectedPostFetch.alphaPost.content.prediction.position === "long" ? "text-selection" : "text-error"}>
                                                            {selectedPostFetch.alphaPost.content.prediction.advancedOptions ? <>{numberWithCommas(getRoundedPrice(selectedPostFetch.alphaPost.content.prediction.advancedOptions.exitTargets[0]))} {currencySpan}</> :
                                                                ""}
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            {postPredictionTimeframe !== null && <div className="flex items-center time-range-info mx-2 my-1 md:order-last">
                                                {selectedPostFetch.alphaPost === null ? <div className="loading-square" style={{ width: "90px" }}></div> : <span className="flex items-center">
                                                    <img src={ALPHA_TIME_RANGE_ICONS[selectedPostFetch.alphaPost.content.prediction.timeframeRange]} className="h-[20px] w-auto me-2" />
                                                    {timestampToSmallDate(postPredictionTimeframe.start / 1000)} {postPredictionTimeframe.end - postPredictionTimeframe.start <= 60_000 * 60 * 24 ? `(${timestampToClockTime(postPredictionTimeframe.start / 1000)}) ` : ""}- {timestampToSmallDate(postPredictionTimeframe.end / 1000)} {postPredictionTimeframe.end - postPredictionTimeframe.start <= 60_000 * 60 * 24 ? `(${timestampToClockTime(postPredictionTimeframe.end / 1000)})` : ""}
                                                </span>}
                                            </div>}
                                            {
                                                selectedPostFetch.alphaPost.content.prediction.advancedOptions?.invalidationPoint && <div className="flex ps-3 order-last" style={{ fontSize: "0.9rem" }}>
                                                    <i>Invalidation Point: {selectedPostFetch.alphaPost.content.prediction.advancedOptions.invalidationPoint} {currencySpan}</i>
                                                </div>
                                            }
                                            {selectedPostFetch.alphaPost !== null ? <a href={`https://t.me/unibotsniper_bot?start=${UNIBOT_REF_CODE}-${selectedPostFetch.alphaPost ? (selectedPostFetch.alphaPost.content.prediction.asset.info as TokenInfo).address : ""}`} target="_blank" rel="noopener noreferrer" className="flex items-center text-header unibot-background rounded-full order-last ms-3 my-1 px-3 py-1">
                                                <UnibotIcon className="w-[25px] h-[25px] me-2" /> Buy with Unibot
                                            </a> : ""}
                                        </div> : ""
                                }


                                {/* <h4 className="alpha-post-title text-header">{selectedPostFetch.alphaPost === null ? <div className="loading-square mx-0" style={{ width: "250px" }}></div> : selectedPostFetch.alphaPost.content.title} {selectedPostFetch.alphaPost?.creation.user.isEliteCreator && <span title="Elite Club Member"><FontAwesomeIcon icon={faChessKnight} className="elite-member-icon" style={{ fontSize: "1.3rem" }} /></span>}</h4> */}

                                {
                                    selectedPostFetch.alphaPost?.content.prediction?.asset?.type === "token" ? <>
                                        <div id="alpha-post-token-pool-stats" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
                                            <div className="flex flex-col mb-2 lg:mb-0">
                                                {/* <h5 className="pool-stat-header mb-0">Price</h5> */}
                                                <div className="flex items-center">
                                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
                                                        <h2 className="mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.price ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.price)}` : "-"}</h2>
                                                        <h6 className={`ms-2 mb-0 ${(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? "text-selection" : "text-error"}`}>{(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? <CaretUp /> : <CaretDown />} {postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h.toFixed(2) ?? "-"}%</h6>
                                                    </>}
                                                </div>
                                            </div>
                                            <div className="flex lg:ms-auto">
                                                <div className="flex flex-col items-center mx-3">
                                                    <h6 className="pool-stat-header text-neutral-400 mb-1">Volume</h6>
                                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.volume24h ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.volume24h, true)}` : "-"}</h5>}
                                                </div>
                                                <div className="flex flex-col items-center mx-3">
                                                    <h6 className="pool-stat-header text-neutral-400 mb-1">Liquidity</h6>
                                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.liquidity ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.liquidity, true)}` : "-"}</h5>}
                                                </div>
                                                <div className="flex flex-col items-center mx-3">
                                                    <h6 className="pool-stat-header text-neutral-400 mb-1">FDV</h6>
                                                    {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.fdv ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.fdv, true)}` : "-"}</h5>}
                                                </div>
                                            </div>
                                        </div>
                                        <button id="alpha-post-token-security-metrics-btn" className="btn-primary py-2 rounded-lg" onClick={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: true })}>Audit Scan</button>
                                    </> : selectedPostFetch.alphaPost?.content.prediction?.asset?.type === "ftShare" ?
                                        <div id="alpha-post-alpha-post-ft-share-metrics" className="flex flex-col lg:flex-row items-center bg-neutral-800 rounded-xl border border-neutral-600 px-4 py-2 mt-2 mb-3">
                                            <div className="flex flex-col mb-2 lg:mb-0">
                                                <div className="flex items-center">
                                                    {ftShareInfoMetricsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
                                                        <h2 className="mb-0">{ftShareInfoMetricsFetch.ftShareInfoMetrics !== null ? ftShareInfoMetricsFetch.ftShareInfoMetrics!.price : "-"}</h2>
                                                        <EthIcon className="w-[15px] h-auto ms-2" />
                                                    </>}
                                                </div>
                                            </div>
                                            <div className="flex lg:ms-auto">
                                                <div className="flex flex-col items-center mx-3">
                                                    <h6 className="pool-stat-header mb-1">Shares</h6>
                                                    {ftShareInfoMetricsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{ftShareInfoMetricsFetch.ftShareInfoMetrics !== null ? ftShareInfoMetricsFetch.ftShareInfoMetrics!.shareSupply : "-"}</h5>}
                                                </div>
                                                <div className="flex flex-col items-center mx-3">
                                                    <h6 className="pool-stat-header mb-1">Holders</h6>
                                                    {ftShareInfoMetricsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{ftShareInfoMetricsFetch.ftShareInfoMetrics !== null ? ftShareInfoMetricsFetch.ftShareInfoMetrics!.holderCount : "-"}</h5>}
                                                </div>
                                            </div>
                                        </div> : ""
                                }

                                <div id="alpha-post-research" className="mt-4 break-words">{selectedPostFetch.alphaPost === null ? <div className="loading-square !w-full !h-[150px]"></div> : <span className="block" dangerouslySetInnerHTML={{ __html: purify.sanitize(selectedPostFetch.alphaPost?.content.research.content) }}></span>}</div>
                                {
                                    selectedPostFetch.alphaPost?.content.research.chartUrl ? <div className="alpha-post-ta-chart mt-2 mb-3">
                                        {selectedPostFetch.alphaPost.content.research.chartUrl !== null && <LazyLoadImage
                                            height={window.innerWidth > 768 ? 500 : 300}
                                            src={selectedPostFetch.alphaPost.content.research.chartUrl} // use normal <img> attributes as props
                                            width={1}
                                            className="w-full h-auto" />}
                                    </div> : ""
                                }

                                {selectedPostFetch.alphaPost && selectedPostFetch.alphaPost.content.research.media.length > 0 ? <div id="alpha-post-modal-research-media-container" className="flex w-full px-3 pb-5 mt-5">
                                    <div className={`grid grid-cols-${selectedPostFetch.alphaPost.content.research.media.length >= 2 ? "2" : "1"} grid-rows-${selectedPostFetch.alphaPost.content.research.media.length >= 3 ? "2" : "1"} w-full`}>
                                        {
                                            selectedPostFetch.alphaPost.content.research.media.map((media, index) => {
                                                return <div key={index} className="relative w-full p-2">
                                                    <img src={media.url} className="w-full h-auto object-cover rounded-md" />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div> : ""}

                                <div className="alpha-post-creation relative flex flex-col sm:flex-row justify-between md:justify-end mt-3 px-2">
                                    <div className="alpha-post-interactions flex md:hidden items-center mb-2">
                                        <CaretUp className={`svg-inline--fa fa-caret-up hover:cursor-pointer fa-2x mx-2 ${currentUser !== null && selectedPostFetch.alphaPost?.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection" : ""}`} onClick={() => selectedPostFetch.alphaPost !== null && interactWithPost(selectedPostFetch.alphaPost, PostInteraction.Upvote)} />
                                        {selectedPostFetch.alphaPost === null ? <div className="loading-square" style={{ width: "50px", height: "25px" }}></div> : selectedPostFetch.alphaPost.interactions.upvote.accounts.length - selectedPostFetch.alphaPost.interactions.downvote.accounts.length}
                                        <CaretDown className={`svg-inline--fa fa-caret-down hover:cursor-pointer fa-2x mx-2 ${currentUser !== null && selectedPostFetch.alphaPost?.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error" : ""}`} onClick={() => selectedPostFetch.alphaPost !== null && interactWithPost(selectedPostFetch.alphaPost, PostInteraction.Downvote)} />

                                        {selectedPostFetch.alphaPost !== null && <div className="flex ms-3">
                                            <Popover placement="right">
                                                <PopoverHandler>
                                                    <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><ShareIcon /></button>
                                                </PopoverHandler>
                                                <PopoverContent className="z-[9999]">
                                                    <div className="flex items-center text-black mb-2">
                                                        <CopiableElement copy={`${getFullBaseUrl(window)}/big-board/${selectedPostFetch.alphaPost.id}`} component={<span><FontAwesomeIcon icon={faLink} className="w-[20px] me-[0.8rem]" /> Copy link to post</span>} />
                                                    </div>
                                                    <div className="flex items-center mt-2">
                                                        <a style={{ textDecoration: "none", color: "black" }} href={getSharePostOnTwitterLink(selectedPostFetch.alphaPost)} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                                                    </div>
                                                </PopoverContent>
                                            </Popover>
                                            <button className="btn-simple ms-2" onClick={() => toggleSavePost(selectedPostFetch.alphaPost)}>
                                                {currentUser?.confidential.userAlphaInfo[selectedPostFetch.alphaPost.content.prediction?.asset.type === "token" ? "crypto" : selectedPostFetch.alphaPost.content.prediction?.asset.type === "nft" ? "nft" : selectedPostFetch.alphaPost.content.prediction?.asset.type === "ftShare" ? "friendTech" : "global"]?.savedPostIds.includes(selectedPostFetch.alphaPost.id) ? <BookmarkIcon /> : <OutlineBookmarkIcon />}
                                            </button>
                                        </div>}
                                    </div>
                                    <div className="flex flex-col text-sm text-primary ms-auto z-10">
                                        {selectedPostFetch.alphaPost === null ? <div className="loading-square w-[200px]"></div> : <span className="flex items-center">
                                            <img src={selectedPostFetch.alphaPost.creation.user.pfpUrl!} className="pfp-icon me-2" />
                                            <Link to={`/profile/a/${selectedPostFetch.alphaPost.creation.user.userId}`}>{selectedPostFetch.alphaPost.creation.user.username}</Link> · {timestampToRelative(selectedPostFetch.alphaPost.creation.timestamp, true)}
                                            {selectedPostFetch.alphaPost && selectedPostFetch.alphaPost?.creation.user.userId === currentUser?.userId && !selectedPostFetch.alphaPost.analysis.toBeAnalyzed && selectedPostTimeLeftToDelete !== undefined && selectedPostTimeLeftToDelete > 0 && <div className="alpha-post-delete-timer flex justify-end me-2 text-sm">
                                                <Menu
                                                    placement="bottom-end"
                                                    dismiss={{
                                                        itemPress: false,
                                                    }}
                                                >
                                                    <MenuHandler>
                                                        <button className="ms-2"><FontAwesomeIcon icon={faTrash} /></button>
                                                    </MenuHandler>
                                                    <MenuList className="flex flex-col items-center text-black z-[9999]">
                                                        {
                                                            selectedPostFetch.deleting ?
                                                                <>
                                                                    {/* <h6 className="hover:outline-none">Deleting</h6> */}
                                                                    <img src={loadingIconAnim} className="h-[25px] w-auto" />
                                                                </> :
                                                                <>
                                                                    <h6 className="hover:outline-none">Delete Post?</h6>
                                                                    <button className="bg-red-500 text-white py-1 w-full rounded-md hover:outline-none hover:bg-red-700 my-1" onClick={tryDeleteSelectedPost}>Confirm</button>
                                                                </>
                                                        }
                                                    </MenuList>
                                                </Menu>
                                            </div>}
                                        </span>}

                                    </div>
                                </div>

                                <div id="nfa-disclaimer-container" className="flex flex-col w-full bg-neutral-950 p-4 rounded-2xl mt-4">
                                    <h6 className="text-header">DISCLAIMER</h6>
                                    <p className="text-normal text-sm mb-0">The information in this post and comments is not meant to be, and does not constitute, financial, investment, trading, or other types of advice or recommendations supplied or endorsed by Primordium Labs.</p>
                                </div>

                                {selectedPostFetch.alphaPost ? <>
                                    <div style={{ height: "1px", background: "rgba(255, 255, 255, 0.4)", marginTop: "4rem", borderRadius: "3rem" }}></div>
                                    <AlphaPostChat className="mt-10" isActive={true} alphaPostId={selectedPostFetch.alphaPost.id} alphaPostAsset={selectedPostFetch.alphaPost.content.prediction?.asset} alphaPostCreatorId={selectedPostFetch.alphaPost.creation.user.userId} />
                                </> : null}
                            </div>
                        </div>
                    </div>}
            </DialogBody>
            {selectedPostFetch.alphaPost && selectedPostFetch.alphaPost.content.prediction?.asset?.type === "token" && !postTokenSecurityMetricsFetch.fetching && postTokenSecurityMetricsFetch.drawerVisible && <TokenSecurityMetricsDrawer tokenInfo={selectedPostFetch.alphaPost?.content.prediction.asset.info} postTokenSecurityMetricsFetch={postTokenSecurityMetricsFetch} close={() => setPostTokenSecurityMetricsFetch({ ...postTokenSecurityMetricsFetch, drawerOpen: false })} />}
        </Dialog>
    )
}

export default AlphaPostViewerModal;