import { FC, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import AlphaPostPreview from "../Components/AlphaPostPreview"
import ErrorContainer from "../../../Components/ErrorContainer";
import AlphaPostViewerModal from "../Components/AlphaPostViewerModal";

import { PostsListFetch, SelectedPostFetch } from "../types";
import { updatePostInPostsList } from "../utils";
import { AlphaPostInfo, AssetClass, PostInteraction } from "../../../utils/types";
import api from "../../../utils/api/api";

import { useUser } from "../../../Components/Providers/useUser";
import firebaseHelpers from "../../../utils/firebaseHelpers";


const AlphaPostsWidget: FC<any> = (props) => {
    const { className, postsListFetch, setPostsListFetch, fetchAlphaPosts, assetClass, searchedAlphaPostId }: { className: string, postsListFetch: PostsListFetch, setPostsListFetch: (value: React.SetStateAction<PostsListFetch>) => void, fetchAlphaPosts: Function, assetClass: AssetClass | null, searchedAlphaPostId?: string } = props;

    const history = useHistory();
    const location = useLocation();
    const { currentUser, setCurrentUser, login, setConnectionDialogOpen } = useUser();

    const [postPreviewOpen, setPostPreviewOpen] = useState<boolean>(false);
    const [selectedPostFetch, setSelectedPostFetch] = useState<SelectedPostFetch>({ fetching: false, fetchError: false, deleting: false, alphaPost: null });

    async function interactWithPost(post: AlphaPostInfo, interactionType: PostInteraction) {
        if (currentUser === null) return setConnectionDialogOpen(true);
        const isRemoveInteraction = interactionType === PostInteraction.Upvote && post.interactions.upvote.accounts.includes(currentUser.userId) || interactionType === PostInteraction.Downvote && post.interactions.downvote.accounts.includes(currentUser.userId)
        mixpanel.track(`Post Interaction: ${isRemoveInteraction ? "Cancel" : ""} ${interactionType === PostInteraction.Upvote ? "Upvote" : "Downvote"}`, {
            postCreationTimestamp: post.creation.timestamp,
            postCreatorUserId: post.creation.user.userId,
            eliteMemberCreator: post.creation.user.isEliteCreator,
            postHasPrediction: post.content.prediction !== null,
            postTokenSymbol: post.content.prediction?.asset?.info.symbol,
            postPosition: post.content.prediction?.position,
            postHasChart: post.content.research.chartUrl !== null
        })

        const initialPostInteractions = JSON.parse(JSON.stringify(post.interactions));
        const interactionResult = api.alphaPost.interactWithPost(interactionType.valueOf(), post.id, isRemoveInteraction);
        addInteractionWithPost(post, interactionType)

        if (!(await interactionResult)) {
            const revertedPost = post;
            revertedPost.interactions = initialPostInteractions;
            console.log("Failed interaction, reverting")
            console.log(revertedPost.interactions)
            updatePostInPostsList(revertedPost, postsListFetch, setPostsListFetch);
        }
    }
    function addInteractionWithPost(post: AlphaPostInfo, interactionType: PostInteraction) {
        if (currentUser === null) return;
        const isCancelation = interactionType === PostInteraction.Upvote ? post.interactions.upvote.accounts.includes(currentUser.userId) : post.interactions.downvote.accounts.includes(currentUser.userId)
        post.interactions.upvote.accounts = post.interactions.upvote.accounts.filter((accountId: string) => accountId !== currentUser.userId);
        post.interactions.downvote.accounts = post.interactions.downvote.accounts.filter((accountId: string) => accountId !== currentUser.userId);

        if (isCancelation) {

        } else if (interactionType === PostInteraction.Upvote) {
            post.interactions.upvote.accounts.push(currentUser.userId);
        } else if (interactionType === PostInteraction.Downvote) {
            post.interactions.downvote.accounts.push(currentUser.userId);
        }

        updatePostInPostsList(post, postsListFetch, setPostsListFetch)
    }

    function removePostFromPostsList(postId: string) {
        const newAlphaPosts = postsListFetch.alphaPosts.filter((alphaPost: AlphaPostInfo) => alphaPost.id !== postId);
        setPostsListFetch({ ...postsListFetch, alphaPosts: newAlphaPosts });
    }

    async function toggleSavePost(post: AlphaPostInfo) {
        if (currentUser === null) return setConnectionDialogOpen(true);

        const postTypeUserAlphaInfo = post.content.prediction?.asset.type === "token" ? "crypto" : post.content.prediction?.asset.type === "nft" ? "nft" : post.content.prediction?.asset.type === "ftShare" ? "friendTech" : "global";

        const isSaveOperation = !currentUser.confidential.userAlphaInfo[postTypeUserAlphaInfo]?.savedPostIds.includes(post.id);
        const operationResultPromise = isSaveOperation ? api.alphaPost.savePost(post.id, postTypeUserAlphaInfo) : api.alphaPost.removeSavedPost(post.id, postTypeUserAlphaInfo);
        mixpanel.track(`${isSaveOperation ? "Add" : "Remove"} Saved Post`, {
            postCreationTimestamp: post.creation.timestamp,
            postCreatorUserId: post.creation.user.userId,
            eliteMemberCreator: post.creation.user.isEliteCreator,
            postTokenSymbol: post.content.prediction?.asset?.info.symbol,
            postPosition: post.content.prediction?.position,
            postHasChart: post.content.research.chartUrl !== null
        })

        function runLocalOperation(isSaveOperation: boolean) {
            isSaveOperation ? setCurrentUser({ ...currentUser, confidential: { userAlphaInfo: { ...currentUser!.confidential.userAlphaInfo, [postTypeUserAlphaInfo]: { ...currentUser!.confidential.userAlphaInfo[postTypeUserAlphaInfo], savedPostIds: [...currentUser!.confidential.userAlphaInfo[postTypeUserAlphaInfo]?.savedPostIds, post.id] } } } })
                : setCurrentUser({ ...currentUser, confidential: { userAlphaInfo: { ...currentUser!.confidential.userAlphaInfo, [postTypeUserAlphaInfo]: { ...currentUser!.confidential.userAlphaInfo[postTypeUserAlphaInfo], savedPostIds: [...currentUser!.confidential.userAlphaInfo[postTypeUserAlphaInfo]?.savedPostIds.filter((postId) => postId !== post.id)] } } } });
        }
        runLocalOperation(isSaveOperation)
        if (!(await operationResultPromise).success) {
            runLocalOperation(!isSaveOperation)
        }
    }

    useEffect(() => {
        if (!postPreviewOpen && searchedAlphaPostId !== selectedPostFetch.alphaPost?.id) {
            fetchSearchedAlphaPostInfo()
        }
    }, [searchedAlphaPostId])
    async function fetchSearchedAlphaPostInfo() {
        if (!searchedAlphaPostId) return;
        await fetchAlphaPostInfo(searchedAlphaPostId);
    }
    async function fetchAlphaPostInfo(alphaPostId: string) {
        setSelectedPostFetch({ ...selectedPostFetch, fetching: true, fetchError: false });
        const response = await firebaseHelpers.alphaPosts.getSpecificPostsInfo([alphaPostId]);
        if (!response.success) {
            setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: true });
            return
        }
        let alphaPostInfo = response.alphaPosts[0];
        // if (response.alphaPosts !== null) alphaPostInfo = arrangePostComments(response.alphaPosts[0]);
        console.log(alphaPostInfo)
        setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: false, alphaPost: alphaPostInfo });
    }

    function closePostPreview() {
        setPostPreviewOpen(false);
        setSelectedPostFetch({ fetching: false, fetchError: false, deleting: false, alphaPost: null });
        if (postPreviewOpen) {
            const closedPostPath = location.pathname.slice(1, location.pathname.length).split("/").slice(0, -1).join("/");
            history.push(`/${closedPostPath}`);
        }
    }

    // assign a random number between 250 and 450 to the height of each loading square
    const loadingSquareHeights: number[] = [];
    for (let i = 0; i < 3; i++) {
        loadingSquareHeights.push(Math.random() * 200 + 250);
    }

    return (
        <>
            <div id="alpha-posts-widget" className={`${className ?? ""} alpha-club-widget no-scrollbar flex flex-col w-full space-y-4 rounded border-white/25 bg-transparent pb-4 md:max-w-full`}>
                {postsListFetch.alphaPosts.map(post => <AlphaPostPreview key={post.id} post={post} postPreviewOpen={postPreviewOpen} setPostPreviewOpen={setPostPreviewOpen} closePostPreview={closePostPreview} removePostFromPostsList={removePostFromPostsList} interactWithPost={interactWithPost} onPostClick={(alphaPost: AlphaPostInfo) => setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: false, alphaPost })} toggleSavePost={toggleSavePost} />)}
                {postsListFetch.fetching ? <>
                    <div className="loading-square alpha-post-preview-loading relative !w-full h-[300px] brightness-150 bg-dark-third !rounded-[1.5rem] my-2" style={{ height: loadingSquareHeights[0] }}></div>
                    <div className="loading-square alpha-post-preview-loading relative !w-full h-[300px] brightness-150 bg-dark-third !rounded-[1.5rem] my-2" style={{ height: loadingSquareHeights[1] }}></div>
                    <div className="loading-square alpha-post-preview-loading relative !w-full h-[300px] brightness-150 bg-dark-third !rounded-[1.5rem] my-2" style={{ height: loadingSquareHeights[2] }}></div>
                </> : null
                }
                {!postsListFetch.fetching && postsListFetch.alphaPosts.length === 0 && postsListFetch.allFetched ? <div className="text-center mt-4">No posts to show.</div> : null}
                {postsListFetch.fetchError && <ErrorContainer text="An error occured while fetching recent posts." buttonText="Try again" onClick={() => fetchAlphaPosts(undefined, false)} className="mt-4" />}
                <div id="post-preview-exit-background" className="fixed inset-0 z-10" style={{ visibility: "hidden" }} onClick={closePostPreview}></div>
            </div>
            {<AlphaPostViewerModal assetClass={assetClass} fetchSearchedAlphaPostInfo={fetchSearchedAlphaPostInfo} selectedPostFetch={selectedPostFetch} setSelectedPostFetch={setSelectedPostFetch} interactWithPost={interactWithPost} removePostFromPostsList={removePostFromPostsList} toggleSavePost={toggleSavePost} />}
        </>
    )
};

export default AlphaPostsWidget;