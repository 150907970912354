import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { Link } from 'react-router-dom';

import loadingIconAnim from "../../media/icons/loadingIcon.gif"
import { ReactComponent as TwitterXIcon } from "../../media/icons/twitterXIcon.svg"
import { ReactComponent as TelegramIcon } from "../../media/icons/telegramIcon.svg"
import TelegramLoginButton from 'react-telegram-login';

import {
    Dialog,
    DialogHeader,
    DialogBody,
} from "@material-tailwind/react";

import { useUser } from "../Providers/useUser"

const ConnectionDialog: FC<any> = (props) => {

    const { loggingIn, currentUser, setCurrentUser, login, logout, connectionDialogOpen, setConnectionDialogOpen } = useUser();

    async function connectTwitter() {
        const loginSuccess = await login("twitter");
        console.log(loginSuccess);
        if (loginSuccess) setConnectionDialogOpen(false);
    }

    async function connectTelegram(telegramData: any) {
        const loginSuccess = await login("telegram", new URLSearchParams(telegramData).toString());
        console.log(loginSuccess);
        if (loginSuccess) setConnectionDialogOpen(false);
    }

    return (
        <Dialog
            id="alpha-post-modal"
            className="bg-neutral-900 text-white m-0 z-[10000]"
            size="xs"
            open={connectionDialogOpen}
            handler={() => setConnectionDialogOpen(false)}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
        >
            <DialogBody className="relative flex flex-col items-center text-white border-b-0 p-2 py-10">
                <div className="flex flex-col items-center max-w-[220px]">
                    <div className="bg-black rounded-full w-full hover:bg-unset hover:scale-105 duration-300 px-4 py-2 my-2"><button onClick={connectTwitter} disabled={loggingIn} className="flex items-center text-base w-full text-normal"><TwitterXIcon className="h-[20px] w-auto me-4" /> Log in with Twitter</button></div>
                    <div className="w-full hover:scale-105 duration-300 p-0 my-2"><TelegramLoginButton botName="alpha_primordium_bot" dataOnauth={connectTelegram} disabled={loggingIn} /></div>
                </div>
                {
                    loggingIn ? <div className="absolute inset-0 w-full h-full bg-black bg-opacity-60 flex items-center justify-center">
                        <img src={loadingIconAnim} className="loading-icon-anim w-[20px] invert h-auto" />
                    </div> : null
                }
            </DialogBody>
        </Dialog>
    )

}

export default ConnectionDialog;
