import axios from "axios";
import { TokenInfo, TokenPoolStats, TokenSecurityMetrics } from "../../types";
import { CHAIN_SLUGS, CHAIN_SLUG_TO_GECKO_ID } from "../../constants";
import { API_URL } from "../api";

async function searchToken(searchQuery: string, limit: number = 1) {
    // const headers = {
    //     "Content-Type": "application/json",
    //     "x-api-key": "ubGqTf4GTp8bBKKSrkKRE19OuXCFO1086KsX4Ujy"
    // };
    // const query = `{
    //     searchTokens(search:"${searchQuery}", limit: ${limit}, resolution: "1D"){
    //         tokens {
    //             name
    //             symbol
    //             address
    //             networkId
    //             topPairId
    //             liquidity
    //             price
    //             priceChange
    //             volume
    //         }
    //     }
    // }`;
    // const result = await axios.post("https://api.defined.fi/", { query }, { headers });
    // const resultData = result.data;

    // const searchedTokens = resultData.data.searchTokens.tokens;
    // return searchedTokens;
    const params = {
        searchQuery,
        limit,
    }
    let response;
    try {
        response = await axios.get(`${API_URL}/alpha-club/big-board/search-token`, { params });
    } catch (error) {
        console.error(error);
        return [];
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return [];
    }

    return responseData.searchedTokens;
}

async function getTokenPoolStats(tokenInfo: TokenInfo): Promise<TokenPoolStats | null> {
    const result = await axios.get(`https://api.geckoterminal.com/api/v2/networks/${CHAIN_SLUG_TO_GECKO_ID[CHAIN_SLUGS[tokenInfo.networkId]]}/pools/${tokenInfo.topPoolId}`);
    const resultData = result.data;
    const tokenPoolAttributes = resultData["data"]["attributes"];

    if (tokenPoolAttributes === undefined) return null;

    const tokenPoolStats: TokenPoolStats = {
        price: Number(tokenPoolAttributes["base_token_price_usd"]),
        priceChange24h: Number(tokenPoolAttributes["price_change_percentage"]["h24"]),
        volume24h: Number(tokenPoolAttributes["volume_usd"]["h24"]),
        liquidity: Number(tokenPoolAttributes["reserve_in_usd"]),
        fdv: Number(tokenPoolAttributes["fdv_usd"]),
        marketCap: Number(tokenPoolAttributes["market_cap_usd"]),
    };
    return tokenPoolStats;
}

async function getTokenSecurityMetrics(tokenInfo: TokenInfo): Promise<TokenSecurityMetrics | null> {
    const result = await axios.get(`https://api.gopluslabs.io/api/v1/token_security/${tokenInfo.networkId}?contract_addresses=${tokenInfo.address}`, { headers: { "Accept": "*/*" } });
    const resultData = result.data;
    let tokenSecurityMetricsResult = resultData["result"][tokenInfo.address];

    if (!tokenSecurityMetricsResult || tokenSecurityMetricsResult.length === 0) return null;

    const tokenSecurityMetrics: TokenSecurityMetrics = {
        isHoneypot: tokenSecurityMetricsResult['is_honeypot'] === '1',
        buyTax: Number(tokenSecurityMetricsResult['buy_tax']) * 100,
        sellTax: Number(tokenSecurityMetricsResult['sell_tax']) * 100,
        holderCount: Number(tokenSecurityMetricsResult['holder_count']),
        totalSupply: Number(tokenSecurityMetricsResult['total_supply']),

        isWhiteListed: tokenSecurityMetricsResult['is_whitelisted'] === '1',
        isBlackListed: tokenSecurityMetricsResult['is_blacklisted'] === '1',
        isAntiWhale: tokenSecurityMetricsResult['is_anti_whale'] === '1',
        isInDex: tokenSecurityMetricsResult['is_in_dex'] === '1',
        isMintable: tokenSecurityMetricsResult['is_mintable'] === '1',
        isOpenSource: tokenSecurityMetricsResult['is_open_source'] === '1',
        isProxy: tokenSecurityMetricsResult['is_proxy'] === '1',

        honeypotWithSameCreator: tokenSecurityMetricsResult['honeypot_with_same_creator'] === '1',
        antiWhaleModifiable: tokenSecurityMetricsResult['anti_whale_modifiable'] === '1',
        canTakeBackOwnership: tokenSecurityMetricsResult['can_take_back_ownership'] === '1',
        cannotBuy: tokenSecurityMetricsResult['cannot_buy'] === '1',
        cannotSellAll: tokenSecurityMetricsResult['cannot_sell_all'] === '1',
        slippageModifiable: tokenSecurityMetricsResult['slippage_modifiable'] === '1',
        tradingCooldown: tokenSecurityMetricsResult['trading_cooldown'] === '1',
        transferPausable: tokenSecurityMetricsResult['transfer_pausable'] === '1',
        externalCall: tokenSecurityMetricsResult['external_call'] === '1',
        personalSlippageModifiable: tokenSecurityMetricsResult['personal_slippage_modifiable'] === '1',
        selfDestruct: tokenSecurityMetricsResult['selfdestruct'] === '1',

        creatorInfo: {
            address: tokenSecurityMetricsResult['creator_address'],
            balance: tokenSecurityMetricsResult['creator_balance'],
            percent: Number(tokenSecurityMetricsResult['creator_percent']).toFixed(2),
        },
        ownerInfo: tokenSecurityMetricsResult['owner_address'].length === 0 ? null : {
            address: tokenSecurityMetricsResult['owner_address'],
            balance: tokenSecurityMetricsResult['owner_balance'],
            change_balance: tokenSecurityMetricsResult['owner_change_balance'],
            percent: Number(tokenSecurityMetricsResult['owner_percent']).toFixed(2),
        },
        hiddenOwner: tokenSecurityMetricsResult['hidden_owner'] === '1',

        lpInfo: {
            lpHoldersCount: tokenSecurityMetricsResult['lp_holder_count'],
            topLpHolders: tokenSecurityMetricsResult["lp_holders"]?.map((holder: any) => ({
                address: holder["address"],
                tag: holder["tag"],
                isContract: holder["is_contract"] === 1,
                balance: holder["balance"],
                percent: holder["percent"],
                isLocked: holder["is_locked"] === 1,
                lockedDetail: holder["locked_detail"]?.map((detail: any) => ({
                    amount: Number(detail["amount"]),
                    endTime: detail["end_time"],
                    optTime: detail["opt_time"],
                })) ?? [],
            })) ?? [],
            lpTotalSupply: Number(tokenSecurityMetricsResult['lp_total_supply']),
        },

        topHolders: tokenSecurityMetricsResult["holders"].map((holder: any) => ({
            address: holder["address"],
            tag: holder["tag"],
            isContract: holder["is_contract"] === 1,
            balance: holder["balance"],
            percent: holder["percent"],
            isLocked: holder["is_locked"] === 1,
        })),

        tokenName: tokenSecurityMetricsResult["token_name"],
        tokenSymbol: tokenSecurityMetricsResult["token_symbol"],
    }

    console.log(tokenSecurityMetrics);

    return tokenSecurityMetrics;
}

export default {
    searchToken,
    getTokenPoolStats,
    getTokenSecurityMetrics,
}
