import axios from "axios";

import { UserAccount } from "../../types";
import { API_URL } from "../api"
import { firebaseAuth } from "../../firebaseApp";

async function searchUserAccount(
    username: string,
    authPlatform: string,
    userId?: string,
): Promise<{ success: boolean, userAccount?: UserAccount, error?: string }> {
    const firebaseAuthToken = await firebaseAuth.currentUser?.getIdToken();
    const headers: any = {
        "Content-Type": "application/json",
    }
    if (firebaseAuthToken) headers["Authorization"] = "Bearer " + firebaseAuthToken

    const params: any = userId ? { userId } : {
        username,
        authPlatform,
    }
    let response;
    try {
        response = await axios.get(`${API_URL}/users/user-info`, { params, headers });
    } catch (error: any) {
        console.error(error);
        return { success: false, error: error.message };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, error: responseData.error };
    }

    return { success: true, userAccount: responseData.userAccount };
}


export default {
    searchUserAccount,
}